import { useNavigate } from "react-router-dom";

export const useOpenHandler = () => {
  const navigate = useNavigate();

  const openHandler = (route: string, event?: React.MouseEvent) => {
    if (event?.ctrlKey) {
      window.open(`${route}`, "_blank");
    } else {
      navigate(route);
    }
  };

  return openHandler;
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { educationLevelOptions } from "../../../data/select-data";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { IEducationHistory } from "../../../interfaces/domain/IEducationHistory";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData, initForm } from "../../UI/Input/input-utils";
import InputGroup from "../../UI/InputGroup/InputGroup";
import HistoryContainer from "../HistoryContainer/HistoryContainer";
import { useAuthUser } from "../../../hooks/useAuthUser";

enum EEducationHistory {
  educationLevel = "educationLevel",
  degree = "degree",
  major = "major",
  field_of_education = "field_of_education",
  school = "school",
  startDate = "startDate",
  endDate = "endDate",
  ongoing = "ongoing",
}

interface IProps {
  index: number;
  educationHistory: IEducationHistory;
  setEducationHistories: React.Dispatch<
    React.SetStateAction<IEducationHistory[]>
  >;
  disabled?: boolean;
}

const initialInputs: IInputField = {
  [EEducationHistory.educationLevel]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.EDUCATION_LEVEL,
    placeholderTranslation: ETranslation.EDUCATION_LEVEL,
    value: "",
    options: educationLevelOptions,
  },
  [EEducationHistory.degree]: {
    type: EInputType.text,
    labelTranslation: ETranslation.DEGREE,
    placeholderTranslation: ETranslation.DEGREE,
    value: "",
  },
  [EEducationHistory.major]: {
    type: EInputType.text,
    labelTranslation: ETranslation.MAJOR,
    placeholderTranslation: ETranslation.MAJOR,
    value: "",
  },
  [EEducationHistory.field_of_education]: {
    type: EInputType.text,
    labelTranslation: ETranslation.FIELD_OF_EDUCATION,
    placeholderTranslation: ETranslation.FIELD_OF_EDUCATION,
    value: "",
  },
  [EEducationHistory.school]: {
    type: EInputType.text,
    labelTranslation: ETranslation.SCHOOL,
    placeholderTranslation: ETranslation.SCHOOL,
    value: "",
  },
  [EEducationHistory.startDate]: {
    type: EInputType.date,
    labelTranslation: ETranslation.EDUCATION_START_DATE,
    placeholderTranslation: ETranslation.EDUCATION_START_DATE,
    value: "",
  },
  [EEducationHistory.endDate]: {
    type: EInputType.date,
    labelTranslation: ETranslation.EDUCATION_END_DATE,
    placeholderTranslation: ETranslation.EDUCATION_END_DATE,
    value: "",
  },
  [EEducationHistory.ongoing]: {
    type: EInputType.checkbox,
    labelTranslation: ETranslation.ONGOING,
    placeholderTranslation: ETranslation.ONGOING,
    value: "",
  },
};

const EducationHistory: React.FC<IProps> = ({
  index,
  educationHistory,
  setEducationHistories,
  disabled,
}) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const openConfirmDeleteModal = useConfirmModal();
  const authUser = useAuthUser();

  const deleteHandler = async () => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.CONFIRM_DELETE_EDUCATION_HISTORY)
    );
    if (success) {
      setEducationHistories((educationHistories) => {
        if (educationHistories.length === 1) return [];
        return educationHistories.filter((_, i) => i !== index);
      });
    }
  };

  const updateHandler = (educationHistory: IEducationHistory) => {
    setEducationHistories((educationHistories) => {
      return educationHistories.map((element, i) =>
        i === index ? { ...educationHistory } : element
      );
    });
  };

  useEffect(() => {
    initForm(setInputs, educationHistory);
  }, [educationHistory]);

  const createInput = useCreateInput(inputs, setInputs, {
    onBlur: () => updateHandler(getInputData(inputs)),
    disabled,
  });

  return (
    <HistoryContainer>
      {createInput(EEducationHistory.educationLevel)}
      {createInput(EEducationHistory.degree)}
      {createInput(EEducationHistory.ongoing)}
      <InputGroup>
        {createInput(EEducationHistory.startDate)}
        {createInput(EEducationHistory.endDate)}
      </InputGroup>
      {createInput(EEducationHistory.major)}
      {createInput(EEducationHistory.field_of_education)}
      {createInput(EEducationHistory.school)}
      {!authUser?.isViewer && (
        <Button
          onClick={deleteHandler}
          color={EButtonColor.DANGER}
          size={EButtonSize.SMALL}
          disabled={disabled}
        >
          {t(ETranslation.COMMON_DELETE)}
        </Button>
      )}
    </HistoryContainer>
  );
};

export default EducationHistory;

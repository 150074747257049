import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import { useAsyncCall } from "../../../hooks/useAsyncCall";
import { search } from "../../../services/searchService";
import Input, { EInputType } from "../../UI/Input/Input";
import Spinner, { ESpinnerSize } from "../../UI/Spinner/Spinner";

import classes from "./Search.module.scss";

enum EKind {
  JobApplication = "JobApplication",
}

interface ISearchResult {
  value: string;
  label: string;
  kind: string;
}

const Search: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const navigate = useNavigate();

  const { loading, result } = useAsyncCall<ISearchResult[]>(searchTerm, search);

  const clickHandler = useCallback(
    (searchResult: ISearchResult, event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      let route = "";
      switch (searchResult.kind) {
        case EKind.JobApplication:
          route = Routes.JOB_APPLICATION(searchResult.value);
          break;
      }
      if (event?.ctrlKey) {
        window.open(`${route}`, "_blank");
      } else {
        navigate(route);
        setSearchTerm("");
      }
    },
    [navigate]
  );

  return (
    <div className={classes.Container}>
      <Input
        inputName="searchInput"
        containerStyles={{ marginBottom: 0, maxWidth: "40rem" }}
        value={searchTerm}
        onChange={(value) => setSearchTerm(value as string)}
        type={EInputType.text}
        placeholder="Haku"
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />

      {(result || loading) && isFocus && (
        <SearchResult
          searchResults={result}
          loading={loading}
          onClick={clickHandler}
        />
      )}
    </div>
  );
};

interface ISearchResultProps {
  searchResults: ISearchResult[] | null;
  loading: boolean;
  onClick: (searchResult: ISearchResult, event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const SearchResult: React.FC<ISearchResultProps> = ({
  searchResults,
  loading,
  onClick,
}) => {
  if (!searchResults && !loading) return null;

  return (
    <article className={classes.SearchResult}>
      {loading ? (
        <Spinner size={ESpinnerSize.SMALL} style={{ padding: ".5rem" }} />
      ) : (
        <>
          {searchResults!.map((searchResult) => (
            <section
              className={classes.SearchResultSectionItem}
              key={searchResult.value}
              onMouseDown={(event) => onClick(searchResult, event)}
            >
              <b className={classes.SearchResultSectionTitle}>
                {searchResult.label}
              </b>
            </section>
          ))}
        </>
      )}
    </article>
  );
};

export default Search;

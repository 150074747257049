import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import firebase from "../../../firebase";
import { isValidEmail } from "../../../shared/isValidEmail";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../UI/Button/Button";
import { EInputType } from "../../UI/Input/Input";
import InputGroup from "../../UI/InputGroup/InputGroup";
import classes from "./PasswordResetDialog.module.scss";

interface IProps {
  onCancel: () => void;
}

const PasswordResetDialog: React.FC<IProps> = ({ onCancel }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");

  const sendEmail = () => {
    if (email) {
      sendPasswordResetEmail(getAuth(firebase), email);
    }
    onCancel();
  }

  return (
    <div>
      <p>{t(ETranslation.PASSWORD_RESET_TEXT)}</p>
      <div>
        <input
          className={classes.Input}
          placeholder={t(ETranslation.COMMON_EMAIL)}
          type={EInputType.email}
          value={email as string}
          onChange={event => {
            const value = event && event.target && event.target.value;
            setEmail(value as string)
          }}
        />
      </div>
      <InputGroup>
        <Button disabled={!isValidEmail(email)} onClick={sendEmail}>{t(ETranslation.COMMON_SEND)}</Button>
        <Button onClick={onCancel}>{t(ETranslation.COMMON_CANCEL)}</Button>
      </InputGroup>
    </div>
  )
}

export default PasswordResetDialog;

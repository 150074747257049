import { ETranslation } from "./translation-keys";

const commonTranslations = {
  [ETranslation.COMMON_EMAIL]: "Email",
  [ETranslation.COMMON_SEND]: "Send",
  [ETranslation.COMMON_CANCEL]: "Cancel",
  [ETranslation.COMMON_CLOSE]: "Close",
  [ETranslation.COMMON_RETURN]: "Return",
  [ETranslation.COMMON_DELETE]: "Delete",
  [ETranslation.COMMON_YES]: "Yes",
  [ETranslation.COMMON_NO]: "No",
  [ETranslation.COMMON_SAVE]: "Save",
  [ETranslation.COMMON_ADD]: "Add",
  [ETranslation.COMMON_CREATED]: "Created",
  [ETranslation.COMMON_SEARCH]: "Search",
  [ETranslation.COMMON_DOWNLOAD]: "Download",
  [ETranslation.COMMON_OPEN_ALL]: "Open all",
};

const authTranslations = {
  [ETranslation.TITLE]: "ALREKRY",
  [ETranslation.LOGIN]: "Login",
  [ETranslation.FORGOT_PASSWORD]: "Forgot your password?",
  [ETranslation.RESET_PASSWORD]: "Reset password",
  [ETranslation.USERNAME]: "Username",
  [ETranslation.PASSWORD]: "Password",
  [ETranslation.PASSWORD_MIN_LENGTH_MESSAGE]:
    "Password must contain at least 6 characters",
  [ETranslation.PASSWORD_RESET_TEXT]:
    "Are you sure you want to send a change password link to your email?",
};

const headerTranslations = {
  [ETranslation.MY_PAGE]: "My page",
  [ETranslation.USERS]: "Users",
  [ETranslation.POSTS]: "Posts",
  [ETranslation.JOB_APPLICATIONS]: "Job applications",
  [ETranslation.LOGOUT]: "Log out",
  [ETranslation.PROFILE]: "Profile",
};

const PostsTranslations = {
  [ETranslation.START_DATE]: "Start date",
  [ETranslation.END_DATE]: "End date",
  [ETranslation.NO_POSTS]: "No posts.",
  [ETranslation.DATE]: "Date",
};

const PostTranslations = {
  [ETranslation.COMMENTS]: "Comments",
  [ETranslation.PROFESSION]: "Profession",
  [ETranslation.CITY]: "City",
  [ETranslation.LOCATION]: "Location",
  [ETranslation.LOCATION_DETAIL]: "Location",
  [ETranslation.SHORT_DESCRIPTION]: "Short description",
  [ETranslation.FULL_DESCRIPTION]: "Full description",
  [ETranslation.DATE_POSTED]: "Date posted",
  [ETranslation.VALID_THROUGH_DATE]: "Valid through date",
  [ETranslation.VALID_THROUGH_TIME]: "Valid through time",
  [ETranslation.EMPLOYMENT_TYPE]: "Työsuhteen tyyppi",
  [ETranslation.IMAGE_URL]: "Image",
  [ETranslation.HARD_TO_FIND]: "Hard to find",
  [ETranslation.ARCHIVED]: "Archived",
  [ETranslation.ATTACHMENTS]: "Attachments",
  [ETranslation.HIDDEN]: "Hidden",
  [ETranslation.STATUS]: "Status",
  [ETranslation.FIELDS_OF_WORK]: "Fields of work",
  [ETranslation.POST_JOB_INFO]: "Job information",
  [ETranslation.POST_PUBLISH_INFO]: "Publish information",
  [ETranslation.REFERENCE]: "Customer/reference",
  [ETranslation.NO_RESULTS]: "No results.",
  [ETranslation.COPY]: "Copy post",
};

const PostCommentsTranslations = {
  [ETranslation.MESSAGE]: "Message",
  [ETranslation.COMMENT]: "Comment",
  [ETranslation.ADD_COMMENT]: "Add comment",
};

const PostListTranslations = {
  [ETranslation.POST_LIST_FILTER]: "Filter",
};

const PostSearchTranslations = {
  [ETranslation.SEARCH_ORDER_BY]: "Order by",
  [ETranslation.SEARCH_START_DATE]: "Start date",
  [ETranslation.SEARCH_STATUS]: "Status",
  [ETranslation.SEARCH_TITLE]: "Search terms",
  [ETranslation.SEARCH_CLEAR_AND_SEARCH]: "Clear and find all",
  [ETranslation.SEARCH_LATEST]: "Latest searches",
  [ETranslation.SEARCH_FIND_LATEST]: "Find latest",
  [ETranslation.SEARCH_EXAMPLE_TEXT]: "Text search e.g."
};

const PostStatusTranslations = {
  [ETranslation.STATUS_ISSUED]: "Issued",
  [ETranslation.STATUS_COMPLETE]: "Complete",
  [ETranslation.STATUS_CANCELLED]: "Cancelled",
  [ETranslation.STATUS_PLANNING]: "In Creation-Planning in progress",
};

const UsersTranslations = {
  [ETranslation.NAME]: "Name",
  [ETranslation.EMAIL]: "Email",
  [ETranslation.MOBILE]: "Mobile",
  [ETranslation.ALLOW_LOGIN]: "Allow login",
  [ETranslation.NO_USERS]: "No users.",
};

const UserTranslations = {
  [ETranslation.FIRST_NAME]: "First name",
  [ETranslation.LAST_NAME]: "Last name",
  [ETranslation.ADDRESS]: "Address",
  [ETranslation.POSTCODE]: "Postcode",
  [ETranslation.PLACE]: "Place",
  [ETranslation.LEVEL]: "Level",
  [ETranslation.GENDER]: "Gender",
  [ETranslation.FIREBASE_USER_ID]: "Firebase ID",
};

/*
const UserSearchTranslations = {
  [ETranslation.SEARCH_ROLE]: "Role",
};
*/

const JobApplicationTranslations = {
  [ETranslation.JOB_APPLICATION_TITLE]: "Job application",
  [ETranslation.NO_JOB_APPLICATIONS]: "No Job Applications",
	[ETranslation.GDPR_CONCENT]: "GDPR Concent",
	[ETranslation.OTHER_INFO]: "Other info",
	[ETranslation.POST_ID]: "Post Id",
  [ETranslation.POST_SHORT_ID]: "Post ID",
	[ETranslation.POST_TITLE]: "Post Title",
	[ETranslation.APPLY_OPTIONS]: "Apply options",
	[ETranslation.DRIVERS_LICENSE]: "Drivers license",
	[ETranslation.DRIVERS_LICENSE_DETAILS]: "Drivers license details",
	[ETranslation.OWN_CAR]: "Own car",
	[ETranslation.EDUCATION]: "Education",
  [ETranslation.LANGUAGE_SKILL_FI]: "Language skill Fi",
	[ETranslation.LANGUAGE_SKILL_EN]: "Language skill En",
  [ETranslation.LANGUAGE_SKILL_SE]: "Language skill Se",
  [ETranslation.BIRTH_YEAR]: "Year of birth",
  [ETranslation.WORK_EXPERIENCE_FIELDS]: "Work experience fields",
  [ETranslation.SPECIAL_SKILLS]: "Special skills",
  [ETranslation.IT_SKILLS]: "IT skills",
  [ETranslation.OTHER_LANGUAGES]: "Other languages",
  [ETranslation.JOB_APPLICATION_TITLE_BASIC]: "Information",
  [ETranslation.JOB_APPLICATION_TITLE_EXPERIENCE]: "Experience",
  [ETranslation.JOB_APPLICATION_TITLE_OTHER_INFO]: "Other info",
  [ETranslation.JOB_APPLICATION_TITLE_APPLY]: "Apply",
  [ETranslation.PREFERRED_WORK_TYPES]: "Preferred work types",
  [ETranslation.PREFERRED_STARTING_DATE]: "Preferred starting date",
  [ETranslation.STARTING_DATE_MORE_INFO]: "More info",
  [ETranslation.SALARY_PREFERENCE]: "Salary preference €/h",
  [ETranslation.UNWANTED_JOBS]: "Unwanted jobs",
  [ETranslation.ALSO_APPLY_TO_FIELDS]: "Also apply to fields",
  [ETranslation.WORK_TIME]: "Work time",
  [ETranslation.JOB_APPLICATION_EMAIL]: "Sähköposti",
  [ETranslation.JOB_APPLICATION_MOBILE]: "Puhelinnumero",
  [ETranslation.LANGUAGE_SKILL]: "Language skill",
  [ETranslation.ADD_WORK_HISTORY]: "Add work history",
  [ETranslation.ADD_EDUCATION_HISTORY]: "Add education",
  [ETranslation.ADD_HISTORY]: "Add history",
  [ETranslation.PROFESSIONS]: "Professions",
  [ETranslation.HOBBIES]: "Hobbies",
  [ETranslation.OTHER_PROFESSION]: "Other profession",
}

const jobApplicationListTranslations = {
  [ETranslation.LIST_PHONE_AND_ZIP]: "Phone & zip",
  [ETranslation.LIST_WORK_EXPERIENCE]: "Work experience",
  [ETranslation.LIST_PROFESSION]: "Profession",
  [ETranslation.LIST_FIELD_OF_WORK]: "Requested profession",
};

const reactSelectTranslations = {
  [ETranslation.REACT_SELECT_PLACEHOLDER]: "Select",
  [ETranslation.REACT_SELECT_ADD_NEW]: "Add",
};

const reactDropzoneTranslations = {
  [ETranslation.REACT_DROPZONE_DRAG_TEXT]: "Click here or drop a file.",
  [ETranslation.REACT_DROPZONE_DRAG_ACTIVE_TEXT]: "Drop file.",
};

const errorTranslations = {
  [ETranslation.NO_ACCESS_ERROR]: "No access.",
  [ETranslation.NOT_FOUND_ERROR]: "No results.",
  [ETranslation.POPUP_BLOCKED_ERROR_TITLE]: "Pop-ups are blocked",
  [ETranslation.POPUP_BLOCKED_ERROR_TEXT]: "Pop-ups are blocked, please enable them for this site.",
};

const validationTranslations = {
  [ETranslation.VALIDATION_EMAIL]: "Check email",
  [ETranslation.VALIDATION_MIN_LENGTH]:
    "Input minimum of {{minLength}} characters.",
  [ETranslation.VALIDATION_MAX_LENGTH]:
    "Input maximum {{maxLength}} characters.",
  [ETranslation.VALIDATION_MIN_AMOUNT]:
    "Amount cannot be lesser than {{minAmount}}.",
  [ETranslation.VALIDATION_MAX_AMOUNT]:
    "Amount cannot be greater than {{maxAmount}}.",
};

const levelTranslations = {
  [ETranslation.LEVEL_ADMIN]: "Admin",
  [ETranslation.LEVEL_OPERATIVE]: "Operative",
  [ETranslation.LEVEL_VIEWER]: "Viewer",
};

const employmentTypeTranslations = {
  [ETranslation.EMPLOYMENT_TYPE_FULL_TIME]: "Full-time",
  [ETranslation.EMPLOYMENT_TYPE_PART_TIME]: "Part-time",
};

const genderTranslations = {
  [ETranslation.GENDER_FEMALE]: "Female",
  [ETranslation.GENDER_MALE]: "Male",
  [ETranslation.GENDER_OTHER]: "Other",
  [ETranslation.GENDER_NOT_WILLING_TO_ANSWER]: "Not willing to answer",
}

//TODO english
const fieldOfWorkTranslations = {
  [ETranslation.FIELD_OF_WORK_ASSEMBLY]: "Assembly",
	[ETranslation.FIELD_OF_WORK_CATERING]: "Catering",
	[ETranslation.FIELD_OF_WORK_CONSTRUCTION]: "Construction",
	[ETranslation.FIELD_OF_WORK_CUSTOMER_SERVICE]: "Customer service",
	[ETranslation.FIELD_OF_WORK_EXCAVATION]: "Excavation",
	[ETranslation.FIELD_OF_WORK_FOOD_CHAIN_SERVICES]: "Food chain services",
	[ETranslation.FIELD_OF_WORK_FOOD_WORK]: "Food work",
	[ETranslation.FIELD_OF_WORK_INDUSTRIAL_WORK]: "Industrial work",
	[ETranslation.FIELD_OF_WORK_INSTALLATION_WORK]: "Installation work",
	[ETranslation.FIELD_OF_WORK_KITCHEN_SERVICES]: "Kitchen services",
	[ETranslation.FIELD_OF_WORK_LAUNDRY_WORK]: "Laundry work",
	[ETranslation.FIELD_OF_WORK_LOGISTICS]: "Logistics",
	[ETranslation.FIELD_OF_WORK_MAINTENANCE]: "Maintenance",
	[ETranslation.FIELD_OF_WORK_METAL_WORK]: "Metal work",
	[ETranslation.FIELD_OF_WORK_OFFICE_WORK]: "Office work",
	[ETranslation.FIELD_OF_WORK_PROCESS_INDUSTRY]: "Process industry",
	[ETranslation.FIELD_OF_WORK_SERVICE_SECTOR]: "Service sector",
	[ETranslation.FIELD_OF_WORK_SURFACE_TREATMENT]: "Surface treatment",
	[ETranslation.FIELD_OF_WORK_TEXTILE_INDUSTRY]: "Textile industry",
	[ETranslation.FIELD_OF_WORK_TRANSPORTATION]: "Transportation",
}
//TODO english
const commonCardTranslations = {
  [ETranslation.COMMON_CARD_ANNISKELUPASSI]: "Anniskelupassi",
	[ETranslation.COMMON_CARD_ASBESTIPURKUTYOLUPA]: "Asbestipurkutyölupa",
	[ETranslation.COMMON_CARD_HYGIENIAPASSI]: "Hygieniapassi",
	[ETranslation.COMMON_CARD_IKARAJAPASSI]: "Ikärajapassi",
	[ETranslation.COMMON_CARD_JARJESTYKSENVALVOJAKORTTI]: "Järjestyksenvalvojakortti",
	[ETranslation.COMMON_CARD_TULITYOKORTTI]: "Tulityökortti",
	[ETranslation.COMMON_CARD_TYOTURVALLISUUSKORTTI]: "Työturvallisuuskortti",
	[ETranslation.COMMON_CARD_NONE]: "Ei mikään näistä",
}

const workTypeTranslations = {
  [ETranslation.WORK_TYPE_PARTTIME]: "Parttime", 
	[ETranslation.WORK_TYPE_TEMPORARY]: "Temporary", 
	[ETranslation.WORK_TYPE_PERMANENT]: "Permanent", 
	[ETranslation.WORK_TYPE_FULLTIME]: "Fulltime", 
}

const workTimeTranslations = {
  [ETranslation.WORK_TIME_EVENINGS]: "Evenings", 
	[ETranslation.WORK_TIME_WEEKDAYS]: "Weekdays", 
	[ETranslation.WORK_TIME_WEEKENDS]: "Weekends", 
	[ETranslation.WORK_TIME_MORNINGS]: "Mornings", 
	[ETranslation.WORK_TIME_SHIFTWORK]: "Shiftwork",
}

const baseEducationTranslations = {
  [ETranslation.BASE_EDUCATION_GRADE_SCHOOL]: "Primary school", 
	[ETranslation.BASE_EDUCATION_HIGH_SCHOOL]: "High school", 
}

const selectTranslations = {
  [ETranslation.SELECT_USER_PLACEHOLDER]: "Choose user",
  [ETranslation.SELECT_STATUS_PLACEHOLDER]: "Choose status",
  [ETranslation.SELECT_LEVEL_PLACEHOLDER]: "Choose level",
  [ETranslation.SELECT_EMPLOYMENT_TYPE_PLACEHOLDER]: "Choose employment type",
  [ETranslation.SELECT_FIELD_OF_WORK_PLACEHOLDER]: "Choose fields of work",
  [ETranslation.SELECT_CITY_PLACEHOLDER]: "Select city",
};

const confirmTranslations = {
  [ETranslation.CONFIRM_DELETE_COMMENT]:
    "Are you sure you want to delete selected comment?",
  [ETranslation.CONFIRM_POST_SAVE]: "Make sure the details are correct, Do you want to save?",
  [ETranslation.CONFIRM_DELETE_ATTACHMENT]: "Are you sure you want to delete the selected attachment?",
  [ETranslation.CONFIRM_DELETE_JOB_APPLICATION]: "Are you sure you want to delete this job application?",
  [ETranslation.CONFIRM_DELETE_POST]: "Are you sure you want to delete this post?",
  [ETranslation.CONFIRM_DELETE_USER]: "Are you sure you want to delete this user?",
  [ETranslation.CONFIRM_DELETE_WORK_HISTORY]: "Are you sure you want to delete this work history?",
  [ETranslation.CONFIRM_DELETE_EDUCATION_HISTORY]: "Are you sure you want to delete this education?",
  [ETranslation.CONFIRM_NOT_HIDDEN_POST]: "Post is not Hidden and it will be listed at www-site.",

};

const workHistoryTranslations = {
  [ETranslation.WORK_HISTORY]: "Work history",
  [ETranslation.EMPLOYER]: "Employer",
  [ETranslation.JOB_TITLE]: "Job title",
  [ETranslation.JOB_ASSIGNMENTS]: "Job assignments",
  [ETranslation.WORK_START_DATE]: "Work start date",
  [ETranslation.WORK_END_DATE]: "Work end date",
};

const educationHistoryTranslations = {
  [ETranslation.EDUCATION_HISTORY]: "Education",
  [ETranslation.EDUCATION_LEVEL]: "Education level",
  [ETranslation.DEGREE]: "Degree",
  [ETranslation.MAJOR]: "Major",
  [ETranslation.FIELD_OF_EDUCATION]: "Field of education",
  [ETranslation.SCHOOL]: "School",
  [ETranslation.EDUCATION_START_DATE]: "Education start date",
  [ETranslation.EDUCATION_END_DATE]: "Education end date",
  [ETranslation.ONGOING]: "Education ongoing",
};

const educationLevelTranslations = {
  [ETranslation.EDUCATION_LEVEL_VOCATIONAL_SCHOOL]: "Vocational school",
	[ETranslation.EDUCATION_LEVEL_UNIVERSITY_OF_APPLIED_SCIENCES]: "University of applied sciences",
	[ETranslation.EDUCATION_LEVEL_UNIVERSITY]: "University",
	[ETranslation.EDUCATION_LEVEL_ADULT_EDUCATION_COURSE]: "Adult education course",
	[ETranslation.EDUCATION_LEVEL_THE_INSTITUTE_OF_ADULT_EDUCATION]: "The institute of adult education",
	[ETranslation.EDUCATION_LEVEL_UPPER_UNIVERSITY_OF_APPLIED_SCIENCES]: "Upper university of applied sciences",
	[ETranslation.EDUCATION_LEVEL_LICENTIATE]: "Licentiate",
	[ETranslation.EDUCATION_LEVEL_OTHER]: "Other",
	[ETranslation.EDUCATION_LEVEL_IN_PROGRESS]: "In progress",
	[ETranslation.EDUCATION_LEVEL_NONE]: "None",
};

const hardToFindTranslations = {
  [ETranslation.HARD_TO_FIND_1]: "1, Easy",
  [ETranslation.HARD_TO_FIND_2]: "2",
  [ETranslation.HARD_TO_FIND_3]: "3, Medium",
  [ETranslation.HARD_TO_FIND_4]: "4",
  [ETranslation.HARD_TO_FIND_5]: "5, Very hard",
};

const languageSkillTranslations = {
  [ETranslation.LANGUAGE_SKILL_0]: "0",
  [ETranslation.LANGUAGE_SKILL_1]: "1",
  [ETranslation.LANGUAGE_SKILL_2]: "2",
  [ETranslation.LANGUAGE_SKILL_3]: "3",
  [ETranslation.LANGUAGE_SKILL_4]: "4",
  [ETranslation.LANGUAGE_SKILL_5]: "5",
};

const logItemTranslations = {
  [ETranslation.LOG]: "Log",
  [ETranslation.USER]: "User",
};

const translationsEn = {
  ...commonTranslations,
  ...authTranslations,
  ...headerTranslations,
  ...PostsTranslations,
  ...PostTranslations,
  ...PostCommentsTranslations,
  ...PostListTranslations,
  ...PostSearchTranslations,
  ...PostStatusTranslations,
  ...UsersTranslations,
  ...UserTranslations,
//  ...UserSearchTranslations,
  ...JobApplicationTranslations,
  ...jobApplicationListTranslations,
  ...reactSelectTranslations,
  ...reactDropzoneTranslations,
  ...errorTranslations,
  ...validationTranslations,
  ...levelTranslations,  
  ...employmentTypeTranslations,
  ...levelTranslations,
  ...genderTranslations,
  ...fieldOfWorkTranslations,
  ...commonCardTranslations,
  ...workTypeTranslations,
  ...workTimeTranslations,
  ...baseEducationTranslations,
  ...selectTranslations,
  ...confirmTranslations,
  ...workHistoryTranslations,
  ...educationHistoryTranslations,
  ...educationLevelTranslations,
  ...hardToFindTranslations,
  ...languageSkillTranslations,
  ...logItemTranslations
};

export default translationsEn;

import { enUS } from "date-fns/locale";
import React, {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputContext, {
  EInputUpdateAction,
} from "../../../context/InputContext";
import { ETranslation } from "../../../translations/translation-keys";
import Dropzone from "../../Dropzone/Dropzone";
import AutoTextArea from "../AutoTextArea/AutoTextArea";
import { TFetchOption } from "../SearchSelect/SearchSelect";
import Select from "../Select/Select";
import Spinner, { ESpinnerSize } from "../Spinner/Spinner";
import classes from "./Input.module.scss";

export enum EInfoColor {
  DANGER,
}

export interface IOption {
  value: string;
  label?: string;
  labelTranslation?: ETranslation;
}

export enum EInputType {
  text = "text",
  number = "number",
  date = "date",
  time = "time",
  email = "email",
  tel = "tel",
  select = "select",
  checkbox = "checkbox",
  textarea = "textarea",
  datepicker = "datepicker",
  password = "password",
  searchSelect = "searchSelect",
  dropzone = "dropzone",
  wysiwyg = "wysiwyg",
  reactSelect = "reactSelect",
}

export interface IValidationResult {
  isValid: boolean;
  message: ETranslation | null;
  messageParams?: {
    [key: string]: string;
  };
}

export interface IInputValidation {
  required?: boolean;
  requiredCompareValue?: string;
  requiredIf?: string;
  requiredIfValue?: string | string[];
  requiredIfNot?: string;
  dependencies?: string[];
  minLength?: number;
  minLengthMessage?: ETranslation;
  maxLength?: number;
  maxLengthMessage?: ETranslation;
  minAmount?: number;
  minAmountMessage?: ETranslation;
  maxAmount?: number;
  maxAmountMessage?: ETranslation;
}

export interface IInputFieldItem {
  type: EInputType;
  label?: string;
  labelTranslation?: ETranslation;
  value: TInputValue;
  options?: IOption[];
  placeholder?: string;
  placeholderTranslation?: ETranslation;
  disabled?: boolean;
  validation?: IInputValidation;
  validationResult?: IValidationResult;
  max?: string;
  min?: string;
  onAdd?: () => void;
  pre?: string;
  post?: string;
  multiple?: boolean;
  readOnly?: boolean;
  isNewPassword?: boolean;
}

export interface IInputField {
  [key: string]: IInputFieldItem;
}

export interface IInputData {
  id?: string;
}

export type TInputValue =
  | string
  | string[]
  | number
  | Date
  | IOption
  | IOption[]
  | boolean
  | null
  | undefined;

export interface IInputOptions {
  updateAction?: EInputUpdateAction;
  weight?: number;
  info?: string;
  topInfo?: string | JSX.Element;
  infoColor?: EInfoColor;
  data?: IInputData;
  disabled?: boolean;
  max?: string;
  min?: string;
  showLabel?: boolean;
  containerStyles?: CSSProperties;
  showValidation?: boolean;
  onAdd?: () => void;
  onRemove?: (id: string) => Promise<void>;
  onSetValid?: React.Dispatch<React.SetStateAction<boolean>>;
  onBlurModifyValue?: (value: TInputValue) => TInputValue;
  onBlur?: (value: TInputValue, inputName: string) => void;
  onFocus?: (value: TInputValue) => void;
  onClick?: () => void;
  hideUnselected?: boolean;
  pre?: string;
  post?: string;
  options?: IOption[];
  multiple?: boolean;
  loading?: boolean;
  onDrop?: (inputName: string, acceptedFiles: File[]) => void;
  fetchOptions?: TFetchOption;
  isNewPassword?: boolean;
}

interface IProps extends IInputOptions {
  label?: string;
  labelTranslation?: ETranslation;
  value: TInputValue;
  type: EInputType;
  validationResult?: IValidationResult;
  valid?: boolean;
  onChange: (value: TInputValue) => void;
  inputName: string;
  placeholder?: string;
  placeholderTranslation?: ETranslation;
  readOnly?: boolean;
  validation?: IInputValidation;
}

export const isInputDisabled = (
  disableFields: boolean,
  item: IInputFieldItem,
  options?: IInputOptions
) => {
  return disableFields || item.disabled || (options && options.disabled);
};

export const inputsToObject = function <T>(inputs: IInputField): T {
  const result: { [key: string]: TInputValue } = {};
  for (let key in inputs) {
    result[key] = inputs[key].value;
  }
  return result as unknown as T;
};

const quillModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    ["link", "image"],
    ["clean"], // remove formatting button
  ],
};

const quillFormats = [
  "font",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  "color",
  "background",
  "list",
  "bullet",
  "align",
  "indent",
  "link",
  "image",
];

const Input: React.FC<IProps> = ({
  label,
  labelTranslation,
  value,
  onChange,
  type,
  options,
  weight,
  inputName,
  info,
  topInfo,
  infoColor,
  placeholder,
  placeholderTranslation,
  validationResult = { isValid: true, message: "", messageParams: {} },
  data,
  disabled,
  max,
  min,
  showLabel,
  updateAction,
  containerStyles,
  showValidation,
  pre,
  post,
  onClick,
  hideUnselected,
  onAdd = () => console.log("onAdd needs to be passed as parameter"),
  onRemove = () =>
    Promise.reject(console.log("onRemove needs to be passed as parameter")),
  onSetValid = () => console.log("onSetValid needs to be passed as paramater"),
  onBlurModifyValue,
  onBlur,
  multiple,
  readOnly,
  validation,
  loading,
  onDrop,
  fetchOptions,
  onFocus,
  isNewPassword,
}) => {
  //console.log("inputUpdated", inputName)
  const { t } = useTranslation();
  const { onAutoUpdate, initDone } = useContext(InputContext);
  const [initValue, setInitValue] = useState(value);
  const [isDisabled, setIsDisabled] = useState(readOnly);

  useEffect(() => {
    if (initDone) {
      setInitValue(value);
    }
    // eslint-disable-next-line
  }, [initDone, setInitValue]);

  const autoUpdateHandler = (value: TInputValue) => {
    if (onBlur) {
      onBlur(value, inputName);
    }

    if (onBlurModifyValue) {
      value = onBlurModifyValue(value);
    }

    if (initValue !== value) {
      if (updateAction && onAutoUpdate) {
        onAutoUpdate(inputName, value, updateAction, data); // Maybe needs some checks here if context missing.
      }
      setInitValue(value);
    }
  };

  const singleChangeHandler = (value: TInputValue) => {
    onChange(value);
    autoUpdateHandler(value);
  };

  const infoClasses = [classes.Info];

  switch (infoColor) {
    case EInfoColor.DANGER:
      infoClasses.push(classes.InfoDanger);
      break;
    default:
  }

  const getLabel = useCallback(
    (
      inputName: string,
      label?: string,
      showLabel?: boolean,
      labelTranslation?: ETranslation,
      required?: boolean
    ) => {
      if (!label && !showLabel && !labelTranslation) return null;
      return (
        <label className={classes.Label} htmlFor={inputName}>
          {labelTranslation ? t(labelTranslation) : label}
          {required ? "*" : ""} {showLabel && <>&nbsp;</>}
        </label>
      );
    },
    [t]
  );

  const invalid = showValidation && !validationResult.isValid && !disabled;
  const invalidMessage =
    showValidation && !validationResult.isValid && validationResult.message;

  const classNames = [classes.Container];
  if (invalid) {
    classNames.push(classes.Invalid);
  }

  const inputGroupClassNames = [classes.InputGroup];
  if (pre) {
    inputGroupClassNames.push(classes.HasPre);
  }
  if (post) {
    inputGroupClassNames.push(classes.HasPost);
  }

  return (
    <div
      className={classNames.join(" ")}
      style={{ flexGrow: weight, ...containerStyles }}
      onClick={isDisabled ? () => setIsDisabled(false) : undefined}
    >
      {getLabel(
        inputName,
        label,
        showLabel,
        labelTranslation,
        validation?.required
      )}
      {topInfo && <div className={classes.TopInfo}>{topInfo}</div>}
      <div className={inputGroupClassNames.join(" ")}>
        {pre && <div className={classes.Pre}>{pre}</div>}
        {(() => {
          if (loading) {
            return (
              <div className={classes.Input}>
                <Spinner size={ESpinnerSize.SMALL} />
              </div>
            );
          }

          switch (type) {
            case EInputType.text:
            case EInputType.number:
            case EInputType.date:
            case EInputType.time:
            case EInputType.email:
            case EInputType.tel:
            case EInputType.password:
              return (
                <input
                  className={classes.Input}
                  disabled={disabled || isDisabled}
                  type={type}
                  value={value as string}
                  autoComplete={isNewPassword ? 'new-password' : undefined}
                  placeholder={
                    placeholderTranslation
                      ? t(placeholderTranslation)
                      : placeholder
                      ? placeholder
                      : label
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(event.target.value)
                  }
                  onBlur={() => autoUpdateHandler(value)}
                  onFocus={
                    onFocus
                      ? () => onFocus(value)
                      : readOnly
                      ? (e) => e.target.removeAttribute("readonly")
                      : undefined
                  }
                  readOnly={readOnly}
                />
              );
            case EInputType.select:
              return (
                <select
                  disabled={disabled}
                  className={classes.Input}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    onChange(event.target.value)
                  }
                  onBlur={(event) => autoUpdateHandler(event.target.value)}
                  value={value as string}
                >
                  {options &&
                    options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {" "}
                        {option.labelTranslation
                          ? t(option.labelTranslation)
                          : option.label}
                      </option>
                    ))}
                </select>
              );
            case EInputType.checkbox:
              return (
                <input
                  name={inputName}
                  id={inputName}
                  type="checkbox"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    singleChangeHandler(event.target.checked)
                  }
                  checked={value as boolean}
                  disabled={disabled}
                />
              );
            case EInputType.textarea:
              return (
                <AutoTextArea
                  className={classes.Input}
                  value={value as string}
                  placeholder={
                    placeholderTranslation
                      ? t(placeholderTranslation)
                      : placeholder
                      ? placeholder
                      : label
                  }
                  onChange={(value) => onChange(value)}
                  onBlur={() => autoUpdateHandler(value)}
                  disabled={disabled}
                />
              );
            case EInputType.datepicker:
              if (value) value = new Date(value as string);
              return (
                <ReactDatePicker
                  className={["form-control", classes.Input].join(" ")}
                  selected={value as Date}
                  onChange={(date) => singleChangeHandler(date)}
                  // onBlur={() => autoUpdateHandler(value)}
                  dateFormat="dd.MM.yyyy"
                  locale={enUS}
                  isClearable={!disabled}
                  placeholderText={
                    placeholderTranslation
                      ? t(placeholderTranslation)
                      : placeholder
                      ? placeholder
                      : label
                  }
                  disabled={disabled}
                  wrapperClassName="datePicker"
                  // onCalendarClose={() => autoUpdateHandler(value)}
                />
              );
            case EInputType.dropzone:
              return (
                onDrop && (
                  <Dropzone
                    onDrop={(acceptedFiles) => onDrop(inputName, acceptedFiles)}
                    multiple={multiple}
                    disabled={disabled}
                  />
                )
              );
            case EInputType.wysiwyg:
              return (
                <ReactQuill
                  value={value as string}
                  onChange={onChange}
                  modules={disabled ? { toolbar: false } : quillModules}
                  formats={quillFormats}
                  readOnly={disabled}
                />
              );
            case EInputType.reactSelect:
              return (
                <Select
                  name={inputName}
                  onChange={singleChangeHandler}
                  value={value as string | string[]}
                  options={options}
                  multiple={multiple}
                  placeholder={
                    placeholderTranslation
                      ? t(placeholderTranslation)
                      : placeholder
                      ? placeholder
                      : undefined
                  }
                  loading={loading}
                  disabled={disabled}
                />
              );
          }
        })()}
        {post && <div className={classes.Post}>{post}</div>}
      </div>
      {info && <div className={infoClasses.join(" ")}>{info}</div>}
      {invalidMessage && (
        <div className={[classes.Info, classes.InfoDanger].join(" ")}>
          {t(invalidMessage, {
            ...(validationResult.messageParams
              ? validationResult.messageParams
              : {}),
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(Input, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.validationResult === nextProps.validationResult &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.showValidation === nextProps.showValidation &&
    prevProps.options === nextProps.options &&
    prevProps.min === nextProps.min &&
    prevProps.info === nextProps.info &&
    prevProps.topInfo === nextProps.topInfo &&
    prevProps.pre === nextProps.pre &&
    prevProps.labelTranslation === nextProps.labelTranslation &&
    prevProps.placeholderTranslation === nextProps.placeholderTranslation &&
    prevProps.onAdd === nextProps.onAdd &&
    prevProps.loading === nextProps.loading
  );
});

import { AxiosResponse } from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Dispatch } from "redux";
import axios from "../../axios";
import { AuthUser } from "../../classes/AuthUser";
import firebase from "../../firebase";
import { EActionTypes } from "../EActionTypes";
import IAuthAction from "../../interfaces/action/IAuthAction";

type TAction = IAuthAction;
const apiPath = "/json/auth";

const authStart = (): TAction => {
  return {
    type: EActionTypes.AUTH_START,
  };
};

const authSuccess = (user: AuthUser | null, firebaseToken: string): TAction => {
  return {
    type: EActionTypes.AUTH_SUCCESS,
    user,
  };
};

const authFail = (error: string): TAction => {
  return {
    type: EActionTypes.AUTH_FAIL,
    error,
  };
};

export const auth = (
  email: string,
  password: string,
  preferredLanguage: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch(authStart());
    try {
      await signInWithEmailAndPassword(getAuth(firebase), email, password)
        .then(async (response) => {
          if (response.user) {
            const tokenResult = await response.user.getIdTokenResult();
            if (tokenResult) {
              const res = await axios.post(`${apiPath}/login`, {
                preferredLanguage,
              });
              dispatch(authSuccess(new AuthUser(res.data), tokenResult.token));
            } else {
              dispatch(authFail("error"));
            }
          } else {
            dispatch(authFail("error"));
          }
        })
        .catch((error) => {
          dispatch(authFail(error.message));
        });
    } catch ({ response }) {
      dispatch(authFail((response as AxiosResponse).data));
    }
  };
};

const getCurrentUserStart = (): TAction => {
  return {
    type: EActionTypes.AUTH_GET_START,
  };
};

const getCurrentUserSuccess = (user: AuthUser | null): TAction => {
  return {
    type: EActionTypes.AUTH_GET_SUCCESS,
    user,
  };
};

const getCurrentUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.AUTH_GET_FAIL,
    error,
  };
};

export const getCurrentUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getCurrentUserStart());
    try {
      const res = await axios.post(`${apiPath}/login`);
      dispatch(getCurrentUserSuccess(new AuthUser(res.data)));
    } catch ({ response }) {
      dispatch(getCurrentUserFail((response as AxiosResponse).data));
    }
  };
};

export const getCurrentUserClear = (): TAction => {
  return {
    type: EActionTypes.AUTH_GET_CLEAR,
  };
};

import React from "react";
import { IMenuItem } from "../components/Header/Menu/Menu";

const HeaderContext = React.createContext<{
  menuItems: IMenuItem[];
  dropdownMenuItems: IMenuItem[];
}>({
  menuItems: [],
  dropdownMenuItems: [],
});

export default HeaderContext;

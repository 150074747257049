import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEn from "./translations/translation-en";
import translationsFi from "./translations/translation-fi";

export enum ELanguage {
  FI = "fi", EN = "en"
}

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  [ELanguage.EN]: {
    translation: translationsEn,
  },
  [ELanguage.FI]: {
    translation: translationsFi,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fi",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

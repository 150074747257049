import React from "react";
import { useTranslation } from "react-i18next";

import { useConfirmModal } from "../../hooks/useConfirmModal";
import { IAttachment } from "../../interfaces/domain/IAttachment";
import { ETranslation } from "../../translations/translation-keys";
import Button, { EButtonSize } from "../UI/Button/Button";
import Table from "../UI/Table/Table";
import classes from "./Attachments.module.scss";
import axios from "../../axios";

interface IProps {
  attachments: IAttachment[];
  onDelete: (attachment: IAttachment) => void;
}

const Attachments: React.FC<IProps> = ({ attachments, onDelete }) => {
  const { t } = useTranslation();
  const openConfirmDeleteModal = useConfirmModal();

  const deleteHandler = async (attachment: IAttachment) => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.CONFIRM_DELETE_ATTACHMENT)
    );
    if (success) onDelete(attachment);
  };

  const openHandler = async (name: string, link: string) => {
    const blob = await axios.get(link, { responseType: "blob" });
    const url = window.URL.createObjectURL(new Blob([blob.data]));
    const el = document.createElement("a");
    el.href = url;
    el.setAttribute("download", name);
    document.body.appendChild(el);
    el.click();
    el.remove();
  };

  return (
    <Table className={classes.Container} bordered>
      <thead>
        <tr>
          <th colSpan={3}>Liitteet</th>
        </tr>
      </thead>
      <tbody>
        {attachments.map((attachment) => (
          <tr key={attachment.id}>
            <td>{attachment.clientName}</td>
            <td style={{ width: "1px" }}>
              <Button
                size={EButtonSize.SMALL}
                onClick={() =>
                  openHandler(attachment.clientName, attachment.link)
                }
              >
                {t(ETranslation.COMMON_DOWNLOAD)}
              </Button>
            </td>
            <td style={{ width: "1px" }}>
              <Button
                size={EButtonSize.SMALL}
                onClick={() => deleteHandler(attachment)}
              >
                {t(ETranslation.COMMON_DELETE)}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default Attachments;

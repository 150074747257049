import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ERouteStaticParams, Routes } from "../../classes/Routes";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import UserList from "../../components/Users/UserList/UserList";
import UserSearch, {
  IUserSearch,
} from "../../components/Users/UserSearch/UserSearch";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";

const Users: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { loading, searchedUsers } = useAppSelector((state) => state.user);

  const searchHandler = useCallback(
    (search: IUserSearch) => {
      dispatch(actions.listUsersSearch(search));
    },
    [dispatch]
  );

  return (
    <>
      <UserSearch onSearch={searchHandler} />
      <Container>
        <Title
          translation={ETranslation.USERS}
          route={Routes.USER(ERouteStaticParams.new)}
        />
        {loading ? (
          <Spinner />
        ) : searchedUsers ? (
          <UserList users={searchedUsers} />
        ) : (
          t(ETranslation.NO_USERS)
        )}
      </Container>
    </>
  );
};

export default Users;

import IPostAction from "../../interfaces/action/IPostAction";
import IPostState from "../../interfaces/state/IPostState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IPostState;
type ReducerSignature = (
  state: ReducerState,
  action: IPostAction
) => ReducerState;

const initialState: ReducerState = {
  post: null,
  posts: null,
  loading: false,
  error: null,
  saveOrUpdateOk: false,
  id: null,
  saveOrUpdateLoading: false,
  copyLoading: false,
  copyId: null
};

const clearPost: ReducerSignature = (state, action): ReducerState => {
  return { ...initialState };
};

const listPostsStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listPostsSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    posts: action.posts || null,
    error: null,
    loading: false,
  };
};

const listPostsFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, posts: null, loading: false, error: action.error };
};

const getPostStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getPostSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    post: action.post || null,
    error: null,
    loading: false,
  };
};

const getPostFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, post: null, loading: false, error: action.error };
};

const updatePostStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    saveOrUpdateLoading: true,
    saveOrUpdateOk: false,
    error: null,
  };
};

const updatePostSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    post: action.post || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const updatePostFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    post: null,
    loading: false,
    saveOrUpdateLoading: false,
    error: action.error,
  };
};

const savePostStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    saveOrUpdateLoading: true,
    saveOrUpdateOk: false,
    error: null,
  };
};

const savePostSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    post: action.post || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const savePostFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    post: null,
    loading: false,
    error: action.error,
    saveOrUpdateLoading: false,
    saveOrUpdateOk: false,
  };
};

const updatePostCommentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null };
};

const updatePostCommentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let post = null;
  if (state.post) {
    post = {
      ...state.post,
      comments: action.post?.comments || [],
    };
  }
  return {
    ...state,
    post,
    error: null,
    loading: false,
  };
};

const updatePostCommentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, post: null, loading: false, error: action.error };
};

const deletePostAttachmentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const deletePostAttachmentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let post = null;
  if (state.post && state.post.attachments?.length > 0) {
    post = {
      ...state.post,
      attachments:
        state.post?.attachments.filter(
          (attachment) => attachment.id !== action.id
        ) || [],
    };
  }
  return {
    ...state,
    post: post || null,
    error: null,
    loading: false,
  };
};

const deletePostAttachmentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const deletePostStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const deletePostSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    post: null,
    error: null,
    loading: false,
  };
};

const deletePostFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const copyPostStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    copyId: null,
    copyLoading: true,
    error: null,
  };
};

const copyPostSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    copyId: action.id || null,
    error: null,
    copyLoading: false,
  };
};

const copyPostFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    copyId: null,
    copyLoading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: IPostAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.POST_CLEAR:
      return clearPost(state, action);
    case EActionTypes.POST_LIST_START:
      return listPostsStart(state, action);
    case EActionTypes.POST_LIST_SUCCESS:
      return listPostsSuccess(state, action);
    case EActionTypes.POST_LIST_FAIL:
      return listPostsFail(state, action);
    case EActionTypes.POST_GET_START:
      return getPostStart(state, action);
    case EActionTypes.POST_GET_SUCCESS:
      return getPostSuccess(state, action);
    case EActionTypes.POST_GET_FAIL:
      return getPostFail(state, action);
    case EActionTypes.POST_UPDATE_START:
      return updatePostStart(state, action);
    case EActionTypes.POST_UPDATE_SUCCESS:
      return updatePostSuccess(state, action);
    case EActionTypes.POST_UPDATE_FAIL:
      return updatePostFail(state, action);
    case EActionTypes.POST_SAVE_START:
      return savePostStart(state, action);
    case EActionTypes.POST_SAVE_SUCCESS:
      return savePostSuccess(state, action);
    case EActionTypes.POST_SAVE_FAIL:
      return savePostFail(state, action);
    case EActionTypes.POST_COMMENT_UPDATE_START:
      return updatePostCommentStart(state, action);
    case EActionTypes.POST_COMMENT_UPDATE_SUCCESS:
      return updatePostCommentSuccess(state, action);
    case EActionTypes.POST_COMMENT_UPDATE_FAIL:
      return updatePostCommentFail(state, action);
    case EActionTypes.POST_ATTACHMENT_DELETE_START:
      return deletePostAttachmentStart(state, action);
    case EActionTypes.POST_ATTACHMENT_DELETE_SUCCESS:
      return deletePostAttachmentSuccess(state, action);
    case EActionTypes.POST_ATTACHMENT_DELETE_FAIL:
      return deletePostAttachmentFail(state, action);
    case EActionTypes.POST_DELETE_START:
      return deletePostStart(state, action);
    case EActionTypes.POST_DELETE_SUCCESS:
      return deletePostSuccess(state, action);
    case EActionTypes.POST_DELETE_FAIL:
      return deletePostFail(state, action);
    case EActionTypes.POST_COPY_START:
      return copyPostStart(state, action);
    case EActionTypes.POST_COPY_SUCCESS:
      return copyPostSuccess(state, action);
    case EActionTypes.POST_COPY_FAIL:
      return copyPostFail(state, action);
    default:
      return state;
  }
};

export default reducer;

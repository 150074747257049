import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { cityOptions } from "../../../data/city-data";
import { fieldOfWorkOptions } from "../../../data/select-data";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useCreateInput } from "../../../hooks/useCreateInput";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData, initForm } from "../../UI/Input/input-utils";
import InputGroup from "../../UI/InputGroup/InputGroup";
import LocalStorage from "../../../shared/localstorage-utils";
import Table from "../../UI/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faCheck } from "@fortawesome/free-solid-svg-icons";
import classes from "./JobApplicationSearch.module.scss";
import { useAuthUser } from "../../../hooks/useAuthUser";

export interface IJobApplicationSearch {
  query?: string;
  fieldsOfWork?: string;
  city?: string;
  historyId?: string;
  archived?: boolean;
}

interface IProps {
  onSearch: (search: IJobApplicationSearch) => void;
}

enum EJobApplicationSearch {
  query = "query",
  fieldsOfWork = "fieldsOfWork",
  city = "city",
  historyId = "historyId",
  archived = "archived",
}

const initialInputs: IInputField = {
  [EJobApplicationSearch.query]: {
    type: EInputType.text,
    labelTranslation: ETranslation.SEARCH_TITLE,
    placeholderTranslation: ETranslation.SEARCH_TITLE,
    value: "",
  },
  [EJobApplicationSearch.fieldsOfWork]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.FIELDS_OF_WORK,
    placeholderTranslation: ETranslation.SELECT_FIELD_OF_WORK_PLACEHOLDER,
    value: "",
    // options: fieldOfWorkOptions,
  },
  [EJobApplicationSearch.city]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.CITY,
    placeholderTranslation: ETranslation.SELECT_CITY_PLACEHOLDER,
    value: "",
    options: cityOptions,
  },
  [EJobApplicationSearch.historyId]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.JOB_APPLICATION_TITLE_APPLY,
    placeholderTranslation: ETranslation.JOB_APPLICATION_TITLE_APPLY,
    value: "",
  },
  [EJobApplicationSearch.archived]: {
    type: EInputType.checkbox,
    labelTranslation: ETranslation.ARCHIVED,
    placeholderTranslation: ETranslation.ARCHIVED,
    value: "",
  },
};

const JobApplicationSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const createInput = useCreateInput(inputs, setInputs);
  const dispatch = useAppDispatch();
  const [showLatestSearches, setShowLatestSearches] = useState(false);
  const authUser = useAuthUser();

  const { loading: postsLoading, posts } = useAppSelector(
    (state) => state.post
  );

  const postsOptions = useMemo(
    () =>
      posts?.map((post) => {
        let title = post.title;
        if (post.archived) {
          title += ' "' + t(ETranslation.ARCHIVED) + '"';
        }
        return { value: post.id, label: title };
      }),
    [posts, t]
  );

  const fieldsOfWorkOptions = useMemo(
    () =>
      fieldOfWorkOptions.map((option) => ({
        value: option.labelTranslation ? t(option.labelTranslation) : "",
        labelTranslation: option.labelTranslation,
      })),
    [t]
  );

  const findDatastoreJobApplications = useCallback(() => {
    dispatch(actions.listJobApplications({}));
    initForm(setInputs, getInputData(initialInputs));
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.listPosts({ fieldsOfWork: "", archived: false }, true));
    const search = LocalStorage.getLatestJobApplicationSearch();
    if (search) {
      initForm(setInputs, search);
      onSearch(search);
    } else {
      dispatch(actions.listJobApplications({}));
    }
  }, [dispatch, onSearch]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSearch(getInputData(inputs));
        }}
      >
        <InputGroup>
          {createInput(EJobApplicationSearch.query)}
          {createInput(EJobApplicationSearch.fieldsOfWork, {
            options: fieldsOfWorkOptions,
          })}
          {createInput(EJobApplicationSearch.city)}
          {createInput(EJobApplicationSearch.historyId, {
            options: postsOptions,
            loading: postsLoading,
          })}
          {authUser?.isAdmin && createInput(EJobApplicationSearch.archived)}
        </InputGroup>
        <Button type="submit" size={EButtonSize.SMALL} onClick={() => {}}>
          {t(ETranslation.COMMON_SEARCH)}
        </Button>{" "}
        <Button size={EButtonSize.SMALL} onClick={findDatastoreJobApplications}>
          {t(ETranslation.SEARCH_FIND_LATEST)}
        </Button>
      </form>
      <div className={classes.LatestSearchContainer}>
        <h3
          onClick={() => setShowLatestSearches((show) => !show)}
          style={{ cursor: "pointer" }}
        >
          {t(ETranslation.SEARCH_LATEST)}{" "}
          <FontAwesomeIcon
            icon={showLatestSearches ? faCaretUp : faCaretDown}
          />
        </h3>
        <span>
          {t(ETranslation.SEARCH_EXAMPLE_TEXT)} ala:metalli ajokortti:b
          kortti:hygieniap kunta:tuusula kommentti:~Taitava pvm&gt;2023-02-20 postinumero:00100
        </span>
      </div>
      {showLatestSearches && (
        <Table hover>
          <thead>
            <tr>
              <th>{t(ETranslation.SEARCH_TITLE)}</th>
              <th>{t(ETranslation.FIELDS_OF_WORK)}</th>
              <th>{t(ETranslation.CITY)}</th>
              <th>{t(ETranslation.JOB_APPLICATION_TITLE_APPLY)}</th>
              <th>{t(ETranslation.ARCHIVED)}</th>
            </tr>
          </thead>
          <tbody>
            {LocalStorage.getJobApplicationSearch()?.map((search, i) => (
              (search.city || search.fieldsOfWork || search.historyId || search.query || search.archived) &&
              <tr key={i} onClick={() => initForm(setInputs, search)}>
                <td>{search.query}</td>
                <td>{search.fieldsOfWork}</td>
                <td>{search.city}</td>
                <td>
                  {search.historyId &&
                    posts &&
                    posts.find((post) => post.id === search.historyId)?.title}
                </td>
                <td>{search.archived && <FontAwesomeIcon icon={faCheck}/>}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default JobApplicationSearch;

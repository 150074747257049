import { ETranslation } from "../../translations/translation-keys";
import { IAttachment } from "./IAttachment";
import { BaseDomain } from "./IBaseDomain";
import { IComment } from "./IComment";
import { IEducationHistory } from "./IEducationHistory";
import { EFieldOfWork } from "./IPost";
import { EGender } from "./IUser";
import { IWorkHistory } from "./IWorkHistory";

export enum EJobApplicationStatus {
  ISSUED = "ISSUED",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  PLANNING = "PLANNING",
}

export enum EApplyOptions {}

export const JobApplicationStatus = {
  [EJobApplicationStatus.ISSUED]: ETranslation.STATUS_ISSUED,
  [EJobApplicationStatus.COMPLETE]: ETranslation.STATUS_COMPLETE,
  [EJobApplicationStatus.CANCELLED]: ETranslation.STATUS_CANCELLED,
  [EJobApplicationStatus.PLANNING]: ETranslation.STATUS_PLANNING,
};

export enum EDriversLicenceType {
	B1 = "B1", 
	B = "B", 
	C1 = "C1", 
	C = "C", 
	D1 = "D1", 
	D = "D", 
	BE = "BE", 
	C1E = "C1E", 
	CE = "CE", 
	D1E = "D1E", 
	DE = "DE"
}

export enum ECommonCard {
	anniskelupassi = "Anniskelupassi",
	asbestipurkutyolupa = "Asbestipurkutyolupa",
	hygieniapassi = "Hygieniapassi",
	ikarajapassi = "Ikarajapassi",
	jarjestyksenvalvojakortti = "Jarjestyksenvalvojakortti",
	tulityokortti = "Tulityokortti",
	tyoturvallisuuskortti = "Tyoturvallisuuskortti",
	none = "noneOfTheAbove"
}

export enum EWorkType {
	partTime = "partTime", 
	temporary = "temporary", 
	permanent = "permanent", 
	fullTime = "fullTime", 
}

export enum EWorkTime {
  evenings = "evenings", 
	weekdays = "weekdays", 
	weekends = "weekends",
  mornings = "mornings", 
	shiftWork = "shiftWork"
}

export enum EBaseEducation {
  gradeSchool = "gradeSchool",
  highSchool = "highSchool"
}

export interface IHistory {
	applyDate: string;
	title: string;
	postId: number;
}

export interface IJobApplication extends BaseDomain {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  address: string;
  postcode: string;
  place: string;
  birthYear: string;
  gender: EGender;

  professions: string[];
  workExperienceFields: EFieldOfWork[];
  specialSkills: string;
  itSkills: string;
  hobbies: string;
  otherProfession: string;
  languageSkillFi: string;
  languageSkillEn: string;
  languageSkillSe: string;
  otherLanguages: string;

  driversLicence: boolean;
  ownCar: boolean;
  driversLicenceDetails: EDriversLicenceType[];
  commonCards: ECommonCard[];
  otherCards: string;

  workType: EWorkType[];
  workTime: EWorkTime[];
  preferredStartingDate: Date;
  startingDateMoreInfo: string;
  salaryPreference: string;

  unwantedJobs: string;
  alsoApplyToFields: EFieldOfWork[];
  history: IHistory[];

  gdprConcent: boolean;
  otherInfo: string;
  applyOptions: EApplyOptions;
  education: EBaseEducation;
  	
  jsonString: string;

  attachments: IAttachment[];
  workHistory: IWorkHistory[];
  educationHistory: IEducationHistory[];
  formData: [];
  comments: IComment[];
}

import axios from "../../axios";
import { Dispatch } from "redux";
import IAttachmentAction from "../../interfaces/action/IAttachmentAction";
import { IAttachment } from "../../interfaces/domain/IAttachment";
import { EActionTypes } from "../EActionTypes";
import { AxiosResponse } from "axios";
import { EKind } from "../../interfaces/domain/IBaseDomain";

type TAction = IAttachmentAction;
const apiPath = "/json/attachments";

export const clearAttachment = (): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_CLEAR,
  };
};

const uploadAttachmentStart = (inputName: string): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_UPLOAD_START,
    inputName,
  };
};

const uploadAttachmentSuccess = (inputName: string, attachments: IAttachment[]): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_UPLOAD_SUCCESS,
    inputName,
    attachments,
  };
};

const uploadAttachmentFail = (error: string): TAction => {
  return {
    type: EActionTypes.ATTACHMENT_UPLOAD_FAIL,
    error,
  };
};

export const uploadAttachment = (inputName: string, files: File[], kind: EKind, id?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(uploadAttachmentStart(inputName));

    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    try {
      const res = await axios.post<IAttachment[]>(`${apiPath}/upload?kind=${kind}&id=${id || ''}`, data);
      dispatch(uploadAttachmentSuccess(inputName, res.data));
    } catch ({ response }) {
      dispatch(uploadAttachmentFail((response as AxiosResponse).data));
    }
  };
};

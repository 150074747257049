import React, { ReactNode, CSSProperties } from "react";

import classes from "./Table.module.scss";

interface IProps {
  children: ReactNode;
  hover?: boolean;
  className?: string;
  style?: CSSProperties;
  containerClassName?: string;
  bordered?: boolean;
}

const Table: React.FC<IProps> = ({
  children,
  hover,
  className,
  style,
  containerClassName,
  bordered,
}) => {
  const tableClassNames = [classes.Table];
  if (hover) {
    tableClassNames.push(classes.Hover);
  }
  if (className) {
    tableClassNames.push(className);
  }
  const containerClassNames = [classes.Container];
  if (containerClassName) {
    containerClassNames.push(containerClassName);
  }

  if (bordered) {
    tableClassNames.push(classes.Bordered);
  }

  return (
    <div className={containerClassNames.join(" ")}>
      <table style={style} className={tableClassNames.join(" ")}>
        {children}
      </table>
    </div>
  );
};

export default Table;

import { ELanguage } from "../i18n";
import { EUserLevel } from "../interfaces/domain/IUser";
import { ERoute } from "./Routes";

interface IAuthUser {
  id: number;
  firebaseUserId: string;
  name: string;
  email: string;
  isTermsOfUseAccepted: boolean;
  preferredLanguage: ELanguage;
  level: EUserLevel;
}

export class AuthUser implements IAuthUser {
  public id: number;
  public firebaseUserId: string;
  public name: string;
  public email: string;
  public isTermsOfUseAccepted: boolean;
  public preferredLanguage: ELanguage;
  public level: EUserLevel;

  public constructor(authUser: IAuthUser) {
    this.id = authUser.id;
    this.firebaseUserId = authUser.firebaseUserId;
    this.name = authUser.name;
    this.email = authUser.email;
    this.isTermsOfUseAccepted = authUser.isTermsOfUseAccepted || false;
    this.preferredLanguage = authUser.preferredLanguage;
    this.level = authUser.level;
  }

  public get routes(): ERoute[] {
    const routes: ERoute[] = [ERoute.LOGIN, ERoute.JOB_APPLICATIONS];

    const { isAdmin, isOperative, isViewer } = this;

    if (isAdmin || isOperative || isViewer) {
      routes.push.apply(routes, [
        ERoute.POSTS,
        ERoute.POST,
      ]);
    }

    if (isAdmin) {
      routes.push.apply(routes, [
        ERoute.USERS,
        ERoute.USER,
        ERoute.LOG,
      ]);
    }

    return routes;
  }

  public get isAdmin(): boolean {
    return this.level === EUserLevel.ADMIN;
  }

  public get isOperative(): boolean {
    return this.level === EUserLevel.OPERATIVE;
  }

  public get isViewer(): boolean {
    return this.level === EUserLevel.VIEWER;
  }

  public get redirect(): ERoute {
    if (this.isAdmin) {
      return ERoute.POSTS;
    }
    if (this.isOperative) {
    }
    if (this.isViewer) {
    }
    return ERoute.POSTS;
  }
}

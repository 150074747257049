export enum EActionTypes {
  // Auth
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,

  AUTH_GET_START,
  AUTH_GET_SUCCESS,
  AUTH_GET_FAIL,

  AUTH_GET_CLEAR,

  // posts
  POST_CLEAR,

  POST_LIST_START,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,

  POST_GET_START,
  POST_GET_SUCCESS,
  POST_GET_FAIL,

  POST_UPDATE_START,
  POST_UPDATE_SUCCESS,
  POST_UPDATE_FAIL,

  POST_SAVE_START,
  POST_SAVE_SUCCESS,
  POST_SAVE_FAIL,

  POST_COMMENT_UPDATE_START,
  POST_COMMENT_UPDATE_SUCCESS,
  POST_COMMENT_UPDATE_FAIL,

  POST_ATTACHMENT_DELETE_START,
  POST_ATTACHMENT_DELETE_SUCCESS,
  POST_ATTACHMENT_DELETE_FAIL,
  
  POST_DELETE_START,
  POST_DELETE_SUCCESS,
  POST_DELETE_FAIL,

  POST_COPY_START,
  POST_COPY_SUCCESS,
  POST_COPY_FAIL,

  // job applications
  JOB_APPLICATION_CLEAR,

  JOB_APPLICATION_LIST_START,
  JOB_APPLICATION_LIST_SUCCESS,
  JOB_APPLICATION_LIST_FAIL,

  JOB_APPLICATION_GET_START,
  JOB_APPLICATION_GET_SUCCESS,
  JOB_APPLICATION_GET_FAIL,

  JOB_APPLICATION_UPDATE_START,
  JOB_APPLICATION_UPDATE_SUCCESS,
  JOB_APPLICATION_UPDATE_FAIL,

  JOB_APPLICATION_SAVE_START,
  JOB_APPLICATION_SAVE_SUCCESS,
  JOB_APPLICATION_SAVE_FAIL,

  JOB_APPLICATION_COMMENT_ADD_START,
  JOB_APPLICATION_COMMENT_ADD_SUCCESS,
  JOB_APPLICATION_COMMENT_ADD_FAIL,

  JOB_APPLICATION_COMMENT_DELETE_START,
  JOB_APPLICATION_COMMENT_DELETE_SUCCESS,
  JOB_APPLICATION_COMMENT_DELETE_FAIL,

  JOB_APPLICATION_ATTACHMENT_DELETE_START,
  JOB_APPLICATION_ATTACHMENT_DELETE_SUCCESS,
  JOB_APPLICATION_ATTACHMENT_DELETE_FAIL,

  JOB_APPLICATION_DELETE_START,
  JOB_APPLICATION_DELETE_SUCCESS,
  JOB_APPLICATION_DELETE_FAIL,

  JOB_APPLICATION_HISTORY_ADD_START,
  JOB_APPLICATION_HISTORY_ADD_SUCCESS,
  JOB_APPLICATION_HISTORY_ADD_FAIL,

  JOB_APPLICATION_PROFESSION_LIST_GET_START,
  JOB_APPLICATION_PROFESSION_LIST_GET_SUCCESS,
  JOB_APPLICATION_PROFESSION_LIST_GET_FAIL,

  JOB_APPLICATION_SEARCH_START,
  JOB_APPLICATION_SEARCH_SUCCESS,
  JOB_APPLICATION_SEARCH_FAIL,

  // User
  USER_LIST_START,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,

  USER_LIST_SEARCH_START,
  USER_LIST_SEARCH_SUCCESS,
  USER_LIST_SEARCH_FAIL,

  USER_GET_START,
  USER_GET_SUCCESS,
  USER_GET_FAIL,

  USER_UPDATE_START,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,

  USER_SAVE_START,
  USER_SAVE_SUCCESS,
  USER_SAVE_FAIL,

  USER_CLEAR,

  USER_DELETE_START,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,

  // Attachment
  ATTACHMENT_CLEAR,

  ATTACHMENT_UPLOAD_START,
  ATTACHMENT_UPLOAD_SUCCESS,
  ATTACHMENT_UPLOAD_FAIL,

  LOG_ITEM_LIST_START,
  LOG_ITEM_LIST_SUCCESS,
  LOG_ITEM_LIST_FAIL,
}

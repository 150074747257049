import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../classes/Routes";
import { ETranslation } from "../../translations/translation-keys";

interface IProps {
  translation: ETranslation;
  icon?: IconProp;
  route?: Routes;
  subtitle?: string;
}

const Title: React.FC<IProps> = ({
  translation,
  icon = faAdd,
  route,
  subtitle,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h2 style={{ marginTop: 0 }}>
        {t(translation)}
        {route && (
          <>
            {" "}
            <FontAwesomeIcon
              icon={icon}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(route)}
            />
          </>
        )}
      </h2>
      {subtitle && <h2 style={{ marginTop: 0 }}>{subtitle}</h2>}
    </div>
  );
};

export default Title;

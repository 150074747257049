export enum ERoute {
  LOGIN = "/login",
  POSTS = "/posts",
  POST = "/posts/:id",
  USERS = "/users",
  USER = "/users/:id",
  JOB_APPLICATIONS = "/jobapplications",
  JOB_APPLICATION = "/jobapplications/:id",
  LOG = "/log"
}

export enum ERouteStaticParams {
  new = "new",
}

type TParam = string | ERouteStaticParams;

interface IParams {
  [key: string]: TParam;
}

export class Routes {
  public static LOGIN = ERoute.LOGIN;
  public static POSTS = ERoute.POSTS;
  public static POST = (id: TParam) => Routes.withParams(ERoute.POST, { id });
  public static JOB_APPLICATIONS = ERoute.JOB_APPLICATIONS;
  public static JOB_APPLICATION = (id: TParam) =>
    Routes.withParams(ERoute.JOB_APPLICATION, { id });
  public static USERS = ERoute.USERS;
  public static USER = (id: TParam) => Routes.withParams(ERoute.USER, { id });
  public static LOG = ERoute.LOG;

  public static withParams(route: ERoute, params: IParams): string {
    let routeWithParams: string = route;
    for (let key in params) {
      routeWithParams = routeWithParams.replace(
        `:${key}`,
        params[key] as string
      );
    }
    return routeWithParams;
  }

  public static isNew(id: TParam): boolean {
    return id === ERouteStaticParams.new;
  }

  public static isNotNew(id: TParam): boolean {
    return id !== ERouteStaticParams.new;
  }
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { useUserOptions } from "../../../hooks/useUserOptions";
import { ILogItemSearch } from "../../../store/actions/logItemActions";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData } from "../../UI/Input/input-utils";

interface IProps {
  onSearch: (search: ILogItemSearch) => void;
}

enum ELogItemSearch {
  userId = "userId",
}

const initialInputs: IInputField = {
  [ELogItemSearch.userId]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.USER,
    placeholderTranslation: ETranslation.USER,
    value: "",
    options: [],
  },
};

const LogItemSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const createInput = useCreateInput(inputs, setInputs);
  const { userOptions, usersLoading } = useUserOptions();

  useEffect(() => {
    onSearch(getInputData(initialInputs));
  }, [onSearch]);

  return (
    <>
      {createInput(ELogItemSearch.userId, {
        options: userOptions,
        loading: usersLoading,
      })}
      <Button
        onClick={() => onSearch(getInputData(inputs))}
        size={EButtonSize.SMALL}
      >
        {t(ETranslation.COMMON_SEARCH)}
      </Button>
    </>
  );
};

export default LogItemSearch;

import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { IPostSearch } from "../../components/Posts/PostSearch/PostSearch";
import IPostAction from "../../interfaces/action/IPostAction";
import { IComment } from "../../interfaces/domain/IComment";
import { IPost } from "../../interfaces/domain/IPost";
import { EActionTypes } from "../EActionTypes";

type TAction = IPostAction;
const apiPath = "/json/post";

export const clearPost = () => {
  return {
    type: EActionTypes.POST_CLEAR,
  };
};

const listPostsStart = (): TAction => {
  return {
    type: EActionTypes.POST_LIST_START,
  };
};

const listPostsSuccess = (posts: IPost[]): TAction => {
  return {
    type: EActionTypes.POST_LIST_SUCCESS,
    posts,
  };
};

const listPostsFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_LIST_FAIL,
    error,
  };
};

export const listPosts = (search: IPostSearch, includeArchived?: boolean) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listPostsStart());
    try {
      let res = null;
      if (includeArchived === true) {
        const includeArchivedPost = { includeArchived: true };
        res = await axios.post<IPost[]>(`${apiPath}/list`, includeArchivedPost);
      } else {
        res = await axios.post<IPost[]>(`${apiPath}/list`, search);
      }
      dispatch(listPostsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listPostsFail((response as AxiosResponse).data));
    }
  };
};

const getPostStart = (): TAction => {
  return {
    type: EActionTypes.POST_GET_START,
  };
};

const getPostSuccess = (post: IPost): TAction => {
  return {
    type: EActionTypes.POST_GET_SUCCESS,
    post,
  };
};

const getPostFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_GET_FAIL,
    error,
  };
};

export const getPost = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getPostStart());
    try {
      const res = await axios.get<IPost>(`${apiPath}/get?id=${id}`);
      dispatch(getPostSuccess(res.data));
    } catch ({ response }) {
      dispatch(getPostFail((response as AxiosResponse).data));
    }
  };
};

const updatePostStart = (): TAction => {
  return {
    type: EActionTypes.POST_UPDATE_START,
  };
};

const updatePostSuccess = (post: IPost): TAction => {
  return {
    type: EActionTypes.POST_UPDATE_SUCCESS,
    post,
  };
};

const updatePostFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_UPDATE_FAIL,
    error,
  };
};

export const updatePost = (post: IPost) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updatePostStart());
    try {
      const res = await axios.put<IPost>(`${apiPath}/update`, post);
      dispatch(updatePostSuccess(res.data));
    } catch ({ response }) {
      dispatch(updatePostFail((response as AxiosResponse).data));
    }
  };
};

const savePostStart = (): TAction => {
  return {
    type: EActionTypes.POST_SAVE_START,
  };
};

const savePostSuccess = (post: IPost): TAction => {
  return {
    type: EActionTypes.POST_SAVE_SUCCESS,
    post,
  };
};

const savePostFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_SAVE_FAIL,
    error,
  };
};

export const savePost = (post: IPost) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(savePostStart());
    try {
      const res = await axios.post<IPost>(`${apiPath}/add`, post);
      dispatch(savePostSuccess(res.data));
    } catch ({ response }) {
      dispatch(savePostFail((response as AxiosResponse).data));
    }
  };
};

const updatePostCommentsStart = (): TAction => {
  return {
    type: EActionTypes.POST_COMMENT_UPDATE_START,
  };
};

const updatePostCommentsSuccess = (post: IPost): TAction => {
  return {
    type: EActionTypes.POST_COMMENT_UPDATE_SUCCESS,
    post,
  };
};

const updatePostCommentsFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_COMMENT_UPDATE_FAIL,
    error,
  };
};

export const updatePostComments = (id: string, comments: IComment[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updatePostCommentsStart());
    try {
      const res = await axios.put<IPost>(`${apiPath}/comment/update`, {
        id,
        comments,
      });
      dispatch(updatePostCommentsSuccess(res.data));
    } catch ({ response }) {
      dispatch(updatePostCommentsFail((response as AxiosResponse).data));
    }
  };
};

const deletePostAttachmentStart = (): TAction => {
  return {
    type: EActionTypes.POST_ATTACHMENT_DELETE_START,
  };
};

const deletePostAttachmentSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.POST_ATTACHMENT_DELETE_SUCCESS,
    id,
  };
};

const deletePostAttachmentFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_ATTACHMENT_DELETE_FAIL,
    error,
  };
};

export const deletePostAttachment = (
  postId: string,
  attachmentId: string,
  serverName: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deletePostAttachmentStart());
    try {
      await axios.delete(
        `${apiPath}/attachments/delete?id=${postId}&attachmentId=${attachmentId}&serverName=${serverName}`
      );
      dispatch(deletePostAttachmentSuccess(attachmentId));
    } catch ({ response }) {
      dispatch(deletePostAttachmentFail((response as AxiosResponse).data));
    }
  };
};

const deletePostStart = (): TAction => {
  return {
    type: EActionTypes.POST_DELETE_START,
  };
};

const deletePostSuccess = (): TAction => {
  return {
    type: EActionTypes.POST_DELETE_SUCCESS,
  };
};

const deletePostFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_DELETE_FAIL,
    error,
  };
};

export const deletePost = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deletePostStart());
    try {
      await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deletePostSuccess());
    } catch ({ response }) {
      dispatch(deletePostFail((response as AxiosResponse).data));
    }
  };
};

const copyPostStart = (): TAction => {
  return {
    type: EActionTypes.POST_COPY_START,
  };
};

const copyPostSuccess = (post: IPost): TAction => {
  return {
    type: EActionTypes.POST_COPY_SUCCESS,
    id: post.id,
  };
};

const copyPostFail = (error: string): TAction => {
  return {
    type: EActionTypes.POST_COPY_FAIL,
    error,
  };
};

export const copyPost = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(copyPostStart());
    try {
      const res = await axios.post<IPost>(`${apiPath}/copy?id=${id}`);
      dispatch(copyPostSuccess(res.data));
    } catch ({ response }) {
      dispatch(copyPostFail((response as AxiosResponse).data));
    }
  };
};
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ERouteStaticParams, Routes } from "../../classes/Routes";
import JobApplicationList from "../../components/JobApplications/JobApplicationList/JobApplicationList";
import JobApplicationSearch, {
  IJobApplicationSearch,
} from "../../components/JobApplications/JobApplicationSearch/JobApplicationSearch";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import { useAuthUser } from "../../hooks/useAuthUser";
import Alert from "../../components/UI/Alert/Alert";

const JobApplications: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  // const [value, setValue] = useState<string>("");

  const { loading, jobApplications, error } = useAppSelector(
    (state) => state.jobApplication
  );

  const searchHandler = useCallback(
    (search: IJobApplicationSearch) => {
      if (
        !search.city &&
        !search.fieldsOfWork &&
        !search.historyId &&
        !search.query &&
        !search.archived
      ) {
        dispatch(actions.listJobApplications({}));
      } else {
        dispatch(actions.searchJobApplications(search));
      }
    },
    [dispatch]
  );

  return (
    <>
      <Container>
        <JobApplicationSearch onSearch={searchHandler} />
      </Container>
      <Container>
        <Title
          translation={ETranslation.JOB_APPLICATIONS}
          route={
            !authUser?.isViewer &&
            Routes.JOB_APPLICATION(ERouteStaticParams.new)
          }
        />
        {error ? (
          <Alert>{t(error)}</Alert>
        ) : loading ? (
          <Spinner />
        ) : jobApplications ? (
          <JobApplicationList jobApplications={jobApplications} />
        ) : (
          t(ETranslation.NO_JOB_APPLICATIONS)
        )}
      </Container>
    </>
  );
};

export default JobApplications;

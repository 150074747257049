import { OAuthProvider, getAuth, getRedirectResult } from "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import GuardedRoute from "./GuardedRoute";
import { ERoute } from "./classes/Routes";
import Layout from "./components/Layout/Layout";
import Modal from "./components/UI/Modal/Modal";
import Auth from "./containers/Auth/Auth";
import JobApplication from "./containers/JobApplication/JobApplication/JobApplication";
import JobApplications from "./containers/JobApplication/JobApplications";
import Post from "./containers/Post/Post/Post";
import Posts from "./containers/Post/Posts";
import User from "./containers/Users/User/User";
import Users from "./containers/Users/Users";
import ModalContext, { IModal, defaultModal } from "./context/ModalContext";
import firebase from "./firebase";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useAuthUser } from "./hooks/useAuthUser";
import { useAuthentication } from "./hooks/useAuthentication";
import i18n from "./i18n";
import * as actions from "./store/actions";
import Log from "./containers/Log/Log";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<IModal>(defaultModal);
  const [loading, setLoading] = useState(true);

  const authUser = useAuthUser();
  const { signOut } = useAuthentication();

  useEffect(() => {
    if (authUser && loading) {
      // When loading is true it's first time this is run, this should be done only once.
      setLoading(false);
      if (authUser?.preferredLanguage != null) {
        i18n.changeLanguage(authUser?.preferredLanguage);
      }
    }
  }, [dispatch, authUser, loading]);

  useEffect(() => {
    const auth = getAuth(firebase);
    getRedirectResult(auth)
      .then((result) => {
        console.log("getRedirectResult: " + result);
        if(!result) return;
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        // const credential = 
        OAuthProvider.credentialFromResult(result);
        // const accessToken = credential?.accessToken;

        // const idToken = credential?.idToken;
      })
      .catch((error) => {
        // Handle error.
        console.log(error);
      });

  /* */
    auth.onIdTokenChanged((fbUser) => {
      if (fbUser) {
        dispatch(actions.getCurrentUser());
      } else {
        setLoading(false);
      }
    });

    //auth.setPersistence(inMemoryPersistence);

    
  }, [dispatch]);

  const closeModal = useCallback(() => setModal(defaultModal), []);

  return (
    <Layout currentUser={authUser} onLogout={signOut}>
      <ModalContext.Provider
        value={{
          setModal,
          closeModal,
        }}
      >
        <Modal modal={modal} />
        {loading ? null : authUser ? (
          <Routes>
            <Route
              path={ERoute.POSTS}
              element={<GuardedRoute user={authUser} element={<Posts />} />}
            />
            <Route
              path={ERoute.POST}
              element={<GuardedRoute user={authUser} element={<Post />} />}
            />
            <Route
              path={ERoute.USERS}
              element={<GuardedRoute user={authUser} element={<Users />} />}
            />
            <Route
              path={ERoute.USER}
              element={<GuardedRoute user={authUser} element={<User />} />}
            />
            <Route
              path={ERoute.JOB_APPLICATIONS}
              element={<JobApplications />}
            />
            <Route path={ERoute.JOB_APPLICATION} element={<JobApplication />} />
            <Route path={ERoute.LOG} element={<Log />} />
            <Route
              path="/*"
              element={<Navigate replace to={authUser.redirect} />}
            />
            <Route
              path={ERoute.LOGIN}
              element={<Navigate replace to={authUser.redirect} />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route path={ERoute.LOGIN} element={<Auth />} />
            <Route path="/*" element={<Navigate replace to={ERoute.LOGIN} />} />
          </Routes>
        )}
      </ModalContext.Provider>
    </Layout>
  );
};

export default App;

import moment from "moment-timezone";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../classes/Routes";
import ModalContext, { EModalSize } from "../../../context/ModalContext";
import { fieldOfWorkOptions } from "../../../data/select-data";
import { useOpenHandler } from "../../../hooks/useOpenHandler";
import { IJobApplication } from "../../../interfaces/domain/IJobApplication";
import { EFieldOfWork } from "../../../interfaces/domain/IPost";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../../UI/Button/Button";
import Table from "../../UI/Table/Table";

interface IProps {
  jobApplications: IJobApplication[];
}

const JobApplicationList: React.FC<IProps> = ({ jobApplications }) => {
  const openHandler = useOpenHandler();
  const { t } = useTranslation();
  const { setModal } = useContext(ModalContext);

  const truncateNum = 100;
  const truncateString = (str: string,) => {
    return str.length > truncateNum ? { truncated: str.slice(0, truncateNum), isTruncated: true } : { truncated: str, isTruncated: false };
  };

  const getFieldOfWorkTranslation = useCallback(
    (field: EFieldOfWork) => {
      const translation = fieldOfWorkOptions.find(
        (option) => option.value === field
      )?.labelTranslation;
      return translation ? t(translation) : "";
    },
    [t]
  );

  const mapProfessions = useCallback(
    (professions: string[], otherProfession: string) => {
      const list =
        professions && professions?.length > 0
          ? professions
              .filter((profession) => profession)
              .map((profession) => profession.toLowerCase())
          : [];
      if (otherProfession) list.push(otherProfession);
      return list;
    },
    []
  );

  const openAllHandler = useCallback(() => {
    if (!jobApplications) return;
    for (let i in jobApplications) {
      const newWindow = window.open(
        `${Routes.JOB_APPLICATION(jobApplications[i].id)}`,
        "_blank"
      );
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == "undefined"
      ) {
        setModal({
          isOpen: true,
          title: t(ETranslation.POPUP_BLOCKED_ERROR_TITLE),
          size: EModalSize.SMALL,
          content: <p>{t(ETranslation.POPUP_BLOCKED_ERROR_TEXT)}</p>,
        });
        return;
      }
    }
  }, [jobApplications, setModal, t]);

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th>{t(ETranslation.COMMON_CREATED)}</th>
            <th>{t(ETranslation.NAME)}</th>
            <th>{t(ETranslation.EMAIL)}</th>
            <th>{t(ETranslation.LIST_PHONE_AND_ZIP)}</th>
            <th>{t(ETranslation.LIST_WORK_EXPERIENCE)}</th>
            <th>{t(ETranslation.LIST_PROFESSION)}</th>
            <th>{t(ETranslation.LIST_FIELD_OF_WORK)}</th>
            <th>{t(ETranslation.JOB_APPLICATION_TITLE_APPLY)}</th>
          </tr>
        </thead>
        <tbody>
          {jobApplications.map((jobApplication) => {
            const {
              id,
              firstName,
              lastName,
              email,
              mobile,
              postcode,
              workExperienceFields,
              professions,
              otherProfession,
              alsoApplyToFields,
              history,
              created,
            } = jobApplication;

            const joinedWorkExperienceFields = workExperienceFields?.map(getFieldOfWorkTranslation).join(", ") || "";
            const { truncated: truncatedWorkExperienceFields, isTruncated: isTruncatedWorkExperienceFields } = truncateString(joinedWorkExperienceFields);

            const joinedProfessions = mapProfessions(professions, otherProfession).join(", ");
            const { truncated: truncatedProfessions, isTruncated: isTruncatedProfessions } = truncateString(joinedProfessions);

            const joinedAlsoApplyToFields = alsoApplyToFields?.map(getFieldOfWorkTranslation).join(", ") || "";
            const { truncated: truncatedAlsoApplyToFields, isTruncated: isTruncatedAlsoApplyToFields } = truncateString(joinedAlsoApplyToFields);

            const joinedHistory = history?.map((h) => h.title).join(",\n") || "";
            const { truncated: truncatedHistory, isTruncated: isTruncatedHistory } = truncateString(joinedHistory);

            return (
              <tr
                key={id}
                onClick={(e) => openHandler(Routes.JOB_APPLICATION(id), e)}
              >
                {/* dd.mm. hh:MM   |  Sukunimi, etunimi | Email | puhelin | työalat | haettu paikka */}
                <td>{created && moment(created).format("DD.MM. HH:mm")}</td>
                <td>
                  {firstName}
                  <br />
                  {lastName}
                </td>
                <td>{email}</td>
                <td>
                  {mobile}
                  <br />
                  {postcode}
                </td>
                <td title={joinedWorkExperienceFields}>
                  {truncatedWorkExperienceFields}
                  {isTruncatedWorkExperienceFields && <span style={{ color: "blue", fontWeight: "bolder", fontSize: "larger" }}> ...</span>}
                </td>
                <td title={joinedProfessions}>
                  {truncatedProfessions}
                  {isTruncatedProfessions && <span style={{ color: "blue", fontWeight: "bolder", fontSize: "larger" }}> ...</span>}
                </td>
                <td title={joinedAlsoApplyToFields}>
                  {truncatedAlsoApplyToFields}
                  {isTruncatedAlsoApplyToFields && <span style={{ color: "blue", fontWeight: "bolder", fontSize: "larger" }}> ...</span>}
                </td>
                <td title={joinedHistory} style={{ whiteSpace: "pre-line" }}>
                  {truncatedHistory}
                  {isTruncatedHistory && <span style={{ color: "blue", fontWeight: "bolder", fontSize: "larger" }}> ...</span>}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {typeof window !== "undefined" && (
        <Button onClick={openAllHandler} style={{ marginTop: "1rem" }}>
          {t(ETranslation.COMMON_OPEN_ALL)}
        </Button>
      )}
    </>
  );
};

export default JobApplicationList;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { useCreateInput } from "../../../../hooks/useCreateInput";
import { IComment } from "../../../../interfaces/domain/IComment";
import { ETranslation } from "../../../../translations/translation-keys";
import Title from "../../../Title/Title";
import Button from "../../../UI/Button/Button";
import { EInputType, IInputField, TInputValue } from "../../../UI/Input/Input";
import {
  getInputData,
  updateInputHandler,
} from "../../../UI/Input/input-utils";
import InputGroup from "../../../UI/InputGroup/InputGroup";
import JobApplicationComment, {
  EJobApplicationComment,
} from "./JobApplicationComment/JobApplicationComment";
import { useAuthUser } from "../../../../hooks/useAuthUser";

interface IProps {
  comments: IComment[];
  onAdd: (value: TInputValue) => void;
  onDelete: (id: string) => void;
}

const JobApplicationComments: React.FC<IProps> = ({
  comments,
  onAdd,
  onDelete,
}) => {
  const { t } = useTranslation();
  const { commentsLoading } = useAppSelector((state) => state.jobApplication);
  const authUser = useAuthUser();

  const [inputs, setInputs] = useState<IInputField>({
    [EJobApplicationComment.message]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.ADD_COMMENT,
      placeholderTranslation: ETranslation.COMMENT,
      value: "",
    },
  });

  const saveHandler = () => {
    const data = getInputData<IComment>(inputs);
    onAdd(data.message);
    updateInputHandler(EJobApplicationComment.message, "", setInputs);
  };

  const createInput = useCreateInput(inputs, setInputs, {
    disabled: commentsLoading,
  });

  return (
    <div style={{ marginBottom: "1rem" }}>
      <Title translation={ETranslation.COMMENTS} />
      {comments?.map((comment, index) => (
        <JobApplicationComment
          key={index}
          comment={comment}
          onDelete={() => onDelete(comment.id)}
        />
      ))}
      {!authUser?.isViewer && <InputGroup style={{ marginTop: "1rem" }}>
        {createInput(EJobApplicationComment.message)}
        <Button onClick={saveHandler} loading={commentsLoading}>
          {t(ETranslation.COMMON_ADD)}
        </Button>
      </InputGroup>}
    </div>
  );
};

export default JobApplicationComments;

import { IAttachment } from "./IAttachment";
import { BaseDomain } from "./IBaseDomain";
import { IComment } from "./IComment";
import { EEmploymentType } from "./IUser";

export enum EFieldOfWork {
  assembly ="assembly",
	catering = "catering",
	construction = "construction",
	customerService = "customerService",
	excavation = "excavation",
	foodChainServices = "foodChainServices",
	foodWork = "foodWork",
	industrialWork = "industrialWork",
	installationWork = "installationWork",
	kitchenServices = "kitchenServices",
	laundryWork = "laundryWork",
	logistics = "logistics",
	maintenance = "maintenance",
	metalWork = "metalWork",
	officeWork = "officeWork",
	processIndustry = "processIndustry",
	serviceSector = "serviceSector",
	surfaceTreatment = "surfaceTreatment",
	textileIndustry = "textileIndustry",
	transportation = "transportation"
}

export interface IPost extends BaseDomain {
  comments: IComment[];
  profession: string;
  locationDetail: string;
  city: string;
  shortDescription: string;
  description: string;
  image: IAttachment;
  hardToFind: number;
  archived: boolean;
  attachments: IAttachment[];
  hidden: boolean;
  fieldsOfWork: EFieldOfWork[];
  employmentType: EEmploymentType;
  postalCode: string;
  datePosted: string;
  validThroughDate: string;
  validThroughTime: string;
  title: string;
  reference: string;
  shortId: string;
}

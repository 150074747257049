import React from "react";
import { useTranslation } from "react-i18next";
import { ILogItem } from '../../../interfaces/domain/ILogItem';
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";

interface IProps {
  logItems: ILogItem[];
}

const LogItemList: React.FC<IProps> = ({ logItems }) => {
  const { t } = useTranslation();
  return (
    <Table hover>
      <thead>
        <tr>
          <th>{t(ETranslation.DATE)}</th>
          <th>{t(ETranslation.MESSAGE)}</th>
          <th>{t(ETranslation.USER)}</th>
        </tr>
      </thead>
      <tbody>
        {logItems.map(item => {
          const { id, dateString, message, actionUserName } = item;
          return (
            <tr key={id}>
              <td>{dateString}</td>
              <td>{message}</td>
              <td>{actionUserName}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default LogItemList;

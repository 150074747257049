import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor } from "../Button/Button";
import InputGroup from "../InputGroup/InputGroup";

interface IProps {
  children: ReactNode;
  onAccept: () => void;
  onCancel: () => void;
}

const ConfirmModal: React.FC<IProps> = ({ children, onAccept, onCancel }) => {
  const { t } = useTranslation();
  return (
    <>
      {children}
      <InputGroup style={{ marginTop: "1rem" }}>
        <Button onClick={onAccept} style={{ marginRight: ".5rem" }}>
          {t(ETranslation.COMMON_YES)}
        </Button>
        <Button onClick={onCancel} color={EButtonColor.DANGER}>
          {t(ETranslation.COMMON_NO)}
        </Button>
      </InputGroup>
    </>
  );
};

export default ConfirmModal;

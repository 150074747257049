import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AuthUser } from "../../../classes/AuthUser";
import { ERoute } from "../../../classes/Routes";
import HeaderContext from "../../../context/HeaderContext";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Search from "../Search/Search";
import classes from "./Menu.module.scss";

export enum EMenuType {
  DESKTOP,
  MOBILE,
}

interface IProps {
  type: EMenuType;
  isOpen: boolean;
  toggleMenu: () => void;
  currentUser: AuthUser;
}

export interface IMenuItem {
  text: string;
  link?: string;
  to?: ERoute;
  subMenuItems?: IMenuItem[];
  action?: () => void;
  search?: boolean;
}

const Menu: React.FC<IProps> = ({ type, isOpen, toggleMenu, currentUser }) => {
  const location = useLocation();
  const { menuItems, dropdownMenuItems } = useContext(HeaderContext);

  const classNames = [classes.Menu];
  switch (type) {
    case EMenuType.DESKTOP:
      classNames.push(classes.DesktopMenu);
      break;
    case EMenuType.MOBILE:
      classNames.push(classes.MobileMenu);
      break;
    default:
      break;
  }

  if (isOpen) {
    classNames.push(classes.isOpen);
  }

  const menuItemClickHandler = (menuItem: IMenuItem): void => {
    if (menuItem.link) {
      window.location.href = menuItem.link;
    }
    if (menuItem.action) {
      menuItem.action();
    }
  };

  const isActiveChild = (menuItems?: IMenuItem[]): boolean => {
    if (!menuItems) return false;
    return menuItems.some(
      (menuItem) => menuItem.to && location.pathname.startsWith(menuItem.to)
    );
  };

  const renderMenuItem = (menuItem: IMenuItem, userDropdown?: boolean) => {
    if (type === EMenuType.MOBILE && menuItem.search) return null;
    const key = menuItem.text;
    let item: JSX.Element | null = null;
    if (menuItem.to) {
      if (!currentUser.routes.includes(menuItem.to)) return null;

      const navLinkClassNames = [classes.MenuItem];
      if (location.pathname.startsWith(menuItem.to)) {
        navLinkClassNames.push(classes.MenuItemActive);
      }

      item = (
        <NavLink
          to={menuItem.to}
          className={navLinkClassNames.join(" ")}
          onClick={menuItem.action}
        >
          {menuItem.text}
        </NavLink>
      );
    } else {
      // if (menuItem.search && !currentUser.allowSearch) return null;
      if (menuItem.subMenuItems) {
        const hasAtleastOneAccess = menuItem.subMenuItems.some(item => item.action || (item.to && currentUser.routes.includes(item.to)));
        if (!hasAtleastOneAccess) return null;
      }

      const classNames = [classes.MenuItem];
      const isActive = isActiveChild(menuItem.subMenuItems);
      if (isActive) {
        classNames.push(classes.MenuItemActive);
      }
      if (userDropdown) {
        classNames.push(classes.UserDropdown);
      }
      item = (
        <div
          className={classNames.join(" ")}
          onClick={() => menuItemClickHandler(menuItem)}
        >
          {menuItem.text}{" "}
          {menuItem.subMenuItems && (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={classes.MenuIcon}
            />
          )}
        </div>
      );
    }

    const containerClasses = [classes.MenuItemContainer];

    if (userDropdown) {
      containerClasses.push(classes.UserDropdownContainer);
    }
    if (menuItem.search) {
      containerClasses.push(classes.Search);
    }

    return (
      <div className={containerClasses.join(" ")} key={key}>
        {item}
        {menuItem.subMenuItems && (
          <div className={classes.Dropdown}>
            {menuItem.subMenuItems.map((menuItem) => renderMenuItem(menuItem))}
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Backdrop isOpen={isOpen} onClick={toggleMenu} />
      <div className={classNames.join(" ")}>
        <div className={classes.MobileHeader}>
          <span className={classes.Toggle} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
        {menuItems.map(menuItem => renderMenuItem(menuItem))}
        {type === EMenuType.DESKTOP && <Search />}
        {dropdownMenuItems.map(menuItem => renderMenuItem(menuItem, true))}
      </div>
    </React.Fragment>
  );
};

export default Menu;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { IWorkHistory } from "../../../interfaces/domain/IWorkHistory";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonColor, EButtonSize } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData, initForm } from "../../UI/Input/input-utils";
import InputGroup from "../../UI/InputGroup/InputGroup";
import HistoryContainer from "../HistoryContainer/HistoryContainer";
import { useAuthUser } from "../../../hooks/useAuthUser";

enum EWorkHistory {
  employer = "employer",
  jobTitle = "jobTitle",
  jobAssignments = "jobAssignments",
  startDate = "startDate",
  endDate = "endDate",
}

interface IProps {
  index: number;
  workHistory: IWorkHistory;
  setWorkHistories: React.Dispatch<React.SetStateAction<IWorkHistory[]>>;
  disabled?: boolean;
}

const initialInputs: IInputField = {
  [EWorkHistory.employer]: {
    type: EInputType.text,
    labelTranslation: ETranslation.EMPLOYER,
    placeholderTranslation: ETranslation.EMPLOYER,
    value: "",
  },
  [EWorkHistory.jobTitle]: {
    type: EInputType.text,
    labelTranslation: ETranslation.JOB_TITLE,
    placeholderTranslation: ETranslation.JOB_TITLE,
    value: "",
  },
  [EWorkHistory.jobAssignments]: {
    type: EInputType.textarea,
    labelTranslation: ETranslation.JOB_ASSIGNMENTS,
    placeholderTranslation: ETranslation.JOB_ASSIGNMENTS,
    value: "",
  },
  [EWorkHistory.startDate]: {
    type: EInputType.date,
    labelTranslation: ETranslation.WORK_START_DATE,
    placeholderTranslation: ETranslation.WORK_START_DATE,
    value: "",
  },
  [EWorkHistory.endDate]: {
    type: EInputType.date,
    labelTranslation: ETranslation.WORK_END_DATE,
    placeholderTranslation: ETranslation.WORK_END_DATE,
    value: "",
  },
};

const WorkHistory: React.FC<IProps> = ({
  index,
  workHistory,
  setWorkHistories,
  disabled,
}) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const openConfirmDeleteModal = useConfirmModal();
  const authUser = useAuthUser();

  const deleteHandler = async () => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.CONFIRM_DELETE_WORK_HISTORY)
    );
    if (success) {
      setWorkHistories((workHistories) => {
        if (workHistories.length === 1) return [];
        return workHistories.filter((_, i) => i !== index);
      });
    }
  };

  const updateHandler = (workHistory: IWorkHistory) => {
    setWorkHistories((workHistories) => {
      return workHistories.map((element, i) =>
        i === index ? { ...workHistory } : element
      );
    });
  };

  useEffect(() => {
    initForm(setInputs, workHistory);
  }, [workHistory]);

  const createInput = useCreateInput(inputs, setInputs, {
    onBlur: () => updateHandler(getInputData(inputs)),
    disabled,
  });

  return (
    <HistoryContainer>
      {createInput(EWorkHistory.employer)}
      {createInput(EWorkHistory.jobTitle)}
      {createInput(EWorkHistory.jobAssignments)}
      <InputGroup>
        {createInput(EWorkHistory.startDate)}
        {createInput(EWorkHistory.endDate)}
      </InputGroup>
      {!authUser?.isViewer && (
        <Button
          onClick={deleteHandler}
          color={EButtonColor.DANGER}
          size={EButtonSize.SMALL}
          disabled={disabled}
        >
          {t(ETranslation.COMMON_DELETE)}
        </Button>
      )}
    </HistoryContainer>
  );
};

export default WorkHistory;

import React from "react";
import { isValidEmail } from "../../../shared/isValidEmail";
import { ETranslation } from "../../../translations/translation-keys";
import {
  EInputType,
  IInputField,
  IInputFieldItem,
  IOption,
  IValidationResult,
  TInputValue,
} from "./Input";

export const isOptionArray = (value: TInputValue): value is IOption[] =>
  Array.isArray(value) &&
  value.length > 0 &&
  (value as IOption[]).every((item: any) => item.value !== undefined);
const isOption = (value: TInputValue): value is IOption =>
  (value as IOption).value !== undefined;
export const isStringArray = (value: TInputValue): value is string[] =>
  Array.isArray(value) &&
  value.length > 0 &&
  (value as string[]).every((item: any) => typeof item === "string");

export function getInputData<T>(inputs: IInputField): T {
  let data: any = {};
  for (let key in inputs) {
    const input = inputs[key];
    let value = input.value;

    if (value === undefined) {
    } else if (value === null) {
    } else if (isOptionArray(value)) {
      value = value.map((item) => item.value);
    } else if (isOption(value)) {
      value = value.value;
    }
    data[key] = value;
  }
  return data;
}

export const initForm = (
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  data: any,
  disableFields?: boolean
): void => {
  setInputs((inputs) => {
    let newInputs = {
      ...inputs,
    };
    // Set values from data and validate
    for (let key in inputs) {
      let value = data[key] || "";
      let input = inputs[key];
      if (typeof value !== "undefined") {
        // Kauhee ratkasu
        const inputType = input.type;
        switch (inputType) {
          case EInputType.select:
            if (typeof value === "object") {
              value = value.id;
            }
            break;
        }
      }
      newInputs[key] = {
        ...input,
        value,
      };
      updateInputValid(newInputs, key);
    }
    return newInputs;
  });
};


const updateInputValid = (
  newState: IInputField,
  inputName: string
): IInputField => {
  newState[inputName].validationResult = validateInput(
    newState,
    newState[inputName]
  );

  const validation = newState[inputName].validation;
  if (validation && validation.dependencies) {
    for (let dependencyName of validation.dependencies) {
      newState[dependencyName].validationResult = validateInput(
        newState,
        newState[dependencyName]
      );
    }
  }
  return newState;
};

export const updateInputHandler = (
  inputName: string,
  value: TInputValue,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>
) => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    newState[inputName].value = value;
    newState = updateInputValid(newState, inputName);
    return newState;
  });
};

export const disableInputs = (
  disabled: boolean,
  setInputs: React.Dispatch<React.SetStateAction<IInputField>>,
  exclude?: string[]
): void => {
  setInputs((prevState: IInputField) => {
    let newState = { ...prevState };
    for (let key in newState) {
      if (exclude && exclude.includes(key)) {
        continue;
      }
      newState[key].disabled = disabled;
    }
    return newState;
  });
};

const INVALID_DEFAULT: IValidationResult = {
  isValid: false,
  message: null,
};
const validateRequiredIf = (
  value: string,
  requiredIfValue?: string | string[]
) => {
  if (!requiredIfValue) return false;
  if (Array.isArray(requiredIfValue)) {
    return !requiredIfValue.includes(value);
  } else {
    return requiredIfValue !== value;
  }
};

const isValidValue = (
  item: IInputFieldItem,
  requiredIfValue?: string | string[],
  requiredCompareValue?: string
): IValidationResult => {
  const { type, validation = {} } = item;
  let value;
  switch (type) {
    case EInputType.text:
    case EInputType.number:
    case EInputType.date:
    case EInputType.tel:
    case EInputType.textarea:
    case EInputType.time:
    case EInputType.select:
    case EInputType.email:
    case EInputType.password:
    case EInputType.reactSelect:
      value = item.value as string;
      if (requiredCompareValue) {
        if (requiredCompareValue === value) return INVALID_DEFAULT;
      } else if (value === "") {
        return INVALID_DEFAULT;
      }
      // if (requireIfValue && requireIfValue !== value) return INVALID_DEFAULT;
      if (validateRequiredIf(value, requiredIfValue)) return INVALID_DEFAULT;
      if (type === EInputType.email && !isValidEmail(value as string))
        return {
          isValid: false,
          message: ETranslation.VALIDATION_EMAIL, // ADD Translation
        };
      if (validation.minLength && value.length < validation.minLength) {
        return {
          isValid: false,
          message:
            validation.minLengthMessage || ETranslation.VALIDATION_MIN_LENGTH,
          messageParams: {
            minLength: validation.minLength.toString(),
          },
        };
      }
      if (validation.maxLength && value.length > validation.maxLength) {
        return {
          isValid: false,
          message:
            validation.maxLengthMessage || ETranslation.VALIDATION_MAX_LENGTH,
          messageParams: {
            maxLength: validation.maxLength.toString(),
          },
        };
      }
      if (validation.minAmount && parseInt(value) < validation.minAmount) {
        return {
          isValid: false,
          message:
            validation.minAmountMessage || ETranslation.VALIDATION_MIN_AMOUNT,
          messageParams: {
            minAmount: validation.minAmount.toString(),
          },
        };
      }
      if (validation.maxAmount && parseInt(value) > validation.maxAmount) {
        return {
          isValid: false,
          message:
            validation.maxAmountMessage || ETranslation.VALIDATION_MAX_AMOUNT,
          messageParams: {
            maxAmount: validation.maxAmount.toString(),
          },
        };
      }
      break;
    case EInputType.checkbox:
      value = item.value as string[];
      if (value.length === 0) return INVALID_DEFAULT;
      break;
    case EInputType.datepicker:
      value = item.value as Date;
      if (!value) return INVALID_DEFAULT;
      break;
    default:
      return INVALID_DEFAULT;
  }
  return {
    isValid: true,
    message: null,
  };
};

export const validateInput = (
  inputs: IInputField,
  item: IInputFieldItem
): IValidationResult => {
  const { validation } = item;
  if (validation) {
    if (validation.required) {
      return isValidValue(item, undefined, validation.requiredCompareValue);
    } else if (validation.requiredIf) {
      if (
        isValidValue(inputs[validation.requiredIf], validation.requiredIfValue)
          .isValid
      ) {
        return isValidValue(item);
      }
    } else if (validation.requiredIfNot) {
      if (
        !isValidValue(
          inputs[validation.requiredIfNot],
          validation.requiredIfValue
        ).isValid
      ) {
        return isValidValue(item);
      }
    }
  }
  return {
    isValid: true,
    message: null,
  };
};

export const validateInputs = (inputs: IInputField) => {
  const keys = Object.keys(inputs);
  for (let key of keys) {
    const item = inputs[key];
    const validationResult = validateInput(inputs, item);
    if (!validationResult.isValid) {
      return false;
    }
  }
  return true;
};

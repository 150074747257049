import { IJobApplicationSearch } from "../components/JobApplications/JobApplicationSearch/JobApplicationSearch";

enum ELocalStorageKey {
  jobApplicationSearch = "jobApplicationSearch",
}

class LocalStorage {
  private static canAccess() {
    return typeof window !== "undefined";
  }

  private static parseJobApplicationJSON = (): IJobApplicationSearch[] => {
    const json = localStorage.getItem(ELocalStorageKey.jobApplicationSearch);
    return json ? JSON.parse(json) : [];
  };

  static setJobApplicationSearch = (search: IJobApplicationSearch) => {
    if (this.canAccess()) {
      /*
      if (
        !search ||
        (!search.city &&
          !search.fieldsOfWork &&
          !search.historyId &&
          !search.query &&
          !search.archived)
      ) {
        return;
      }*/

      const latest = this.getLatestJobApplicationSearch();
      if (
        latest &&
        search.city === latest.city &&
        search.fieldsOfWork === latest.fieldsOfWork &&
        search.historyId === latest.historyId &&
        search.query === latest.query &&
        search.archived === latest.archived
      )
        return;

      let json = this.parseJobApplicationJSON();
      if (json.length > 9) {
        json = json.slice(1);
      }
      json.push(search);

      localStorage.setItem(
        ELocalStorageKey.jobApplicationSearch,
        JSON.stringify(json)
      );
    }
  };

  static getJobApplicationSearch = () => {
    if (this.canAccess()) {
      return this.parseJobApplicationJSON();
    }
  };

  static getLatestJobApplicationSearch = ():
    | IJobApplicationSearch
    | undefined => {
    if (this.canAccess()) {
      const search = this.parseJobApplicationJSON();
      return search.length > 0 ? search[search.length - 1] : undefined;
    }
  };
}

export default LocalStorage;

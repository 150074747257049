import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../classes/Routes";
import { useOpenHandler } from "../../../hooks/useOpenHandler";
import { IPost } from "../../../interfaces/domain/IPost";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";

interface IProps {
  posts: IPost[];
  filter: string;
}

const PostList: React.FC<IProps> = ({ posts, filter }) => {
  const openHandler = useOpenHandler();
  const { t } = useTranslation();
  const [filteredPosts, setFilteredPosts] = useState<IPost[]>(posts);

  useEffect(() => {
    if (!filter) {
      setFilteredPosts([...posts]);
      return;
    }
    const lowerCaseFilter = filter?.trim().toLowerCase();
    setFilteredPosts(
      [...posts].filter((p) =>
        p.city?.trim().toLowerCase().includes(lowerCaseFilter) ||
        p.reference?.trim().toLowerCase().includes(lowerCaseFilter) ||
        p.profession?.trim().toLowerCase().includes(lowerCaseFilter) ||
        p.locationDetail?.trim().toLowerCase().includes(lowerCaseFilter) ||
        p.shortDescription?.trim().toLowerCase().includes(lowerCaseFilter)
      )
    );
  }, [posts, filter]);

  return filteredPosts.length > 0 ? (
    <Table hover>
      <thead>
        <tr>
          <th>{t(ETranslation.COMMON_CREATED)}</th>
          <th>{t(ETranslation.PROFESSION)}</th>
          <th>{t(ETranslation.LOCATION)}</th>
          <th>{t(ETranslation.SHORT_DESCRIPTION)}</th>
          <th>{t(ETranslation.DATE_POSTED)}</th>
          <th>{t(ETranslation.VALID_THROUGH_DATE)}</th>
          <th>{t(ETranslation.HIDDEN)}</th>
          <th>{t(ETranslation.HARD_TO_FIND)}</th>
        </tr>
      </thead>
      <tbody>
        {filteredPosts.map((post) => {
          const {
            id,
            created,
            profession,
            city,
            locationDetail,
            shortDescription,
            datePosted,
            validThroughDate,
            hidden,
            reference,
            hardToFind,
          } = post;
          const location = [];
          if (city) location.push(city);
          if (locationDetail) location.push(locationDetail);
          return (
            <tr key={id} onClick={(e) => openHandler(Routes.POST(id), e)}>
              <td>{created && moment(created).format("DD.MM.yyyy")}</td>
              <td><b>{profession}</b><p style={{"fontSize": "10px"}}>{reference}</p></td>
              <td>{location.join(" ")}</td>
              <td>{shortDescription}</td>
              <td>{datePosted && moment(datePosted).format("DD.MM.yyyy")}</td>
              <td>
                {validThroughDate &&
                  moment(validThroughDate).format("DD.MM.yyyy")}
              </td>
              <td>{hidden && <FontAwesomeIcon icon={faTimes} />}</td>
              <td>{hardToFind}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  ) : (
    <p>{t(ETranslation.NO_RESULTS)}</p>
  );
};

export default PostList;

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Routes } from "../../../classes/Routes";
import { levelOptions } from '../../../data/select-data';
import { useOpenHandler } from '../../../hooks/useOpenHandler';
import { EUserLevel, IUser } from "../../../interfaces/domain/IUser";
import { ETranslation } from "../../../translations/translation-keys";
import Table from "../../UI/Table/Table";

interface IProps {
  users: IUser[];
}

const UserList: React.FC<IProps> = ({ users }) => {
  const openHandler = useOpenHandler();
  const { t } = useTranslation();

  const getLevelTranslation = (level: EUserLevel) => {
    const translation = levelOptions.find(option => option.value === level)?.labelTranslation;
    return translation && t(translation);
  }

  return (
    <Table hover>
      <thead>
        <tr>
          <th>{t(ETranslation.NAME)}</th>
          <th>{t(ETranslation.EMAIL)}</th>
          <th>{t(ETranslation.MOBILE)}</th>
          <th>{t(ETranslation.LEVEL)}</th>
          <th>{t(ETranslation.ALLOW_LOGIN)}</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => {
          const { id, name, email, mobile, allowLogin, level } = user;
          return (
            <tr key={id} onClick={(e) => openHandler(Routes.USER(id), e)}>
              <td>{name}</td>
              <td>{email}</td>
              <td>{mobile}</td>
              <td>{getLevelTranslation(level)}</td>
              <td>{allowLogin ? <FontAwesomeIcon icon={faCheck}/> :""}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default UserList;

import { getAuth, OAuthProvider, signInWithPopup } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from "../../assets/alrlogo.png";
import PasswordResetDialog from '../../components/Auth/PasswordResetDialog/PasswordResetDialog';
import Alert from '../../components/UI/Alert/Alert';
import Button, { EButtonColor } from '../../components/UI/Button/Button';
import Input, { EInputType, IInputField, IInputOptions } from '../../components/UI/Input/Input';
import { updateInputHandler } from '../../components/UI/Input/input-utils';
import Spinner from '../../components/UI/Spinner/Spinner';
import ModalContext, { EModalSize } from '../../context/ModalContext';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import i18n from '../../i18n';
import * as actions from '../../store/actions';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Auth.module.scss';

export enum EAuth {
  username = "username",
  password = "password",
};

const Auth: React.FC = () => {
  const { setModal, closeModal } = useContext(ModalContext);
  const dispatch = useAppDispatch();

  const { loading, error } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    [EAuth.username]: {
      type: EInputType.email,
      labelTranslation: ETranslation.USERNAME,
      placeholderTranslation: ETranslation.USERNAME,
      value: "",
    },
    [EAuth.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.PASSWORD,
      placeholderTranslation: ETranslation.PASSWORD,
      value: "",
    },
  });

  const createAuthInput = (inputName: EAuth, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      {...item}
      {...options}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  const submitHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const username = inputs[EAuth.username].value as string;
    const password = inputs[EAuth.password].value as string;
    const preferredLanguage = i18n.language;
    dispatch(actions.auth(username, password, preferredLanguage));
  };

  const setPasswordResetDialog = () => setModal({
    isOpen: true,
    title: t(ETranslation.RESET_PASSWORD),
    size: EModalSize.SMALL,
    content: <PasswordResetDialog onCancel={closeModal} />,
  });

  const startOIDCHandler = () => {
    
    const provider = new OAuthProvider('oidc.tunnistus.alrekry.fi');

    const auth = getAuth();
    signInWithPopup(auth, provider);

  }

  return (
    <div className={classes.Container}>
      <div className={classes.LoginBox}>
        <div className={classes.Title}><img src={logo} alt="Alrekry" /></div>
        {(loading && <Spinner center />) || (
          <form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {createAuthInput(EAuth.username)}
              {createAuthInput(EAuth.password)}
            </div>
            <div className={classes.ButtonContainer}>
              <Button onClick={submitHandler} type="submit">
                {t(ETranslation.LOGIN)}
              </Button>
            </div>

            <div className={classes.ButtonContainer}>
              {/* {i18n.language === "fi" ? (
                <Button onClick={() => i18n.changeLanguage('en')} color={EButtonColor.NONE}>{t(ETranslation.LANGUAGE_IN_ENGLISH)}</Button>
              ) : (
                <Button onClick={() => i18n.changeLanguage('fi')} color={EButtonColor.NONE}>{t(ETranslation.LANGUAGE_IN_FINNISH)}</Button>
              )} */}
              <Button className='passwordResetButton' onClick={setPasswordResetDialog} color={EButtonColor.NONE}>
                {t(ETranslation.FORGOT_PASSWORD)}
              </Button>
              <Button onClick={startOIDCHandler}>
                Kirjaudu tunnistus.alrekry.fi
              </Button>
            </div>
            {error && (
              <Alert className={classes.ErrorMessage}>{error}</Alert>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;

export enum EEducationLevel {
  vocationalSchool = "vocationalSchool",
	universityOfAppliedSciences = "universityOfAppliedSciences",
	university = "university",
	adultEducationCourse = "adultEducationCourse",
	theInstituteofAdultEducation = "theInstituteofAdultEducation",
	upperUniversityOfAppliedSciences = "upperUniversityOfAppliedSciences",
	licentiate = "licentiate",
	other = "other",
	inProgress = "inProgress",
	none = "none"
}

export interface IEducationHistory {
  educationLevel: EEducationLevel;
  degree: string;
  major: string;
  field_of_education: string;
  school: string;
  startDate: string;
  endDate: string;
  ongoing: boolean;
}

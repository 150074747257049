import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fieldOfWorkOptions } from "../../../data/select-data";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { ETranslation } from "../../../translations/translation-keys";
import Button, { EButtonSize } from "../../UI/Button/Button";
import { EInputType, IInputField } from "../../UI/Input/Input";
import { getInputData } from "../../UI/Input/input-utils";
import InputGroup from "../../UI/InputGroup/InputGroup";
import { useAuthUser } from "../../../hooks/useAuthUser";

export interface IPostSearch {
  fieldsOfWork: string;
  created?: string | null;
  archived?: boolean;
}

interface IProps {
  onSearch: (search: IPostSearch) => void;
}

enum EPostSearch {
  fieldsOfWork = "fieldsOfWork",
  createdStart = "createdStart",
  createdEnd = "createdEnd",
  archived = "archived",
}

const initialInputs: IInputField = {
  [EPostSearch.fieldsOfWork]: {
    type: EInputType.reactSelect,
    labelTranslation: ETranslation.FIELDS_OF_WORK,
    placeholderTranslation: ETranslation.SELECT_FIELD_OF_WORK_PLACEHOLDER,
    value: "",
    options: fieldOfWorkOptions,
  },
  [EPostSearch.createdStart]: {
    type: EInputType.date,
    labelTranslation: ETranslation.COMMON_CREATED,
    placeholderTranslation: ETranslation.COMMON_CREATED,
    value: "",
  },
  [EPostSearch.createdEnd]: {
    type: EInputType.date,
    labelTranslation: ETranslation.COMMON_CREATED,
    placeholderTranslation: ETranslation.COMMON_CREATED,
    value: "",
  },
  [EPostSearch.archived]: {
    type: EInputType.checkbox,
    labelTranslation: ETranslation.ARCHIVED,
    placeholderTranslation: ETranslation.ARCHIVED,
    value: "",
  },
};

const PostSearch: React.FC<IProps> = ({ onSearch }) => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState<IInputField>(initialInputs);
  const createInput = useCreateInput(inputs, setInputs);
  const authUser = useAuthUser();

  useEffect(() => {
    onSearch(getInputData(initialInputs));
  }, [onSearch]);

  return (
    <>
      {createInput(EPostSearch.fieldsOfWork)}
      <InputGroup>
        {createInput(EPostSearch.createdStart)}
        {createInput(EPostSearch.createdEnd)}
      </InputGroup>
      {(authUser?.isAdmin || authUser?.isOperative) && createInput(EPostSearch.archived)}
      <Button
        onClick={() => onSearch(getInputData(inputs))}
        size={EButtonSize.SMALL}
      >
        {t(ETranslation.COMMON_SEARCH)}
      </Button>
    </>
  );
};

export default PostSearch;

import { getAuth } from "firebase/auth";
import firebase from "../firebase";
import * as actions from "../store/actions";
import { useAppDispatch } from "./useAppDispatch";

const auth = getAuth(firebase);

export const useAuthentication = () => {
  const dispatch = useAppDispatch();

  const signOut = () => {
    auth.signOut();
    window.open('https://tunnistus.alrekry.fi/realms/master/protocol/openid-connect/logout');
    console.log("called logout");

    dispatch(actions.getCurrentUserClear());
  };

  return { signOut };
};

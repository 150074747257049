import React, { useEffect, useState } from 'react';

export interface IUserSearch {
  role?: string;
}

interface IProps {
  onSearch: (search: IUserSearch) => void;
}

const UserSearch: React.FC<IProps> = ({ onSearch }) => {
  const [role] = useState<string>("");

  useEffect(() => {
    onSearch({
      role
    });
  }, [onSearch, role]);

  return (
    <></>
  );
}

export default UserSearch;

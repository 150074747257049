import { configureStore } from "@reduxjs/toolkit";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { combineReducers, Reducer } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import "./i18n";
import "./index.scss";
import IAppState from "./interfaces/state/IAppState";
import { EActionTypes } from "./store/EActionTypes";
import {
  attachmentReducer,
  authReducer,
  jobApplicationReducer,
  logItemReducer,
  postReducer,
  userReducer,
} from "./store/reducers";

const appReducer: Reducer<IAppState> = combineReducers({
  auth: authReducer,
  post: postReducer,
  jobApplication: jobApplicationReducer,
  user: userReducer,
  attachment: attachmentReducer,
  logItem: logItemReducer
});

const rootReducer = (
  state: IAppState | undefined,
  action: { type: EActionTypes }
) => {
  if (action.type === EActionTypes.AUTH_GET_CLEAR) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  devTools: process.env.NODE_ENV === "development" ? true : false,
});

export type TAppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

import React, { ReactNode } from "react";
import classes from "./HistoryContainer.module.scss";

interface IProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

const HistoryContainer: React.FC<IProps> = ({ children, style }) => {
  return (
    <div className={classes.Container} style={style}>
      {children}
    </div>
  );
}

export default HistoryContainer;

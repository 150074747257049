import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { } from 'react-router-dom';
import { AuthUser } from '../../classes/AuthUser';
import { Routes } from '../../classes/Routes';
import HeaderContext from '../../context/HeaderContext';
import { ETranslation } from '../../translations/translation-keys';
import classes from './Header.module.scss';
import Menu, { EMenuType, IMenuItem } from './Menu/Menu';
import logo from "../../assets/alrlogo.png";
import Search from './Search/Search';

interface IProps {
  currentUser: AuthUser;
  logout: () => void;
}

const Header: React.FC<IProps> = ({ currentUser, logout }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen((prevState) => !prevState);
  const { t } = useTranslation();

  const clickHandler = (link: string) => navigate(link);

  const menuItems: IMenuItem[] = [
    { text: t(ETranslation.POSTS), to: Routes.POSTS },
    { text: t(ETranslation.JOB_APPLICATIONS), to: Routes.JOB_APPLICATIONS },
    { text: t(ETranslation.USERS), to: Routes.USERS },
    { text: t(ETranslation.LOG), to: Routes.LOG }
  ];

  const dropdownMenuItems: IMenuItem[] = [
    {
      text: currentUser?.name || "",
      subMenuItems: [
        { text: t(ETranslation.LOGOUT), action: logout },
      ]
    }
  ];

  return (
    <HeaderContext.Provider value={{ menuItems, dropdownMenuItems }}>
      <Menu type={EMenuType.MOBILE} isOpen={isOpen} toggleMenu={toggleMenu} currentUser={currentUser} />
      <header className={classes.Container}>
        <div className={classes.Wrapper}>
          <div className={classes.ToggleMenu} onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} className={classes.Icon} />
          </div>
          <div className={classes.Logo} onClick={() => clickHandler(currentUser.redirect)}>
            <img src={logo} alt="Alrekry" />
          </div>
          <div className={classes.MobileSearch}>
            <Search />
          </div>
          <Menu type={EMenuType.DESKTOP} isOpen={false} toggleMenu={toggleMenu} currentUser={currentUser} />
        </div>
      </header>
    </HeaderContext.Provider>
  );
};

export default React.memo(Header);

import { ETranslation } from "./translation-keys";

const commonTranslations = {
  [ETranslation.COMMON_EMAIL]: "Sähköposti",
  [ETranslation.COMMON_SEND]: "Lähetä",
  [ETranslation.COMMON_CANCEL]: "Peruuta",
  [ETranslation.COMMON_CLOSE]: "Sulje",
  [ETranslation.COMMON_RETURN]: "Palaa",
  [ETranslation.COMMON_DELETE]: "Poista",
  [ETranslation.COMMON_YES]: "Kyllä",
  [ETranslation.COMMON_NO]: "Ei",
  [ETranslation.COMMON_SAVE]: "Tallenna",
  [ETranslation.COMMON_ADD]: "Lisää",
  [ETranslation.COMMON_CREATED]: "Luotu",
  [ETranslation.COMMON_SEARCH]: "Haku",
  [ETranslation.COMMON_DOWNLOAD]: "Lataa",
  [ETranslation.COMMON_OPEN_ALL]: "Avaa kaikki",
};

const authTranslations = {
  [ETranslation.TITLE]: "ALREKRY",
  [ETranslation.LOGIN]: "Kirjaudu",
  [ETranslation.FORGOT_PASSWORD]: "Unohtuiko salasana?",
  [ETranslation.RESET_PASSWORD]: "Palauta salasana",
  [ETranslation.USERNAME]: "Tunnus",
  [ETranslation.PASSWORD]: "Salasana",
  [ETranslation.PASSWORD_MIN_LENGTH_MESSAGE]:
    "Salasanassa on oltava vähintää 6 merkkiä",
  [ETranslation.PASSWORD_RESET_TEXT]:
    "Oletko varma että haluat lähettää salasananvaihto linkin sähköpostiisi?",
};

const headerTranslations = {
  [ETranslation.MY_PAGE]: "Etusivu",
  [ETranslation.USERS]: "Käyttäjät",
  [ETranslation.POSTS]: "Työpaikat",
  [ETranslation.JOB_APPLICATIONS]: "Hakemukset",
  [ETranslation.LOGOUT]: "Kirjaudu ulos",
  [ETranslation.PROFILE]: "Profiili",
};

const PostsTranslations = {
  [ETranslation.START_DATE]: "alkupvm",
  [ETranslation.END_DATE]: "loppupvm",
  [ETranslation.NO_POSTS]: "Ei työpaikkoja.",
  [ETranslation.DATE]: "Pvm",
};

const PostTranslations = {
  [ETranslation.COMMENTS]: "Kommentit",
  [ETranslation.PROFESSION]: "Tehtävä",
  [ETranslation.CITY]: "Kunta/kaupunki",
  [ETranslation.LOCATION]: "Sijainti",
  [ETranslation.LOCATION_DETAIL]: "Sijainti",
  [ETranslation.SHORT_DESCRIPTION]: "Lyhyt kuvaus",
  [ETranslation.FULL_DESCRIPTION]: "Kuvaus",
  [ETranslation.DATE_POSTED]: "Julkaisu pvm",
  [ETranslation.VALID_THROUGH_DATE]: "Haku päättyy pvm",
  [ETranslation.VALID_THROUGH_TIME]: "Haku päättyy aika",
  [ETranslation.EMPLOYMENT_TYPE]: "Työsuhteen tyyppi",
  [ETranslation.IMAGE_URL]: "Kuva",
  [ETranslation.HARD_TO_FIND]: "Haastava täyttää",
  [ETranslation.ARCHIVED]: "Arkistoitu",
  [ETranslation.ATTACHMENTS]: "Liitteet",
  [ETranslation.HIDDEN]: "Piilotettu julkisista listauksista",
  [ETranslation.STATUS]: "Tila",
  [ETranslation.FIELDS_OF_WORK]: "Työalat",
  [ETranslation.POST_JOB_INFO]: "Työpaikan tiedot",
  [ETranslation.POST_PUBLISH_INFO]: "Julkaisun asetukset",
  [ETranslation.REFERENCE]: "Asiakas/viite",
  [ETranslation.NO_RESULTS]: "Ei tuloksia.",
  [ETranslation.COPY]: "Käytä ilmoitusta mallina",
};

const PostCommentsTranslations = {
  [ETranslation.MESSAGE]: "Viesti",
  [ETranslation.COMMENT]: "Kommentti",
  [ETranslation.ADD_COMMENT]: "Lisää kommentti",
};

const PostListTranslations = {
  [ETranslation.POST_LIST_FILTER]: "Suodata",
};

const PostSearchTranslations = {
  [ETranslation.SEARCH_ORDER_BY]: "Järjestä",
  [ETranslation.SEARCH_START_DATE]: "Luotu",
  [ETranslation.SEARCH_STATUS]: "Tila",
  [ETranslation.SEARCH_TITLE]: "Tekstihaku",
  [ETranslation.SEARCH_CLEAR_AND_SEARCH]: "Tyhjennä ja hae kaikki",
  [ETranslation.SEARCH_LATEST]: "Viimeisimmät haut",
  [ETranslation.SEARCH_FIND_LATEST]: "Hae uudet",
  [ETranslation.SEARCH_EXAMPLE_TEXT]: "Tekstihaku esim."
};

const PostStatusTranslations = {
  [ETranslation.STATUS_ISSUED]: "Avattu",
  [ETranslation.STATUS_COMPLETE]: "Valmis",
  [ETranslation.STATUS_CANCELLED]: "Peruttu",
  [ETranslation.STATUS_PLANNING]: "Valmistelu",
};

const UsersTranslations = {
  [ETranslation.NAME]: "Nimi",
  [ETranslation.EMAIL]: "Sähköposti",
  [ETranslation.MOBILE]: "Puhelin",
  [ETranslation.ALLOW_LOGIN]: "Salli kirjautuminen",
  [ETranslation.NO_USERS]: "Ei käyttäjiä.",
};

const UserTranslations = {
  [ETranslation.FIRST_NAME]: "Etunimi",
  [ETranslation.LAST_NAME]: "Sukunimi",
  [ETranslation.ADDRESS]: "Lähiosoite",
  [ETranslation.POSTCODE]: "Postinumero",
  [ETranslation.PLACE]: "Postitoimipaikka",
  [ETranslation.LEVEL]: "Käyttäjätaso",
  [ETranslation.GENDER]: "Sukupuoli",
  [ETranslation.FIREBASE_USER_ID]: "Firebase ID",
};

/*
const UserSearchTranslations = {
   [ETranslation.SEARCH_ROLE]: "Role",
};
*/

const JobApplicationTranslations = {
  [ETranslation.JOB_APPLICATION_TITLE]: "Hakemuksen tiedot",
  [ETranslation.NO_JOB_APPLICATIONS]: "Ei työhakemuksia",
	[ETranslation.GDPR_CONCENT]: "Käyttöehdot ja GDPR-käsittelylupa hyväksytty",
	[ETranslation.OTHER_INFO]: "Muuta huomioitavaa",
	[ETranslation.POST_ID]: "Työhakemuksen id",
  [ETranslation.POST_SHORT_ID]: "Ilmoituksen ID",
	[ETranslation.POST_TITLE]: "Työhakemuksen otsikko",
	[ETranslation.APPLY_OPTIONS]: "Haettava työ",
	[ETranslation.DRIVERS_LICENSE]: "Ajokortti",
	[ETranslation.DRIVERS_LICENSE_DETAILS]: "Ajokortin tyyppi",
	[ETranslation.OWN_CAR]: "Omistaa auton",
	[ETranslation.EDUCATION]: "Pohjakoulutus",
  [ETranslation.LANGUAGE_SKILL_FI]: "Suomi",
	[ETranslation.LANGUAGE_SKILL_EN]: "Englanti",
  [ETranslation.LANGUAGE_SKILL_SE]: "Ruotsi",
  [ETranslation.BIRTH_YEAR]: "Syntymävuosi",
  [ETranslation.WORK_EXPERIENCE_FIELDS]: "Minulla on kokemusta aloilta",
  [ETranslation.SPECIAL_SKILLS]: "Erityistaitoni",
  [ETranslation.IT_SKILLS]: "Tietotekniset taidot",
  [ETranslation.OTHER_LANGUAGES]: "Muu kielitaito ja arvio osaamisesta",
  [ETranslation.JOB_APPLICATION_TITLE_BASIC]: "Perustiedot",
  [ETranslation.JOB_APPLICATION_TITLE_EXPERIENCE]: "Osaaminen",
  [ETranslation.JOB_APPLICATION_TITLE_CARDS]: "Todistukset ja kortit",
  [ETranslation.JOB_APPLICATION_TITLE_WISHES]: "Toiveet",
  [ETranslation.JOB_APPLICATION_TITLE_OTHER_INFO]: "Lisätietoa",
  [ETranslation.JOB_APPLICATION_TITLE_APPLY]: "Haetut työpaikat",
  [ETranslation.COMMON_CARDS]: "Kortit",
  [ETranslation.OTHER_CARDS]: "Muu kortti",
  [ETranslation.PREFERRED_WORK_TYPES]: "Etsin seuraavanlaista työtä",
  [ETranslation.PREFERRED_STARTING_DATE]: "Etsin työtä alkaen",
  [ETranslation.STARTING_DATE_MORE_INFO]: "Lisätietoa",
  [ETranslation.SALARY_PREFERENCE]: "Palkkatoive €/h",
  [ETranslation.UNWANTED_JOBS]: "Mitä töitä en mielelläni ota vastaan",
  [ETranslation.ALSO_APPLY_TO_FIELDS]: "Haluan hakea kaikkia seuraavien alojen paikkoja",
  [ETranslation.WORK_TIME]: "Toivomukseni työajasta",
  [ETranslation.JOB_APPLICATION_EMAIL]: "Sähköposti",
  [ETranslation.JOB_APPLICATION_MOBILE]: "Puhelinnumero",
  [ETranslation.LANGUAGE_SKILL]: "Kielitaito",
  [ETranslation.ADD_WORK_HISTORY]: "Lisää työkokemus",
  [ETranslation.ADD_EDUCATION_HISTORY]: "Lisää jatkokoulutus",
  [ETranslation.ADD_HISTORY]: "Lisää työpaikkoja historiaan",
  [ETranslation.PROFESSIONS]: "Koen edustavani tiettyä/tiettyjä ammatteja",
  [ETranslation.HOBBIES]: "Harrastukseni",
  [ETranslation.OTHER_PROFESSION]: "Muu ammatti",
}

const jobApplicationListTranslations = {
  [ETranslation.LIST_PHONE_AND_ZIP]: "Puhelin- & postinumero",
  [ETranslation.LIST_WORK_EXPERIENCE]: "Kokemusta aloilta",
  [ETranslation.LIST_PROFESSION]: "Ammatti",
  [ETranslation.LIST_FIELD_OF_WORK]: "Toiveeni alasta",
};

const reactSelectTranslations = {
  [ETranslation.REACT_SELECT_PLACEHOLDER]: "Valitse",
  [ETranslation.REACT_SELECT_ADD_NEW]: "Lisää",
};

const reactDropzoneTranslations = {
  [ETranslation.REACT_DROPZONE_DRAG_TEXT]: "Paina valitaksesi tai raahaa tiedosto",
  [ETranslation.REACT_DROPZONE_DRAG_ACTIVE_TEXT]: "Raahaa tiedosto.",
};

const errorTranslations = {
  [ETranslation.NO_ACCESS_ERROR]: "Ei käyttöoikeutta.",
  [ETranslation.NOT_FOUND_ERROR]: "Ei tuloksia.",
  [ETranslation.POPUP_BLOCKED_ERROR_TITLE]: "Ponnahdusikkunat on estetty",
  [ETranslation.POPUP_BLOCKED_ERROR_TEXT]: "Ponnahdusikkunat on estetty tällä sivulla, ota ne käyttöön avataksesi kaikki työhakemukset uusiin välilehtiin.",
  [ETranslation.DATABASE_ERROR]: "Tietokanta virhe.",
};

const validationTranslations = {
  [ETranslation.VALIDATION_EMAIL]: "Tarkista sähköposti",
  [ETranslation.VALIDATION_MIN_LENGTH]:
    "Syötteen pituus oltava vähintään {{minLength}} merkkiä.",
  [ETranslation.VALIDATION_MAX_LENGTH]:
    "Syötteen pituus oltava enintään {{maxLength}} merkkiä.",
  [ETranslation.VALIDATION_MIN_AMOUNT]:
    "Syötteen oltava vähintään {{minAmount}}.",
  [ETranslation.VALIDATION_MAX_AMOUNT]:
    "Syötteen oltava enintään {{maxAmount}}.",
};

const levelTranslations = {
  [ETranslation.LEVEL_ADMIN]: "Admin",
  [ETranslation.LEVEL_OPERATIVE]: "Operatiivinen",
  [ETranslation.LEVEL_VIEWER]: "Katselija",
};

const employmentTypeTranslations = {
  [ETranslation.EMPLOYMENT_TYPE_FULL_TIME]: "Kokoaikainen",
  [ETranslation.EMPLOYMENT_TYPE_PART_TIME]: "Osa-aikainen",
};

const genderTranslations = {
  [ETranslation.GENDER_FEMALE]: "Nainen",
  [ETranslation.GENDER_MALE]: "Mies",
  [ETranslation.GENDER_OTHER]: "Muu sukupuoli",
  [ETranslation.GENDER_NOT_WILLING_TO_ANSWER]: "En halua vastata",
}

const fieldOfWorkTranslations = {
  [ETranslation.FIELD_OF_WORK_ASSEMBLY]: "Kokoonpano",
	[ETranslation.FIELD_OF_WORK_CATERING]: "Ravintola-ala",
	[ETranslation.FIELD_OF_WORK_CONSTRUCTION]: "Rakennusala",
	[ETranslation.FIELD_OF_WORK_CUSTOMER_SERVICE]: "Asiakaspalvelu",
	[ETranslation.FIELD_OF_WORK_EXCAVATION]: "Maanrakennus",
	[ETranslation.FIELD_OF_WORK_FOOD_CHAIN_SERVICES]: "Suurkeittiö",
	[ETranslation.FIELD_OF_WORK_FOOD_WORK]: "Elintarvike",
	[ETranslation.FIELD_OF_WORK_INDUSTRIAL_WORK]: "Teollisuus",
	[ETranslation.FIELD_OF_WORK_INSTALLATION_WORK]: "Asennus",
	[ETranslation.FIELD_OF_WORK_KITCHEN_SERVICES]: "Keittiö",
	[ETranslation.FIELD_OF_WORK_LAUNDRY_WORK]: "Pesula",
	[ETranslation.FIELD_OF_WORK_LOGISTICS]: "Logistiikka",
	[ETranslation.FIELD_OF_WORK_MAINTENANCE]: "Toimitilahuolto",
	[ETranslation.FIELD_OF_WORK_METAL_WORK]: "Metalli",
	[ETranslation.FIELD_OF_WORK_OFFICE_WORK]: "Toimistotyö",
	[ETranslation.FIELD_OF_WORK_PROCESS_INDUSTRY]: "Prosessiteollisuus",
	[ETranslation.FIELD_OF_WORK_SERVICE_SECTOR]: "Palvelutyö",
	[ETranslation.FIELD_OF_WORK_SURFACE_TREATMENT]: "Pintakäsittely",
	[ETranslation.FIELD_OF_WORK_TEXTILE_INDUSTRY]: "Tekstiiliteollisuus",
	[ETranslation.FIELD_OF_WORK_TRANSPORTATION]: "Kuljetus",
}

const commonCardTranslations = {
  [ETranslation.COMMON_CARD_ANNISKELUPASSI]: "Anniskelupassi",
	[ETranslation.COMMON_CARD_ASBESTIPURKUTYOLUPA]: "Asbestipurkutyölupa",
	[ETranslation.COMMON_CARD_HYGIENIAPASSI]: "Hygieniapassi",
	[ETranslation.COMMON_CARD_IKARAJAPASSI]: "Ikärajapassi",
	[ETranslation.COMMON_CARD_JARJESTYKSENVALVOJAKORTTI]: "Järjestyksenvalvojakortti",
	[ETranslation.COMMON_CARD_TULITYOKORTTI]: "Tulityökortti",
	[ETranslation.COMMON_CARD_TYOTURVALLISUUSKORTTI]: "Työturvallisuuskortti",
	[ETranslation.COMMON_CARD_NONE]: "Ei mikään näistä",
}

const workTypeTranslations = {
  [ETranslation.WORK_TYPE_PARTTIME]: "Osa-aikainen", 
	[ETranslation.WORK_TYPE_TEMPORARY]: "Tilapäistä", 
	[ETranslation.WORK_TYPE_PERMANENT]: "Vakituinen", 
	[ETranslation.WORK_TYPE_FULLTIME]: "Kokopäiväistä", 
}

const workTimeTranslations = {
  [ETranslation.WORK_TIME_EVENINGS]: "Illalla", 
	[ETranslation.WORK_TIME_WEEKDAYS]: "Arkipäivisin", 
	[ETranslation.WORK_TIME_WEEKENDS]: "Viikonloppuisin", 
	[ETranslation.WORK_TIME_MORNINGS]: "Aamulla", 
	[ETranslation.WORK_TIME_SHIFTWORK]: "Vuorotyö",
}

const baseEducationTranslations = {
  [ETranslation.BASE_EDUCATION_GRADE_SCHOOL]: "Peruskoulu", 
	[ETranslation.BASE_EDUCATION_HIGH_SCHOOL]: "Lukio", 
}

const selectTranslations = {
  [ETranslation.SELECT_USER_PLACEHOLDER]: "Valitse käyttäjä",
  [ETranslation.SELECT_STATUS_PLACEHOLDER]: "Valitse tila",
  [ETranslation.SELECT_LEVEL_PLACEHOLDER]: "Valitse käyttäjätaso",
  [ETranslation.SELECT_EMPLOYMENT_TYPE_PLACEHOLDER]: "Valitse työsuhteen tyyppi",
  [ETranslation.SELECT_FIELD_OF_WORK_PLACEHOLDER]: "Valitse työalat",
  [ETranslation.SELECT_CITY_PLACEHOLDER]: "Valitse kunta/kaupunki",
};

const confirmTranslations = {
  [ETranslation.CONFIRM_DELETE_COMMENT]:
    "Haluatko varmasti poistaa kommentin?",
  [ETranslation.CONFIRM_POST_SAVE]: "Varmista tiedot, Haluatko tallentaa?",
  [ETranslation.CONFIRM_DELETE_ATTACHMENT]: "Haluatko varmasti poistaa liitteen?",
  [ETranslation.CONFIRM_DELETE_JOB_APPLICATION]: "Haluatko varmasti poistaa työhakemuksen?",
  [ETranslation.CONFIRM_DELETE_POST]: "Haluatko varmasti poistaa työpaikan?",
  [ETranslation.CONFIRM_DELETE_USER]: "Haluatko varmasti poistaa käyttäjän?",
  [ETranslation.CONFIRM_DELETE_WORK_HISTORY]: "Haluatko varmasti poistaa työkokemuksen?",
  [ETranslation.CONFIRM_DELETE_EDUCATION_HISTORY]: "Haluatko varmasti poistaa koulutuksen?",
  [ETranslation.CONFIRM_NOT_HIDDEN_POST]: "Työpaikka ei ole piilotettu, se julkaistaan www-sivustolle.",
};

const workHistoryTranslations = {
  [ETranslation.WORK_HISTORY]: "Työkokemus",
  [ETranslation.EMPLOYER]: "Työnantaja",
  [ETranslation.JOB_TITLE]: "Tehtävänimike",
  [ETranslation.JOB_ASSIGNMENTS]: "Työtehtävät",
  [ETranslation.WORK_START_DATE]: "Työsuhde alkoi",
  [ETranslation.WORK_END_DATE]: "Työsuhde päättyi",
};

const educationHistoryTranslations = {
  [ETranslation.EDUCATION_HISTORY]: "Koulutus",
  [ETranslation.EDUCATION_LEVEL]: "Jatkokoulutus",
  [ETranslation.DEGREE]: "Tutkinnon nimi",
  [ETranslation.MAJOR]: "Pääaine",
  [ETranslation.FIELD_OF_EDUCATION]: "Koulutusala",
  [ETranslation.SCHOOL]: "Oppilaitos",
  [ETranslation.EDUCATION_START_DATE]: "Koulutus alkoi",
  [ETranslation.EDUCATION_END_DATE]: "Koulutus päättyi (tai arvio)",
  [ETranslation.ONGOING]: "Tutkinto kesken",
};

const educationLevelTranslations = {
  [ETranslation.EDUCATION_LEVEL_VOCATIONAL_SCHOOL]: "Ammattikoulu",
	[ETranslation.EDUCATION_LEVEL_UNIVERSITY_OF_APPLIED_SCIENCES]: "Ammattikorkeakoulu",
	[ETranslation.EDUCATION_LEVEL_UNIVERSITY]: "Yliopisto",
	[ETranslation.EDUCATION_LEVEL_ADULT_EDUCATION_COURSE]: "Aikuiskoulutuskurssi",
	[ETranslation.EDUCATION_LEVEL_THE_INSTITUTE_OF_ADULT_EDUCATION]: "Opistotaso",
	[ETranslation.EDUCATION_LEVEL_UPPER_UNIVERSITY_OF_APPLIED_SCIENCES]: "Ylempi ammattikorkeakoulu",
	[ETranslation.EDUCATION_LEVEL_LICENTIATE]: "Lisensiaatti",
	[ETranslation.EDUCATION_LEVEL_OTHER]: "Muu",
	[ETranslation.EDUCATION_LEVEL_IN_PROGRESS]: "Koulutus kesken",
	[ETranslation.EDUCATION_LEVEL_NONE]: "Ei jatkokoulutusta",
};

const hardToFindTranslations = {
  [ETranslation.HARD_TO_FIND_1]: "1, Helppo",
  [ETranslation.HARD_TO_FIND_2]: "2",
  [ETranslation.HARD_TO_FIND_3]: "3, Kohtalainen",
  [ETranslation.HARD_TO_FIND_4]: "4",
  [ETranslation.HARD_TO_FIND_5]: "5, Erittäin haastava",
};

const languageSkillTranslations = {
  [ETranslation.LANGUAGE_SKILL_0]: "0 - ei kielitaitoa",
  [ETranslation.LANGUAGE_SKILL_1]: "1 - alkeet",
  [ETranslation.LANGUAGE_SKILL_2]: "2 - kohtalainen",
  [ETranslation.LANGUAGE_SKILL_3]: "3 - hyvä",
  [ETranslation.LANGUAGE_SKILL_4]: "4 - sujuva",
  [ETranslation.LANGUAGE_SKILL_5]: "5 - äidinkieli",
};

const logItemTranslations = {
  [ETranslation.LOG]: "Loki",
  [ETranslation.USER]: "Käyttäjä",
};

const translationsFi = {
  ...commonTranslations,
  ...authTranslations,
  ...headerTranslations,
  ...PostsTranslations,
  ...PostTranslations,
  ...PostCommentsTranslations,
  ...PostListTranslations,
  ...PostSearchTranslations,
  ...PostStatusTranslations,
  ...UsersTranslations,
  ...UserTranslations,
//  ...UserSearchTranslations,
  ...JobApplicationTranslations,
  ...jobApplicationListTranslations,
  ...reactSelectTranslations,
  ...reactDropzoneTranslations,
  ...errorTranslations,
  ...validationTranslations,
  ...levelTranslations,
  ...employmentTypeTranslations,
  ...genderTranslations,
  ...fieldOfWorkTranslations,
  ...commonCardTranslations,
  ...workTypeTranslations,
  ...workTimeTranslations,
  ...baseEducationTranslations,
  ...selectTranslations,
  ...confirmTranslations,
  ...workHistoryTranslations,
  ...educationHistoryTranslations,
  ...educationLevelTranslations,
  ...hardToFindTranslations,
  ...languageSkillTranslations,
  ...logItemTranslations
};

export default translationsFi;

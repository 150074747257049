import ILogItemAction from "../../interfaces/action/ILogItemAction";
import ILogItemState from "../../interfaces/state/ILogItemState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = ILogItemState;
type ReducerSignature = (
  state: ReducerState,
  action: ILogItemAction
) => ReducerState;

const initialState: ReducerState = {
  logItems: null,
  loading: false,
  error: null,
};

const listLogItemsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listLogItemsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    logItems: action.logItems || null,
    error: null,
    loading: false,
  };
};

const listLogItemsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    logItems: null,
    loading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: ILogItemAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.LOG_ITEM_LIST_START:
      return listLogItemsStart(state, action);
    case EActionTypes.LOG_ITEM_LIST_SUCCESS:
      return listLogItemsSuccess(state, action);
    case EActionTypes.LOG_ITEM_LIST_FAIL:
      return listLogItemsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

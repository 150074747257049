import axios from "axios";
import { getAuth } from "firebase/auth";
import moment from "moment-timezone";
import { Routes } from "./classes/Routes";
import firebase from "./firebase";

const hostname = window && window.location && window.location.hostname;

let apiPath = "/";
let withCredentials = false;
if (hostname === "localhost") {
  apiPath = "http://localhost:8080/";
  withCredentials = true;
}

const instance = axios.create({
  baseURL: apiPath,
  withCredentials: withCredentials,
});

instance.interceptors.request.use(async (config) => {
  const firebaseToken = await getAuth(firebase).currentUser?.getIdToken();
  if (config.headers) {
    config.headers["X-Auth"] = `Bearer ${firebaseToken ? firebaseToken : ""}`;
    config.headers["X-TimeZoneName"] = moment.tz.guess();
  }
  return config;
});

instance.interceptors.response.use(
  (config) => config,
  ({ response }) => {
    if (!response || response.status === 500 || response.status === 405) {
      response.data = "Server error! Please contact support.";
    }
    if (response && response.status === 401) {
      window.location.href = Routes.LOGIN;
    }
    return Promise.reject({ response });
  }
);

export default instance;

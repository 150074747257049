import { EActionTypes } from "../EActionTypes";
import IAuthAction from "../../interfaces/action/IAuthAction";
import IAuthState from "../../interfaces/state/IAuthState";

type ReducerState = IAuthState;
type ReducerSignature = (
  state: ReducerState,
  action: IAuthAction
) => ReducerState;

const initialState: ReducerState = {
  authUser: null,
  loading: false,
  error: null,
};

const authStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: null,
    loading: true,
    authUser: null,
  };
};

const authSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    authUser: action.user || null,
    error: null,
    loading: false,
  };
};

const authFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    error: action.error || null,
    loading: false,
  };
};

const getCurrentUserStart = (
  state: ReducerState,
  action: IAuthAction
): ReducerState => {
  return {
    ...state,
    loading: true,
    error: null,
  };
};

const getCurrentUserSuccess = (
  state: ReducerState,
  action: IAuthAction
): ReducerState => {
  return {
    ...state,
    authUser: action.user || null,
    loading: false,
    error: null,
  };
};

const getCurrentUserFail = (
  state: ReducerState,
  action: IAuthAction
): ReducerState => {
  return {
    ...state,
    error: action.error || null,
    authUser: null,
    loading: false,
  };
};

const getCurrentUserClear = (
  state: ReducerState,
  action: IAuthAction
): ReducerState => {
  return {
    ...initialState,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: IAuthAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.AUTH_START:
      return authStart(state, action);
    case EActionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case EActionTypes.AUTH_FAIL:
      return authFail(state, action);
    case EActionTypes.AUTH_GET_START:
      return getCurrentUserStart(state, action);
    case EActionTypes.AUTH_GET_SUCCESS:
      return getCurrentUserSuccess(state, action);
    case EActionTypes.AUTH_GET_FAIL:
      return getCurrentUserFail(state, action);
    case EActionTypes.AUTH_GET_CLEAR:
      return getCurrentUserClear(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { IOption } from "../Input/Input";

interface IProps {
  options?: IOption[];
  name: string;
  value: string | string[];
  placeholder?: string;
  multiple?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onChange: (value: string | string[]) => void;
}
const Select: React.FC<IProps> = ({
  options,
  name,
  placeholder,
  multiple,
  loading,
  disabled,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const [selectValue, setSelectValue] = useState<
    IOption | IOption[] | null
  >();

  useEffect(() => {
    if (value && options) {
      if (multiple) {
        setSelectValue(
          options.filter((option) => value.includes(option.value))
        );
      } else {
        setSelectValue(options.find((option) => option.value === value));
      }
    } else {
      setSelectValue(null);
    }
  }, [value, options, multiple]);

  const changeHandler = (
    value: SingleValue<IOption> | MultiValue<IOption>,
    actionMeta: ActionMeta<IOption>
  ) => {
    if (multiple) {
      onChange(
        (value as MultiValue<IOption> | undefined)?.map(
          (value) => value.value
        ) || ""
      );
    } else {
      onChange((value as SingleValue<IOption> | undefined)?.value || "");
    }
  };

  const getOptionLabel = (option: IOption) => {
    return (option.labelTranslation ? t(option.labelTranslation) : option.label) || "";
  };

  return (
    <ReactSelect
      name={name}
      onChange={changeHandler}
      isLoading={loading}
      value={selectValue}
      placeholder={placeholder}
      options={options}
      styles={{
        control: (base, state) => {
          const styles = {
            borderColor: "#008000",
            boxShadow: "0 0 0 1px #008000",
          }
          return {
            ...base,
            ...(state.isFocused ? {...styles, ":hover": styles} : {}),
          };
        },
      }}
      isDisabled={disabled}
      isSearchable
      isClearable
      isMulti={multiple}
      getOptionLabel={getOptionLabel}
    />
  );
};

export default Select;

import { EBaseEducation, ECommonCard, EDriversLicenceType, EJobApplicationStatus, EWorkTime, EWorkType } from './../interfaces/domain/IJobApplication';
import { IOption } from "../components/UI/Input/Input";
import { EEmploymentType, EGender, EUserLevel } from "../interfaces/domain/IUser";
import { ETranslation } from "../translations/translation-keys";
import { EFieldOfWork } from '../interfaces/domain/IPost';
import { EEducationLevel } from '../interfaces/domain/IEducationHistory';

export const PostSearchOptions: IOption[] = [
  // { value: EPostSearch.startDate, labelTranslation: ETranslation.SEARCH_START_DATE },
  //{ value: EPostSearch.status, labelTranslation: ETranslation.SEARCH_STATUS },
];

export const JobApplicationSearchOptions: IOption[] = [
  // { value: EPostSearch.startDate, labelTranslation: ETranslation.SEARCH_START_DATE },
  //c{ value: EPostSearch.status, labelTranslation: ETranslation.SEARCH_STATUS },
];

export const JobApplicationStatusOptions: IOption[] = [
  { value: "", labelTranslation: ETranslation.SELECT_STATUS_PLACEHOLDER },
  { value: EJobApplicationStatus.ISSUED, labelTranslation: ETranslation.STATUS_ISSUED },
  { value: EJobApplicationStatus.COMPLETE, labelTranslation: ETranslation.STATUS_COMPLETE },
  { value: EJobApplicationStatus.CANCELLED, labelTranslation: ETranslation.STATUS_CANCELLED },
  { value: EJobApplicationStatus.PLANNING, labelTranslation: ETranslation.STATUS_PLANNING },
];

export const levelOptions: IOption[] = [
  // { value: "", labelTranslation: ETranslation.SELECT_LEVEL_PLACEHOLDER },
  { value: EUserLevel.ADMIN, labelTranslation: ETranslation.LEVEL_ADMIN },
  { value: EUserLevel.OPERATIVE, labelTranslation: ETranslation.LEVEL_OPERATIVE },
  { value: EUserLevel.VIEWER, labelTranslation: ETranslation.LEVEL_VIEWER },
];

export const employmentTypeOptions: IOption[] = [
  // { value: "", labelTranslation: t(ETranslation.SELECT_EMPLOYMENT_TYPE_PLACEHOLDER) },
  { value: EEmploymentType.FULL_TIME, labelTranslation: ETranslation.EMPLOYMENT_TYPE_FULL_TIME },
  { value: EEmploymentType.PART_TIME, labelTranslation: ETranslation.EMPLOYMENT_TYPE_PART_TIME },
];

export const genderOptions: IOption[] = [
  { value: EGender.male, labelTranslation: ETranslation.GENDER_MALE },
  { value: EGender.female, labelTranslation: ETranslation.GENDER_FEMALE },
  { value: EGender.other, labelTranslation: ETranslation.GENDER_OTHER },
  { value: EGender.notWillingToAnswer, labelTranslation: ETranslation.GENDER_NOT_WILLING_TO_ANSWER },
];

export const fieldOfWorkOptions: IOption[] = [
  { value: EFieldOfWork.assembly, labelTranslation: ETranslation.FIELD_OF_WORK_ASSEMBLY },
	{ value: EFieldOfWork.catering, labelTranslation: ETranslation.FIELD_OF_WORK_CATERING },
	{ value: EFieldOfWork.construction, labelTranslation: ETranslation.FIELD_OF_WORK_CONSTRUCTION },
	{ value: EFieldOfWork.customerService, labelTranslation: ETranslation.FIELD_OF_WORK_CUSTOMER_SERVICE },
	{ value: EFieldOfWork.excavation, labelTranslation: ETranslation.FIELD_OF_WORK_EXCAVATION },
	{ value: EFieldOfWork.foodChainServices, labelTranslation: ETranslation.FIELD_OF_WORK_FOOD_CHAIN_SERVICES },
	{ value: EFieldOfWork.foodWork, labelTranslation: ETranslation.FIELD_OF_WORK_FOOD_WORK },
	{ value: EFieldOfWork.industrialWork, labelTranslation: ETranslation.FIELD_OF_WORK_INDUSTRIAL_WORK },
	{ value: EFieldOfWork.installationWork, labelTranslation: ETranslation.FIELD_OF_WORK_INSTALLATION_WORK },
	{ value: EFieldOfWork.kitchenServices, labelTranslation: ETranslation.FIELD_OF_WORK_KITCHEN_SERVICES },
	{ value: EFieldOfWork.laundryWork, labelTranslation: ETranslation.FIELD_OF_WORK_LAUNDRY_WORK },
	{ value: EFieldOfWork.logistics, labelTranslation: ETranslation.FIELD_OF_WORK_LOGISTICS },
	{ value: EFieldOfWork.maintenance, labelTranslation: ETranslation.FIELD_OF_WORK_MAINTENANCE },
	{ value: EFieldOfWork.metalWork, labelTranslation: ETranslation.FIELD_OF_WORK_METAL_WORK },
	{ value: EFieldOfWork.officeWork, labelTranslation: ETranslation.FIELD_OF_WORK_OFFICE_WORK },
	{ value: EFieldOfWork.processIndustry, labelTranslation: ETranslation.FIELD_OF_WORK_PROCESS_INDUSTRY },
	{ value: EFieldOfWork.serviceSector, labelTranslation: ETranslation.FIELD_OF_WORK_SERVICE_SECTOR },
	{ value: EFieldOfWork.surfaceTreatment, labelTranslation: ETranslation.FIELD_OF_WORK_SURFACE_TREATMENT },
	{ value: EFieldOfWork.textileIndustry, labelTranslation: ETranslation.FIELD_OF_WORK_TEXTILE_INDUSTRY },
	{ value: EFieldOfWork.transportation, labelTranslation: ETranslation.FIELD_OF_WORK_TRANSPORTATION }
];

export const driversLicenceTypeOptions: IOption[] = [
  { value: EDriversLicenceType.B1, label: "B1" },
  { value: EDriversLicenceType.B, label: "B" },
  { value: EDriversLicenceType.C1, label: "C1" },
  { value: EDriversLicenceType.C, label: "C" },
  { value: EDriversLicenceType.D1, label: "D1" },
  { value: EDriversLicenceType.D, label: "D" },
  { value: EDriversLicenceType.BE, label: "BE" },
  { value: EDriversLicenceType.C1E, label: "C1E" },
  { value: EDriversLicenceType.CE, label: "CE" },
  { value: EDriversLicenceType.D1E, label: "D1E" },
  { value: EDriversLicenceType.DE, label: "DE" },
];

 export const commonCardOptions: IOption[] = [
  { value: ECommonCard.anniskelupassi, labelTranslation: ETranslation.COMMON_CARD_ANNISKELUPASSI },
  { value: ECommonCard.asbestipurkutyolupa, labelTranslation: ETranslation.COMMON_CARD_ASBESTIPURKUTYOLUPA },
  { value: ECommonCard.hygieniapassi, labelTranslation: ETranslation.COMMON_CARD_HYGIENIAPASSI },
  { value: ECommonCard.ikarajapassi, labelTranslation: ETranslation.COMMON_CARD_IKARAJAPASSI },
  { value: ECommonCard.jarjestyksenvalvojakortti, labelTranslation: ETranslation.COMMON_CARD_JARJESTYKSENVALVOJAKORTTI },
  { value: ECommonCard.tulityokortti, labelTranslation: ETranslation.COMMON_CARD_TULITYOKORTTI },
  { value: ECommonCard.tyoturvallisuuskortti, labelTranslation: ETranslation.COMMON_CARD_TYOTURVALLISUUSKORTTI },
  { value: ECommonCard.none, labelTranslation: ETranslation.COMMON_CARD_NONE },
];

export const workTypeOptions: IOption[] = [
  { value: EWorkType.partTime, labelTranslation: ETranslation.WORK_TYPE_PARTTIME },
  { value: EWorkType.temporary, labelTranslation: ETranslation.WORK_TYPE_TEMPORARY },
  { value: EWorkType.permanent, labelTranslation: ETranslation.WORK_TYPE_PERMANENT },
  { value: EWorkType.fullTime, labelTranslation: ETranslation.WORK_TYPE_FULLTIME },
];

export const workTimeOptions: IOption[] = [
  { value: EWorkTime.evenings, labelTranslation: ETranslation.WORK_TIME_EVENINGS },
  { value: EWorkTime.weekdays, labelTranslation: ETranslation.WORK_TIME_WEEKDAYS },
  { value: EWorkTime.weekends, labelTranslation: ETranslation.WORK_TIME_WEEKENDS },
  { value: EWorkTime.mornings, labelTranslation: ETranslation.WORK_TIME_MORNINGS },
  { value: EWorkTime.shiftWork, labelTranslation: ETranslation.WORK_TIME_SHIFTWORK },
];

export const educationLevelOptions: IOption[] = [
  { value: EEducationLevel.vocationalSchool, labelTranslation: ETranslation.EDUCATION_LEVEL_VOCATIONAL_SCHOOL },
	{ value: EEducationLevel.universityOfAppliedSciences, labelTranslation: ETranslation.EDUCATION_LEVEL_UNIVERSITY_OF_APPLIED_SCIENCES },
	{ value: EEducationLevel.university, labelTranslation: ETranslation.EDUCATION_LEVEL_UNIVERSITY },
	{ value: EEducationLevel.adultEducationCourse, labelTranslation: ETranslation.EDUCATION_LEVEL_ADULT_EDUCATION_COURSE },
	{ value: EEducationLevel.theInstituteofAdultEducation, labelTranslation: ETranslation.EDUCATION_LEVEL_THE_INSTITUTE_OF_ADULT_EDUCATION },
	{ value: EEducationLevel.upperUniversityOfAppliedSciences, labelTranslation: ETranslation.EDUCATION_LEVEL_UPPER_UNIVERSITY_OF_APPLIED_SCIENCES },
	{ value: EEducationLevel.licentiate, labelTranslation: ETranslation.EDUCATION_LEVEL_LICENTIATE },
	{ value: EEducationLevel.other, labelTranslation: ETranslation.EDUCATION_LEVEL_OTHER },
	{ value: EEducationLevel.inProgress, labelTranslation: ETranslation.EDUCATION_LEVEL_IN_PROGRESS },
	{ value: EEducationLevel.none, labelTranslation: ETranslation.EDUCATION_LEVEL_NONE },
];

export const baseEducationOptions: IOption[] = [
  { value: EBaseEducation.gradeSchool, labelTranslation: ETranslation.BASE_EDUCATION_GRADE_SCHOOL },
  { value: EBaseEducation.highSchool, labelTranslation: ETranslation.BASE_EDUCATION_HIGH_SCHOOL },
];

export const hardToFindOptions: IOption[] = [
  { value: "1", labelTranslation: ETranslation.HARD_TO_FIND_1 },
  { value: "2", labelTranslation: ETranslation.HARD_TO_FIND_2 },
  { value: "3", labelTranslation: ETranslation.HARD_TO_FIND_3 },
  { value: "4", labelTranslation: ETranslation.HARD_TO_FIND_4 },
  { value: "5", labelTranslation: ETranslation.HARD_TO_FIND_5 },
];

export const languageSkillOptions: IOption[] = [
  { value: "0", labelTranslation: ETranslation.LANGUAGE_SKILL_0 },
  { value: "1", labelTranslation: ETranslation.LANGUAGE_SKILL_1 },
  { value: "2", labelTranslation: ETranslation.LANGUAGE_SKILL_2 },
  { value: "3", labelTranslation: ETranslation.LANGUAGE_SKILL_3 },
  { value: "4", labelTranslation: ETranslation.LANGUAGE_SKILL_4 },
  { value: "5", labelTranslation: ETranslation.LANGUAGE_SKILL_5 },
];
import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import { IJobApplicationSearch } from "../../components/JobApplications/JobApplicationSearch/JobApplicationSearch";
import IJobApplicationAction from "../../interfaces/action/IJobApplicationAction";
import { IJobApplication } from "../../interfaces/domain/IJobApplication";
import { EActionTypes } from "../EActionTypes";
import LocalStorage from "../../shared/localstorage-utils";

type TAction = IJobApplicationAction;
const apiPath = "/json/jobapplication";

export const clearJobApplication = () => {
  return {
    type: EActionTypes.JOB_APPLICATION_CLEAR,
  };
};

const listJobApplicationsStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_LIST_START,
  };
};

const listJobApplicationsSuccess = (
  jobApplications: IJobApplication[]
): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_LIST_SUCCESS,
    jobApplications,
  };
};

const listJobApplicationsFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_LIST_FAIL,
    error,
  };
};

export const listJobApplications = (search: IJobApplicationSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listJobApplicationsStart());
    try {
      const res = await axios.post<IJobApplication[]>(`${apiPath}/list`, search);
      dispatch(listJobApplicationsSuccess(res.data));
      LocalStorage.setJobApplicationSearch(search);
    } catch ({ response }) {
      dispatch(listJobApplicationsFail((response as AxiosResponse).data));
    }
  };
};

const getJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_GET_START,
  };
};

const getJobApplicationSuccess = (jobApplication: IJobApplication): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_GET_SUCCESS,
    jobApplication,
  };
};

const getJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_GET_FAIL,
    error,
  };
};

export const getJobApplication = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getJobApplicationStart());
    try {
      const res = await axios.get<IJobApplication>(`${apiPath}/get?id=${id}`);
      dispatch(getJobApplicationSuccess(res.data));
    } catch ({ response }) {
      dispatch(getJobApplicationFail((response as AxiosResponse).data));
    }
  };
};

const updateJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_UPDATE_START,
  };
};

const updateJobApplicationSuccess = (
  jobApplication: IJobApplication
): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_UPDATE_SUCCESS,
    jobApplication,
  };
};

const updateJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_UPDATE_FAIL,
    error,
  };
};

export const updateJobApplication = (jobApplication: IJobApplication) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateJobApplicationStart());
    try {
      const res = await axios.put<IJobApplication>(
        `${apiPath}/update`,
        jobApplication
      );
      dispatch(updateJobApplicationSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateJobApplicationFail((response as AxiosResponse).data));
    }
  };
};

const saveJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SAVE_START,
  };
};

const saveJobApplicationSuccess = (
  jobApplication: IJobApplication
): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SAVE_SUCCESS,
    jobApplication,
  };
};

const saveJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SAVE_FAIL,
    error,
  };
};

export const saveJobApplication = (jobApplication: IJobApplication) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveJobApplicationStart());
    try {
      const res = await axios.post<IJobApplication>(
        `${apiPath}/add?admin=true`,
        jobApplication
      );
      dispatch(saveJobApplicationSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveJobApplicationFail((response as AxiosResponse).data));
    }
  };
};
/*
const updateJobApplicationCommentsStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_UPDATE_START,
  };
};

const updateJobApplicationCommentsSuccess = (jobApplication: IJobApplication): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_UPDATE_SUCCESS,
    jobApplication,
  };
};

const updateJobApplicationCommentsFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_UPDATE_FAIL,
    error,
  };
};

export const updateJobApplicationComments = (id: string, comments: IComment[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateJobApplicationCommentsStart());
    try {
      const res = await axios.put<IJobApplication>(
        `${apiPath}/comment/update`,
        { id, comments }
      );
      dispatch(updateJobApplicationCommentsSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateJobApplicationCommentsFail((response as AxiosResponse).data));
    }
  };
};*/

const addJobApplicationCommentStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_ADD_START,
  };
};

const addJobApplicationCommentSuccess = (
  jobApplication: IJobApplication
): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_ADD_SUCCESS,
    jobApplication,
  };
};

const addJobApplicationCommentFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_ADD_FAIL,
    error,
  };
};

export const addJobApplicationComment = (id: string, message: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(addJobApplicationCommentStart());
    try {
      const res = await axios.put<IJobApplication>(`${apiPath}/comment/add`, {
        id,
        message,
      });
      dispatch(addJobApplicationCommentSuccess(res.data));
    } catch ({ response }) {
      dispatch(addJobApplicationCommentFail((response as AxiosResponse).data));
    }
  };
};

const deleteJobApplicationCommentStart = (id: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_DELETE_START,
    id,
  };
};

const deleteJobApplicationCommentSuccess = (
  jobApplication: IJobApplication
): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_DELETE_SUCCESS,
    jobApplication,
  };
};

const deleteJobApplicationCommentFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_COMMENT_DELETE_FAIL,
    error,
  };
};

export const deleteJobApplicationComment = (
  jobApplicationId: string,
  commentId: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteJobApplicationCommentStart(commentId));
    try {
      const res = await axios.delete<IJobApplication>(
        `${apiPath}/comment/delete?jobApplicationId=${jobApplicationId}&commentId=${commentId}`
      );
      dispatch(deleteJobApplicationCommentSuccess(res.data));
    } catch ({ response }) {
      dispatch(
        deleteJobApplicationCommentFail((response as AxiosResponse).data)
      );
    }
  };
};

const deleteJobApplicationAttachmentStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_ATTACHMENT_DELETE_START,
  };
};

const deleteJobApplicationAttachmentSuccess = (id: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_ATTACHMENT_DELETE_SUCCESS,
    id,
  };
};

const deleteJobApplicationAttachmentFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_ATTACHMENT_DELETE_FAIL,
    error,
  };
};

export const deleteJobApplicationAttachment = (
  jobApplicationId: string,
  attachmentId: string,
  serverName: string
) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteJobApplicationAttachmentStart());
    try {
      await axios.delete(
        `${apiPath}/attachments/delete?id=${jobApplicationId}&attachmentId=${attachmentId}&serverName=${serverName}`
      );
      dispatch(deleteJobApplicationAttachmentSuccess(attachmentId));
    } catch ({ response }) {
      dispatch(
        deleteJobApplicationAttachmentFail((response as AxiosResponse).data)
      );
    }
  };
};

const deleteJobApplicationStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_DELETE_START,
  };
};

const deleteJobApplicationSuccess = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_DELETE_SUCCESS,
  };
};

const deleteJobApplicationFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_DELETE_FAIL,
    error,
  };
};

export const deleteJobApplication = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteJobApplicationStart());
    try {
      await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteJobApplicationSuccess());
    } catch ({ response }) {
      dispatch(deleteJobApplicationFail((response as AxiosResponse).data));
    }
  };
};

const addJobApplicationHistoryStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_HISTORY_ADD_START,
  };
};

const addJobApplicationHistorySuccess = (jobApplication: IJobApplication): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_HISTORY_ADD_SUCCESS,
    jobApplication,
  };
};

const addJobApplicationHistoryFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_HISTORY_ADD_FAIL,
    error,
  };
};

export const addJobApplicationHistory = (jobApplicationId: string, postIdList: string[]) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(addJobApplicationHistoryStart());
    try {
      const res = await axios.put<IJobApplication>(
        `${apiPath}/history/add`,
        { jobApplicationId, postIdList }
      );
      dispatch(addJobApplicationHistorySuccess(res.data));
    } catch ({ response }) {
      dispatch(addJobApplicationHistoryFail((response as AxiosResponse).data));
    }
  };
};


const getProfessionListStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_PROFESSION_LIST_GET_START,
  };
};

const getProfessionListSuccess = (
  professions: string[]
): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_PROFESSION_LIST_GET_SUCCESS,
    professions,
  };
};

const getProfessionListFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_PROFESSION_LIST_GET_FAIL,
    error,
  };
};

export const getProfessionList = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getProfessionListStart());
    try {
      const res = await axios.get<string[]>(`json/professions_list`);
      dispatch(getProfessionListSuccess(res.data));
    } catch ({ response }) {
      dispatch(getProfessionListFail((response as AxiosResponse).data));
    }
  };
};

const searchJobApplicationsStart = (): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SEARCH_START,
  };
};

const searchJobApplicationsSuccess = (
  jobApplications: IJobApplication[]
): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SEARCH_SUCCESS,
    jobApplications,
  };
};

const searchJobApplicationsFail = (error: string): TAction => {
  return {
    type: EActionTypes.JOB_APPLICATION_SEARCH_FAIL,
    error,
  };
};

export const searchJobApplications = (search: IJobApplicationSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(searchJobApplicationsStart());
    try {
      const res = await axios.post<IJobApplication[]>(`/json/search/jobapplication`, search);
      dispatch(searchJobApplicationsSuccess(res.data));
      LocalStorage.setJobApplicationSearch(search);
    } catch ({ response }) {
      dispatch(searchJobApplicationsFail((response as AxiosResponse).data));
    }
  };
};

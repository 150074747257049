export enum ETranslation {
  // Common
  COMMON_EMAIL = "COMMON_EMAIL",
  COMMON_SEND = "COMMON_SEND",
  COMMON_CANCEL = "COMMON_CANCEL",
  COMMON_CLOSE = "COMMON_CLOSE",
  COMMON_RETURN = "COMMON_RETURN",
  COMMON_DELETE = "COMMON_DELETE",
  COMMON_YES = "COMMON_YES",
  COMMON_NO = "COMMON_NO",
  COMMON_SAVE = "COMMON_SAVE",
  COMMON_ADD = "COMMON_ADD",
  COMMON_CREATED = "COMMON_CREATED",
  COMMON_SEARCH = "COMMON_SEARCH",
  COMMON_DOWNLOAD = "COMMON_DOWNLOAD",
  COMMON_OPEN_ALL = "COMMON_OPEN_ALL",

  // Auth
  TITLE = "TITLE",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  PASSWORD_RESET_TEXT = "PASSWORD_RESET_TEXT",

  // Auth Inputs
  USERNAME = "USERNAME",
  PASSWORD = "PASSWORD",
  PASSWORD_MIN_LENGTH_MESSAGE = "PASSWORD_MIN_LENGTH_MESSAGE",

  // Routes
  MY_PAGE = "MY_PAGE",
  POSTS = "POSTS",
  JOB_APPLICATIONS = "JOB_APPLICATIONS",
  USERS = "USERS",
  PROFILE = "PROFILE",

  // Posts
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  NO_POSTS = "NO_POSTS",
  DATE = "DATE",

  // Post
  COMMENTS = "COMMENTS",
  PROFESSION = "PROFESSION",
  CITY = "CITY",
  LOCATION = "LOCATION",
  LOCATION_DETAIL = "LOCATION_DETAIL",
  SHORT_DESCRIPTION = "SHORT_DESCRIPTION",
  FULL_DESCRIPTION = "FULL_DESCRIPTION",
  DATE_POSTED = "DATE_POSTED",
  VALID_THROUGH_TIME = "VALID_THROUGH_TIME",
  VALID_THROUGH_DATE = "VALID_THROUGH_DATE",
  EMPLOYMENT_TYPE = "EMPLOYMENT_TYPE",
  IMAGE_URL = "IMAGE_URL",
  HARD_TO_FIND = "HARD_TO_FIND",
  ARCHIVED = "ARCHIVED",
  ATTACHMENTS = "ATTACHMENTS",
  HIDDEN = "HIDDEN",
  FIELDS_OF_WORK = "FIELDS_OF_WORK",
  STATUS = "STATUS",
  REFERENCE = "REFERENCE",
  NO_RESULTS = "NO_RESULTS",
  COPY = "COPY",

  // Post Comments
  MESSAGE = "MESSAGE",
  COMMENT = "COMMENT",
  ADD_COMMENT = "ADD_COMMENT",

  // Post List
  POST_LIST_FILTER = "POST_LIST_FILTER",

  // Search
  SEARCH_ORDER_BY = "SEARCH_ORDER_BY",
  SEARCH_START_DATE = "SEARCH_START_DATE",
  SEARCH_STATUS = "SEARCH_STATUS",
  SEARCH_TITLE = "SEARCH_TITLE",
  SEARCH_CLEAR_AND_SEARCH = "SEARCH_CLEAR_AND_SEARCH",
  SEARCH_LATEST = "SEARCH_LATEST",
  SEARCH_FIND_LATEST = "SEARCH_FIND_LATEST",
  SEARCH_EXAMPLE_TEXT = "SEARCH_EXAMPLE_TEXT",

  // Post status
  STATUS_ISSUED = "STATUS_ISSUED",
  STATUS_COMPLETE = "STATUS_COMPLETE",
  STATUS_CANCELLED = "STATUS_CANCELLED",
  STATUS_PLANNING = "STATUS_PLANNING",

  // Users
  NAME = "NAME",
  EMAIL = "EMAIL",
  MOBILE = "MOBILE",
  ALLOW_LOGIN = "ALLOW_LOGIN",
  NO_USERS = "NO_USERS",

  // User
  FIRST_NAME = "FIRST_NAME",
  LAST_NAME = "LAST_NAME",
  ADDRESS = "ADDRESS",
  POSTCODE = "POSTCODE",
  PLACE = "PLACE",
  LEVEL = "LEVEL",
  GENDER = "GENDER",
  FIREBASE_USER_ID = "FIREBASE_USER_ID",

  // User Search
  SEARCH_ROLE = "SEARCH_ROLE",

  // JobApplication
  JOB_APPLICATION_TITLE = "JOB_APPLICATION_TITLE",
  NO_JOB_APPLICATIONS = "NO_JOB_APPLICATIONS",
  GDPR_CONCENT = "GDPR_CONCENT",
  OTHER_INFO = "OTHER_INFO",
  POST_ID = "POST_ID",
  POST_SHORT_ID = "POST_SHORT_ID",
  POST_TITLE = "POST_TITLE",
  APPLY_OPTIONS = "APPLY_OPTIONS",
  DRIVERS_LICENSE = "DRIVERS_LICENSE",
  DRIVERS_LICENSE_DETAILS = "DRIVERS_LICENSE_DETAILS",
  OWN_CAR = "OWN_CAR",
  EDUCATION = "EDUCATION",
  LANGUAGE_SKILL_FI = "LANGUAGE_SKILL_FI",
  LANGUAGE_SKILL_EN = "LANGUAGE_SKILL_EN",
  LANGUAGE_SKILL_SE = "LANGUAGE_SKILL_SE",
  BIRTH_YEAR = "BIRTH_YEAR",
  WORK_EXPERIENCE_FIELDS = "WORK_EXPERIENCE_FIELDS",
  SPECIAL_SKILLS = "SPECIAL_SKILLS",
  IT_SKILLS = "IT_SKILLS",
  OTHER_LANGUAGES = "OTHER_LANGUAGES",
  JOB_APPLICATION_TITLE_BASIC = "JOB_APPLICATION_TITLE_BASIC",
  JOB_APPLICATION_TITLE_EXPERIENCE = "JOB_APPLICATION_TITLE_EXPERIENCE",
  JOB_APPLICATION_TITLE_CARDS = "JOB_APPLICATION_TITLE_CARDS",
  JOB_APPLICATION_TITLE_WISHES = "JOB_APPLICATION_TITLE_WISHES",
  JOB_APPLICATION_TITLE_OTHER_INFO = "JOB_APPLICATION_TITLE_OTHER_INFO",
  JOB_APPLICATION_TITLE_APPLY = "JOB_APPLICATION_TITLE_APPLY",
  COMMON_CARDS = "COMMON_CARDS",
  OTHER_CARDS = "OTHER_CARDS",
  PREFERRED_WORK_TYPES = "PREFERRED_WORK_TYPES",
  PREFERRED_STARTING_DATE = "PREFERRED_STARTING_DATE",
  STARTING_DATE_MORE_INFO = "STARTING_DATE_MORE_INFO",
  SALARY_PREFERENCE = "SALARY_PREFERENCE",
  UNWANTED_JOBS = "UNWANTED_JOBS",
  ALSO_APPLY_TO_FIELDS = "ALSO_APPLY_TO_FIELDS",
  WORK_TIME = "WORK_TIME",
  JOB_APPLICATION_EMAIL = "JOB_APPLICATION_EMAIL",
  JOB_APPLICATION_MOBILE = "JOB_APPLICATION_MOBILE",
  LANGUAGE_SKILL = "LANGUAGE_SKILL",
  ADD_HISTORY = "ADD_HISTORY",
  PROFESSIONS = "PROFESSIONS",
  HOBBIES = "HOBBIES",
  OTHER_PROFESSION = "OTHER_PROFESSION",

  // Job Application List
  LIST_PHONE_AND_ZIP = "LIST_PHONE_AND_ZIP",
  LIST_WORK_EXPERIENCE = "LIST_WORK_EXPERIENCE",
  LIST_PROFESSION = "LIST_PROFESSION",
  LIST_FIELD_OF_WORK = "LIST_FIELD_OF_WORK",

  // React Select
  REACT_SELECT_PLACEHOLDER = "REACT_SELECT_PLACEHOLDER",
  REACT_SELECT_ADD_NEW = "REACT_SELECT_ADD_NEW",

  // React Dropzone
  REACT_DROPZONE_DRAG_TEXT = "REACT_DROPZONE_DRAG_TEXT",
  REACT_DROPZONE_DRAG_ACTIVE_TEXT = "REACT_DROPZONE_DRAG_ACTIVE_TEXT",

  // Errors
  NO_ACCESS_ERROR = "NO_ACCESS_ERROR",
  NOT_FOUND_ERROR = "NOT_FOUND_ERROR",
  POPUP_BLOCKED_ERROR_TITLE = "POPUP_BLOCKED_ERROR_TITLE",
  POPUP_BLOCKED_ERROR_TEXT = "POPUP_BLOCKED_ERROR_TEXT",
  DATABASE_ERROR = "DATABASE_ERROR",

  // Validation
  VALIDATION_EMAIL = "VALIDATION_EMAIL",
  VALIDATION_MIN_LENGTH = "VALIDATION_MIN_LENGTH",
  VALIDATION_MAX_LENGTH = "VALIDATION_MAX_LENGTH",
  VALIDATION_MIN_AMOUNT = "VALIDATION_MIN_AMOUNT",
  VALIDATION_MAX_AMOUNT = "VALIDATION_MAX_AMOUNT",

  // Level
  LEVEL_ADMIN = "LEVEL_ADMIN",
  LEVEL_OPERATIVE = "LEVEL_OPERATIVE",
  LEVEL_VIEWER = "LEVEL_VIEWER",

  // Employment type
  EMPLOYMENT_TYPE_FULL_TIME = "EMPLOYMENT_TYPE_FULL_TIME",
  EMPLOYMENT_TYPE_PART_TIME = "EMPLOYMENT_TYPE_PART_TIME",

  // Gender
  GENDER_MALE = "GENDER_MALE",
  GENDER_FEMALE = "GENDER_FEMALE",
  GENDER_OTHER = "GENDER_OTHER",
  GENDER_NOT_WILLING_TO_ANSWER = "GENDER_NOT_WILLING_TO_ANSWER",

  // Field Of Work
  FIELD_OF_WORK_ASSEMBLY = "FIELD_OF_WORK_ASSEMBLY",
  FIELD_OF_WORK_CATERING = "FIELD_OF_WORK_CATERING",
  FIELD_OF_WORK_CONSTRUCTION = "FIELD_OF_WORK_CONSTRUCTION",
  FIELD_OF_WORK_CUSTOMER_SERVICE = "FIELD_OF_WORK_CUSTOMER_SERVICE",
  FIELD_OF_WORK_EXCAVATION = "FIELD_OF_WORK_EXCAVATION",
  FIELD_OF_WORK_FOOD_CHAIN_SERVICES = "FIELD_OF_WORK_FOOD_CHAIN_SERVICES",
  FIELD_OF_WORK_FOOD_WORK = "FIELD_OF_WORK_FOOD_WORK",
  FIELD_OF_WORK_INDUSTRIAL_WORK = "FIELD_OF_WORK_INDUSTRIAL_WORK",
  FIELD_OF_WORK_INSTALLATION_WORK = "FIELD_OF_WORK_INSTALLATION_WORK",
  FIELD_OF_WORK_KITCHEN_SERVICES = "FIELD_OF_WORK_KITCHEN_SERVICES",
  FIELD_OF_WORK_LAUNDRY_WORK = "FIELD_OF_WORK_LAUNDRY_WORK",
  FIELD_OF_WORK_LOGISTICS = "FIELD_OF_WORK_LOGISTICS",
  FIELD_OF_WORK_MAINTENANCE = "FIELD_OF_WORK_MAINTENANCE",
  FIELD_OF_WORK_METAL_WORK = "FIELD_OF_WORK_METAL_WORK",
  FIELD_OF_WORK_OFFICE_WORK = "FIELD_OF_WORK_OFFICE_WORK",
  FIELD_OF_WORK_PROCESS_INDUSTRY = "FIELD_OF_WORK_PROCESS_INDUSTRY",
  FIELD_OF_WORK_SERVICE_SECTOR = "FIELD_OF_WORK_SERVICE_SECTOR",
  FIELD_OF_WORK_SURFACE_TREATMENT = "FIELD_OF_WORK_SURFACE_TREATMENT",
  FIELD_OF_WORK_TEXTILE_INDUSTRY = "FIELD_OF_WORK_TEXTILE_INDUSTRY",
  FIELD_OF_WORK_TRANSPORTATION = "FIELD_OF_WORK_TRANSPORTATION",

  // Common card
  COMMON_CARD_ANNISKELUPASSI = "COMMON_CARD_ANNISKELUPASSI",
  COMMON_CARD_ASBESTIPURKUTYOLUPA = "COMMON_CARD_ASBESTIPURKUTYOLUPA",
  COMMON_CARD_HYGIENIAPASSI = "COMMON_CARD_HYGIENIAPASSI",
  COMMON_CARD_IKARAJAPASSI = "COMMON_CARD_IKARAJAPASSI",
  COMMON_CARD_JARJESTYKSENVALVOJAKORTTI = "COMMON_CARD_JARJESTYKSENVALVOJAKORTTI",
  COMMON_CARD_TULITYOKORTTI = "COMMON_CARD_TULITYOKORTTI",
  COMMON_CARD_TYOTURVALLISUUSKORTTI = "COMMON_CARD_TYOTURVALLISUUSKORTTI",
  COMMON_CARD_NONE = "COMMON_CARD_NONE",

  // Work types
  WORK_TYPE_PARTTIME = "WORK_TYPE_PARTTIME",
  WORK_TYPE_TEMPORARY = "WORK_TYPE_TEMPORARY",
  WORK_TYPE_PERMANENT = "WORK_TYPE_PERMANENT",
  WORK_TYPE_FULLTIME = "WORK_TYPE_FULLTIME",

  // Work times
  WORK_TIME_EVENINGS = "WORK_TIME_EVENINGS",
  WORK_TIME_WEEKDAYS = "WORK_TIME_WEEKDAYS",
  WORK_TIME_WEEKENDS = "WORK_TIME_WEEKENDS",
  WORK_TIME_MORNINGS = "WORK_TIME_MORNINGS",
  WORK_TIME_SHIFTWORK = "WORK_TIME_SHIFTWORK",

  // Base education
  BASE_EDUCATION_GRADE_SCHOOL = "BASE_EDUCATION_GRADE_SCHOOL",
  BASE_EDUCATION_HIGH_SCHOOL = "BASE_EDUCATION_HIGH_SCHOOL",

  // Select
  SELECT_USER_PLACEHOLDER = "SELECT_USER_PLACEHOLDER",
  SELECT_STATUS_PLACEHOLDER = "SELECT_STATUS_PLACEHOLDER",
  SELECT_LEVEL_PLACEHOLDER = "SELECT_LEVEL_PLACEHOLDER",
  SELECT_EMPLOYMENT_TYPE_PLACEHOLDER = "SELECT_EMPLOYMENT_TYPE_PLACEHOLDER",
  SELECT_CITY_PLACEHOLDER = "SELECT_CITY_PLACEHOLDER",
  SELECT_FIELD_OF_WORK_PLACEHOLDER = "SELECT_FIELD_OF_WORK_PLACEHOLDER",

  // Confirm
  CONFIRM_DELETE_COMMENT = "CONFIRM_DELETE_COMMENT",
  CONFIRM_POST_SAVE = "CONFIRM_POST_SAVE",
  PostStatusOptions = "PostStatusOptions",
  CONFIRM_DELETE_ATTACHMENT = "CONFIRM_DELETE_ATTACHMENT",
  CONFIRM_DELETE_JOB_APPLICATION = "CONFIRM_DELETE_JOB_APPLICATION",
  CONFIRM_DELETE_POST = "CONFIRM_DELETE_POST",
  CONFIRM_DELETE_USER = "CONFIRM_DELETE_USER",
  CONFIRM_DELETE_WORK_HISTORY = "CONFIRM_DELETE_WORK_HISTORY",
  CONFIRM_DELETE_EDUCATION_HISTORY = "CONFIRM_DELETE_EDUCATION_HISTORY",
  CONFIRM_NOT_HIDDEN_POST = "CONFIRM_NOT_HIDDEN_POST",

  // Post subtitles
  POST_JOB_INFO = "POST_JOB_INFO",
  POST_PUBLISH_INFO = "POST_PUBLISH_INFO",

  // Work History
  WORK_HISTORY = "WORK_HISTORY",
  EMPLOYER = "EMPLOYER",
  JOB_TITLE = "JOB_TITLE",
  JOB_ASSIGNMENTS = "JOB_ASSIGNMENTS",
  WORK_START_DATE = "WORK_START_DATE",
  WORK_END_DATE = "WORK_END_DATE",

  // Education History
  EDUCATION_HISTORY = "EDUCATION_HISTORY",
  EDUCATION_LEVEL = "EDUCATION_LEVEL",
  DEGREE = "DEGREE",
  MAJOR = "MAJOR",
  FIELD_OF_EDUCATION = "FIELD_OF_EDUCATION",
  SCHOOL = "SCHOOL",
  EDUCATION_START_DATE = "EDUCATION_START_DATE",
  EDUCATION_END_DATE = "EDUCATION_END_DATE",
  ONGOING = "ONGOING",

  // Education level
  EDUCATION_LEVEL_VOCATIONAL_SCHOOL = "EDUCATION_LEVEL_VOCATIONAL_SCHOOL",
  EDUCATION_LEVEL_UNIVERSITY_OF_APPLIED_SCIENCES = "EDUCATION_LEVEL_UNIVERSITY_OF_APPLIED_SCIENCES",
  EDUCATION_LEVEL_UNIVERSITY = "EDUCATION_LEVEL_UNIVERSITY",
  EDUCATION_LEVEL_ADULT_EDUCATION_COURSE = "EDUCATION_LEVEL_ADULT_EDUCATION_COURSE",
  EDUCATION_LEVEL_THE_INSTITUTE_OF_ADULT_EDUCATION = "EDUCATION_LEVEL_THE_INSTITUTE_OF_ADULT_EDUCATION",
  EDUCATION_LEVEL_UPPER_UNIVERSITY_OF_APPLIED_SCIENCES = "EDUCATION_LEVEL_UPPER_UNIVERSITY_OF_APPLIED_SCIENCES",
  EDUCATION_LEVEL_LICENTIATE = "EDUCATION_LEVEL_LICENTIATE",
  EDUCATION_LEVEL_OTHER = "EDUCATION_LEVEL_OTHER",
  EDUCATION_LEVEL_IN_PROGRESS = "EDUCATION_LEVEL_IN_PROGRESS",
  EDUCATION_LEVEL_NONE = "EDUCATION_LEVEL_NONE",

  // Add
  ADD_WORK_HISTORY = "ADD_WORK_HISTORY",
  ADD_EDUCATION_HISTORY = "ADD_EDUCATION_HISTORY",

  // Hard to find
  HARD_TO_FIND_1 = "HARD_TO_FIND_1",
  HARD_TO_FIND_2 = "HARD_TO_FIND_2",
  HARD_TO_FIND_3 = "HARD_TO_FIND_3",
  HARD_TO_FIND_4 = "HARD_TO_FIND_4",
  HARD_TO_FIND_5 = "HARD_TO_FIND_5",

  // Hard to find
  LANGUAGE_SKILL_0 = "LANGUAGE_SKILL_0",
  LANGUAGE_SKILL_1 = "LANGUAGE_SKILL_1",
  LANGUAGE_SKILL_2 = "LANGUAGE_SKILL_2",
  LANGUAGE_SKILL_3 = "LANGUAGE_SKILL_3",
  LANGUAGE_SKILL_4 = "LANGUAGE_SKILL_4",
  LANGUAGE_SKILL_5 = "LANGUAGE_SKILL_5",

  // Log
  LOG = "LOG",
  USER = "USER",
  
}
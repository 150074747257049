import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import ILogItemAction from "../../interfaces/action/ILogItemAction";
import { EActionTypes } from "../EActionTypes";
import { ILogItem } from "./../../interfaces/domain/ILogItem";

type TAction = ILogItemAction;
const apiPath = "/json/logitem";

export interface ILogItemSearch {
  userId?: number;
}

const listLogItemsStart = (): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_LIST_START,
  };
};

const listLogItemsSuccess = (logItems: ILogItem[]): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_LIST_SUCCESS,
    logItems,
  };
};

const listLogItemsFail = (error: string): TAction => {
  return {
    type: EActionTypes.LOG_ITEM_LIST_FAIL,
    error,
  };
};

export const listLogItems = (search: ILogItemSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listLogItemsStart());
    try {
      const res = await axios.get<ILogItem[]>(`${apiPath}/list?userId=${search.userId ?? ""}`);
      dispatch(listLogItemsSuccess(res.data));
    } catch ({ response }) {
      dispatch(listLogItemsFail((response as AxiosResponse).data));
    }
  };
};
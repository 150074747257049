// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAa_UlxeEZAnyjo5vYZSPIaPwpGreUrwPY",
  authDomain: "alrekry.firebaseapp.com",
  projectId: "alrekry",
  storageBucket: "alrekry.appspot.com",
  messagingSenderId: "518548659039",
  appId: "1:518548659039:web:a174d41ec4ee7f1e2ab4ea"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);

export enum EKind {
  Post = "Post",
  JobApplication = "JobApplication",
}

export interface BaseDomain {
  id: string;
  created?: string;
  updated?: string;
}

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import Attachments from "../../../components/Attachments/Attachments";
import Title from "../../../components/Title/Title";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import ConfirmDialog from "../../../components/UI/ConfirmModal/ConfirmModal";
import Container from "../../../components/UI/Container/Container";
import { EInputType, IInputField } from "../../../components/UI/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../../../components/UI/Input/input-utils";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ModalContext, { EModalSize } from "../../../context/ModalContext";
import { cityOptions } from "../../../data/city-data";
import {
  employmentTypeOptions,
  hardToFindOptions,
} from "../../../data/select-data";
import { fieldOfWorkOptions } from "../../../data/select-data";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useBlurBeforeUnload } from "../../../hooks/useBlurBeforeUnload";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { IAttachment } from "../../../interfaces/domain/IAttachment";
import { EKind } from "../../../interfaces/domain/IBaseDomain";
import { IPost } from "../../../interfaces/domain/IPost";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import { useAuthUser } from "../../../hooks/useAuthUser";
import Alert from "../../../components/UI/Alert/Alert";
import classes from "./Post.module.scss";

export enum EPost {
  comments = "comments",
  profession = "profession",
  city = "city",
  locationDetail = "locationDetail",
  postalCode = "postalCode",
  shortDescription = "shortDescription",
  description = "description",
  datePosted = "datePosted",
  validThroughDate = "validThroughDate",
  validThroughTime = "validThroughTime",
  employmentType = "employmentType",
  image = "image",
  hardToFind = "hardToFind",
  archived = "archived",
  attachments = "attachments",
  hidden = "hidden",
  fieldsOfWork = "fieldsOfWork",
  reference = "reference",
}

const Post: React.FC = () => {
  useBlurBeforeUnload();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { loading, post, saveOrUpdateOk, saveOrUpdateLoading, error, copyId, copyLoading } = 
    useAppSelector((state) => state.post);
  const [isValid, setIsValid] = useState(false);
  const id = params.id as string;

  const {
    loading: attachmentLoading,
    inputName,
    attachments,
  } = useAppSelector((state) => state.attachment);
  const [image, setImage] = useState<IAttachment | null>(null);
  const [postAttachments, setPostAttachments] = useState<IAttachment[]>([]);
  const { setModal, closeModal } = useContext(ModalContext);
  const authUser = useAuthUser();

  // const [comments, setComments] = useState<IComment[]>([]);
  const attachmentInput = inputName as EPost;

  useEffect(() => {
    if (saveOrUpdateOk) {
      navigate(Routes.POSTS);
    }
    return () => {
      dispatch(actions.clearPost());
      dispatch(actions.clearAttachment());
    };
  }, [dispatch, saveOrUpdateOk, navigate]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getPost(id));
    }
  }, [dispatch, id]);

  const [inputs, setInputs] = useState<IInputField>({
    [EPost.profession]: {
      type: EInputType.text,
      labelTranslation: ETranslation.PROFESSION,
      placeholderTranslation: ETranslation.PROFESSION,
      value: "",
      validation: {
        required: true,
      },
    },
    [EPost.city]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.CITY,
      placeholderTranslation: ETranslation.SELECT_CITY_PLACEHOLDER,
      value: "",
      options: cityOptions,
    },
    [EPost.locationDetail]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LOCATION,
      placeholderTranslation: ETranslation.LOCATION,
      value: "",
    },
    [EPost.postalCode]: {
      type: EInputType.text,
      labelTranslation: ETranslation.POSTCODE,
      placeholderTranslation: ETranslation.POSTCODE,
      value: "",
    },
    [EPost.fieldsOfWork]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.FIELDS_OF_WORK,
      placeholderTranslation: ETranslation.SELECT_FIELD_OF_WORK_PLACEHOLDER,
      value: "",
      multiple: true,
    },
    [EPost.shortDescription]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.SHORT_DESCRIPTION,
      placeholderTranslation: ETranslation.SHORT_DESCRIPTION,
      value: "",
    },
    [EPost.description]: {
      type: EInputType.wysiwyg,
      labelTranslation: ETranslation.FULL_DESCRIPTION,
      placeholderTranslation: ETranslation.FULL_DESCRIPTION,
      value: "",
    },
    [EPost.image]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.IMAGE_URL,
      placeholderTranslation: ETranslation.IMAGE_URL,
      value: "",
    },
    [EPost.datePosted]: {
      type: EInputType.date,
      labelTranslation: ETranslation.DATE_POSTED,
      placeholderTranslation: ETranslation.DATE_POSTED,
      value: "",
    },
    [EPost.validThroughDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.VALID_THROUGH_DATE,
      placeholderTranslation: ETranslation.VALID_THROUGH_DATE,
      value: "",
    },
    [EPost.validThroughTime]: {
      type: EInputType.time,
      labelTranslation: ETranslation.VALID_THROUGH_TIME,
      placeholderTranslation: ETranslation.VALID_THROUGH_TIME,
      value: "",
    },
    [EPost.employmentType]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.EMPLOYMENT_TYPE,
      placeholderTranslation: ETranslation.SELECT_EMPLOYMENT_TYPE_PLACEHOLDER,
      value: "",
      options: employmentTypeOptions,
    },
    [EPost.hardToFind]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.HARD_TO_FIND,
      placeholderTranslation: ETranslation.HARD_TO_FIND,
      value: "",
      options: hardToFindOptions,
    },
    [EPost.archived]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.ARCHIVED,
      placeholderTranslation: ETranslation.ARCHIVED,
      value: "",
    },
    [EPost.hidden]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.HIDDEN,
      placeholderTranslation: ETranslation.HIDDEN,
      value: "",
    },
    [EPost.attachments]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.ATTACHMENTS,
      placeholderTranslation: ETranslation.ATTACHMENTS,
      value: "",
      multiple: true,
    },
    [EPost.reference]: {
      type: EInputType.text,
      labelTranslation: ETranslation.REFERENCE,
      placeholderTranslation: ETranslation.REFERENCE,
      value: "",
    },
  });

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  useEffect(() => {
    if (post) {
      initForm(setInputs, post);
      setImage(post.image);
      setPostAttachments(post.attachments);
    }
    // if (post?.comments) setComments(post.comments);
  }, [post]);

  useEffect(() => {
    if (!attachments) return;
    if (attachmentInput === EPost.image) {
      setImage(attachments[0]);
    } else if (attachmentInput === EPost.attachments) {
      setPostAttachments((postAttachments) => [
        ...(postAttachments || []),
        ...attachments,
      ]);
    }
  }, [attachmentInput, attachments]);

  const saveHandler = () => {
    const data = getInputData<IPost>(inputs);
    // data.comments = [...comments];
    if (image) data.image = image;
    if (postAttachments?.length) data.attachments = [...postAttachments];
    if (Routes.isNew(id)) {
      showSaveDialog(data);
    } else {
      data.id = id;
      dispatch(actions.updatePost(data));
    }
  };

  const showSaveDialog = (data: IPost) =>
    setModal({
      isOpen: true,
      title: t(ETranslation.COMMON_SAVE),
      size: EModalSize.SMALL,
      content: (
        <ConfirmDialog
          onCancel={closeModal}
          onAccept={() => {
            dispatch(actions.savePost(data));
            closeModal();
          }}
          children={
            <div>
              <p>{t(ETranslation.CONFIRM_POST_SAVE)}</p>
              <p>
                {data.profession
                  ? t(ETranslation.PROFESSION) + ": " + data.profession
                  : ""}
              </p>
              <p>{data.city ? t(ETranslation.CITY) + ": " + data.city : ""}</p>
              <p>
                {data.locationDetail
                  ? t(ETranslation.LOCATION_DETAIL) + ": " + data.locationDetail
                  : ""}
              </p>
              <p>
                {data.datePosted
                  ? t(ETranslation.DATE_POSTED) + ": " + data.datePosted
                  : ""}
              </p>
              <p>
                 {data.hidden
                  ? t(ETranslation.HIDDEN)
                  : t(ETranslation.CONFIRM_NOT_HIDDEN_POST)}
              </p>
            </div>
          }
        />
      ),
    });

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation: !isValid,
    disabled: saveOrUpdateLoading || authUser?.isViewer,
  });

  const onDrop = (inputName: string, acceptedFiles: File[]) => {
    // console.log(inputName, acceptedFiles);
    dispatch(
      actions.uploadAttachment(inputName, acceptedFiles, EKind.Post, post?.id)
    );
  };

  const deleteAttachmentHandler = (attachment: IAttachment) => {
    // delete from datastore
    dispatch(
      actions.deletePostAttachment(
        post?.id || "",
        attachment.id,
        attachment.serverName
      )
    );
    // delete from state
    setPostAttachments((postAttachments) =>
      postAttachments.filter((a) => a.id !== attachment.id)
    );
  };

  const openConfirmDeleteModal = useConfirmModal();

  const deleteHandler = async () => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.CONFIRM_DELETE_POST)
    );
    if (success) {
      await dispatch(actions.deletePost(id));
      navigate(Routes.POSTS);
    }
  };

  const copyHandler = () => {
    if (post && post.id) {
      dispatch(actions.copyPost(post.id));
    }
  };

  useEffect(() => {
    if (!copyLoading && copyId) {
      window.open(`${Routes.POST(copyId)}`, "_blank");
    }
  }, [copyLoading, copyId])

  return (
    <>
      {error ? (
        <Alert>{t(error)}</Alert>
      ) : loading ? (
        <Spinner center />
      ) : (
        <>
          <Container>
            <InputGroup>
              <Title
                translation={ETranslation.POST_JOB_INFO}
                subtitle={
                  post?.shortId &&
                  `${t(ETranslation.POST_SHORT_ID)}: ${post.shortId}`
                }
              />
              {Routes.isNotNew(id) && !authUser?.isViewer && (
                <Button onClick={copyHandler} color={EButtonColor.PRIMARY} loading={copyLoading}>
                  {t(ETranslation.COPY)}
                </Button>
              )}
            </InputGroup>
            {createInput(EPost.profession)}
            {createInput(EPost.city)}
            {createInput(EPost.locationDetail)}
            {createInput(EPost.postalCode)}
            {createInput(EPost.fieldsOfWork, { options: fieldOfWorkOptions })}
            {createInput(EPost.shortDescription)}
            <div className={classes.forceMarginEditor}>
            {createInput(EPost.description)}
            </div>
            {createInput(EPost.employmentType)}
            {createInput(EPost.reference)}
            {createInput(EPost.image, {
              onDrop,
              loading: attachmentLoading && attachmentInput === EPost.image,
            })}
            <img
              style={{ maxWidth: "15rem" }}
              src={image?.link}
              alt={image?.clientName || ""}
            />
            {createInput(EPost.attachments, {
              onDrop,
              loading:
                attachmentLoading && attachmentInput === EPost.attachments,
            })}
            {postAttachments?.length > 0 && (
              <Attachments
                attachments={postAttachments}
                onDelete={deleteAttachmentHandler}
              />
            )}
            {/* <PostComments
              comments={comments || []}
              onAdd={commentAddHandler}
              onDelete={commentDeleteHandler}
            /> */}
          </Container>
          <Container>
            <Title translation={ETranslation.POST_PUBLISH_INFO} />
            {createInput(EPost.datePosted)}
            <InputGroup>
              {createInput(EPost.validThroughDate)}
              {createInput(EPost.validThroughTime)}
            </InputGroup>
            <InputGroup>
              {createInput(EPost.hardToFind)}
              {createInput(EPost.hidden)}
              {createInput(EPost.archived)}
            </InputGroup>
            <div style={{ marginTop: ".5rem" }}>
              <InputGroup>
                {!authUser?.isViewer && !post?.archived && <Button
                  disabled={loading || !isValid}
                  onClick={saveHandler}
                  loading={saveOrUpdateLoading}
                >
                  {t(ETranslation.COMMON_SAVE)}
                </Button>}
                <Button onClick={() => navigate(-1)} color={EButtonColor.NONE}>
                  {t(ETranslation.COMMON_RETURN)}
                </Button>
                {Routes.isNotNew(id) && authUser?.isAdmin && (
                  <Button onClick={deleteHandler} color={EButtonColor.DANGER}>
                    {t(ETranslation.COMMON_DELETE)}
                  </Button>
                )}
              </InputGroup>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default Post;
import IJobApplicationAction from "../../interfaces/action/IJobApplicationAction";
import IJobApplicationState from "../../interfaces/state/IJobApplicationState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IJobApplicationState;
type ReducerSignature = (
  state: ReducerState,
  action: IJobApplicationAction
) => ReducerState;

const initialState: ReducerState = {
  jobApplication: null,
  jobApplications: null,
  loading: false,
  error: null,
  saveOrUpdateOk: false,
  saveOrUpdateLoading: false,
  commentsLoading: false,
  commentsDeleteLoading: false,
  id: null,
  professions: null,
};

const clearJobApplication: ReducerSignature = (state, action): ReducerState => {
  return { ...initialState };
};

const listJobApplicationsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const listJobApplicationsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplications: action.jobApplications || null,
    error: null,
    loading: false,
  };
};

const listJobApplicationsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplications: null,
    loading: false,
    error: action.error,
  };
};

const getJobApplicationStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getJobApplicationSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: action.jobApplication || null,
    error: null,
    loading: false,
  };
};

const getJobApplicationFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: null,
    loading: false,
    error: action.error,
  };
};

const updateJobApplicationStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    saveOrUpdateLoading: true,
    saveOrUpdateOk: false,
    error: null,
  };
};

const updateJobApplicationSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: action.jobApplication || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const updateJobApplicationFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: null,
    loading: false,
    saveOrUpdateLoading: false,
    saveOrUpdateOk: false,
    error: action.error,
  };
};

const saveJobApplicationStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    saveOrUpdateLoading: true,
    saveOrUpdateOk: false,
    error: null,
  };
};

const saveJobApplicationSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: action.jobApplication || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const saveJobApplicationFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: null,
    loading: false,
    error: action.error,
    saveOrUpdateLoading: false,
    saveOrUpdateOk: false,
  };
};

const addJobApplicationCommentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, commentsLoading: true, error: null };
};

const addJobApplicationCommentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let jobApplication = null;
  if (state.jobApplication) {
    jobApplication = {
      ...state.jobApplication,
      comments: action.jobApplication?.comments || [],
    };
  }
  return {
    ...state,
    jobApplication,
    error: null,
    loading: false,
    commentsLoading: false,
  };
};

const addJobApplicationCommentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
    commentsLoading: false,
  };
};

const deleteJobApplicationCommentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    commentsDeleteLoading: true,
    id: action.id || null,
    error: null,
  };
};

const deleteJobApplicationCommentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let jobApplication = null;
  if (state.jobApplication) {
    jobApplication = {
      ...state.jobApplication,
      comments: action.jobApplication?.comments || [],
    };
  }
  return {
    ...state,
    jobApplication,
    error: null,
    loading: false,
    commentsDeleteLoading: false,
    id: null,
  };
};

const deleteJobApplicationCommentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
    commentsDeleteLoading: false,
    id: null,
  };
};

const deleteJobApplicationAttachmentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const deleteJobApplicationAttachmentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  let jobApplication = null;
  if (state.jobApplication && state.jobApplication.attachments?.length > 0) {
    jobApplication = {
      ...state.jobApplication,
      attachments:
        state.jobApplication?.attachments.filter(
          (attachment) => attachment.id !== action.id
        ) || [],
    };
  }
  return {
    ...state,
    jobApplication: jobApplication || null,
    error: null,
    loading: false,
  };
};

const deleteJobApplicationAttachmentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const deleteJobApplicationStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const deleteJobApplicationSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: null,
    error: null,
    loading: false,
  };
};

const deleteJobApplicationFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const addJobApplicationHistoryStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    saveOrUpdateLoading: true,
    saveOrUpdateOk: false,
    error: null,
  };
};

const addJobApplicationHistorySuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: action.jobApplication || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const addJobApplicationHistoryFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplication: null,
    loading: false,
    saveOrUpdateLoading: false,
    saveOrUpdateOk: false,
    error: action.error,
  };
};

const getProfessionListStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const getProfessionListSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    professions: action.professions || null,
    error: null,
    loading: false,
  };
};

const getProfessionListFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    professions: null,
    loading: false,
    error: action.error,
  };
};

const searchJobApplicationsStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return { ...state, error: null, loading: true };
};

const searchJobApplicationsSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplications: action.jobApplications || null,
    error: null,
    loading: false,
  };
};

const searchJobApplicationsFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    jobApplications: null,
    loading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: IJobApplicationAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.JOB_APPLICATION_CLEAR:
      return clearJobApplication(state, action);
    case EActionTypes.JOB_APPLICATION_LIST_START:
      return listJobApplicationsStart(state, action);
    case EActionTypes.JOB_APPLICATION_LIST_SUCCESS:
      return listJobApplicationsSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_LIST_FAIL:
      return listJobApplicationsFail(state, action);
    case EActionTypes.JOB_APPLICATION_GET_START:
      return getJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_GET_SUCCESS:
      return getJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_GET_FAIL:
      return getJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_UPDATE_START:
      return updateJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_UPDATE_SUCCESS:
      return updateJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_UPDATE_FAIL:
      return updateJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_SAVE_START:
      return saveJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_SAVE_SUCCESS:
      return saveJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_SAVE_FAIL:
      return saveJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_COMMENT_ADD_START:
      return addJobApplicationCommentStart(state, action);
    case EActionTypes.JOB_APPLICATION_COMMENT_ADD_SUCCESS:
      return addJobApplicationCommentSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_COMMENT_ADD_FAIL:
      return addJobApplicationCommentFail(state, action);
    case EActionTypes.JOB_APPLICATION_COMMENT_DELETE_START:
      return deleteJobApplicationCommentStart(state, action);
    case EActionTypes.JOB_APPLICATION_COMMENT_DELETE_SUCCESS:
      return deleteJobApplicationCommentSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_COMMENT_DELETE_FAIL:
      return deleteJobApplicationCommentFail(state, action);
    case EActionTypes.JOB_APPLICATION_ATTACHMENT_DELETE_START:
      return deleteJobApplicationAttachmentStart(state, action);
    case EActionTypes.JOB_APPLICATION_ATTACHMENT_DELETE_SUCCESS:
      return deleteJobApplicationAttachmentSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_ATTACHMENT_DELETE_FAIL:
      return deleteJobApplicationAttachmentFail(state, action);
    case EActionTypes.JOB_APPLICATION_DELETE_START:
      return deleteJobApplicationStart(state, action);
    case EActionTypes.JOB_APPLICATION_DELETE_SUCCESS:
      return deleteJobApplicationSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_DELETE_FAIL:
      return deleteJobApplicationFail(state, action);
    case EActionTypes.JOB_APPLICATION_HISTORY_ADD_START:
      return addJobApplicationHistoryStart(state, action);
    case EActionTypes.JOB_APPLICATION_HISTORY_ADD_SUCCESS:
      return addJobApplicationHistorySuccess(state, action);
    case EActionTypes.JOB_APPLICATION_HISTORY_ADD_FAIL:
      return addJobApplicationHistoryFail(state, action);
    case EActionTypes.JOB_APPLICATION_PROFESSION_LIST_GET_START:
      return getProfessionListStart(state, action);
    case EActionTypes.JOB_APPLICATION_PROFESSION_LIST_GET_SUCCESS:
      return getProfessionListSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_PROFESSION_LIST_GET_FAIL:
      return getProfessionListFail(state, action);
    case EActionTypes.JOB_APPLICATION_SEARCH_START:
      return searchJobApplicationsStart(state, action);
    case EActionTypes.JOB_APPLICATION_SEARCH_SUCCESS:
      return searchJobApplicationsSuccess(state, action);
    case EActionTypes.JOB_APPLICATION_SEARCH_FAIL:
      return searchJobApplicationsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

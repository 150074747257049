import { useTranslation } from "react-i18next";
import { ETranslation } from "./../translations/translation-keys";
import { useEffect, useState } from "react";

import { IOption } from "../components/UI/Input/Input";
import { createOptions } from "../shared/option-utils";

import * as actions from "../store/actions";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import { EUserLevel } from "../interfaces/domain/IUser";

interface IParams {
  withPlaceholder?: boolean;
  level?: EUserLevel;
}

const defaultParams: IParams = {
  withPlaceholder: false,
  level: undefined
};

export const useUserOptions = ({ withPlaceholder, level } = defaultParams) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [userOptions, setUserOptions] = useState<IOption[]>([]);
  const [tryFetch, setTryFetch] = useState(false);

  const { authUser } = useAppSelector((state) => state.auth);
  const { users, loading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (authUser) {
      setTryFetch(authUser.isAdmin);
    }
  }, [authUser]);

  useEffect(() => {
    if (tryFetch && !users && !loading) {
      dispatch(actions.listUsers(level));
      setTryFetch(false);
    }
  }, [dispatch, users, tryFetch, loading, level]);

  useEffect(() => {
    let placeholder;
    if (withPlaceholder) {
      placeholder = t(ETranslation.SELECT_USER_PLACEHOLDER);
    }
    if (users) {
      let optionUsers = [...users];
      setUserOptions(
        createOptions(optionUsers, (u) => u.name || u.id, placeholder)
      );
    } else {
      setUserOptions([]);
    }
  }, [users, withPlaceholder, t]);

  return { userOptions, usersLoading: loading };
};

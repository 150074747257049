import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../hooks/useAppSelector";
import { useAuthUser } from "../../../../../hooks/useAuthUser";
import { useConfirmModal } from "../../../../../hooks/useConfirmModal";
import { IComment } from "../../../../../interfaces/domain/IComment";
import { ETranslation } from "../../../../../translations/translation-keys";
import Button from "../../../../UI/Button/Button";
import Spinner, { ESpinnerSize } from "../../../../UI/Spinner/Spinner";
import classes from "./JobApplicationComment.module.scss";

export enum EJobApplicationComment {
  message = "message",
}

interface IProps {
  comment: IComment;
  onDelete: () => void;
}

const JobApplicationComment: React.FC<IProps> = ({ comment, onDelete }) => {
  const { commentsDeleteLoading, id: stateId } = useAppSelector(
    (state) => state.jobApplication
  );

  const { id, message, createdBy, createdDate } = comment;
  const { t } = useTranslation();
  const authUser = useAuthUser();

  const openConfirmDeleteModal = useConfirmModal();

  const deleteHandler = async () => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.CONFIRM_DELETE_COMMENT)
    );
    if (success) onDelete();
  };

  return (
    <div className={classes.Container}>
      {commentsDeleteLoading && id === stateId  ? (
        <Spinner size={ESpinnerSize.SMALL} />
      ) : (
        <>
          <div className={classes.JobApplicationComment}>{message}</div>
          <div className={classes.Created}>
            {createdBy?.name} {createdDate && moment(createdDate).format("DD.MM.yyyy HH:mm")}
          </div>
          {(authUser?.isAdmin || authUser?.id.toString() === createdBy?.id) && (
            <Button
              className={classes.DeleteButton}
              link
              onClick={deleteHandler}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default JobApplicationComment;

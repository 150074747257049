import { BaseDomain } from "./IBaseDomain";

export enum EUserLevel {
  ADMIN = "ADMIN",
  OPERATIVE = "OPERATIVE",
  VIEWER = "VIEWER",
}

export enum EGender {
  male = "male",
  female = "female",
  other = "other",
  notWillingToAnswer = "notWillingToAnswer"
}

export interface IUser extends BaseDomain {
  // firebaseUserId: string;
  firstName: string;
  lastName: string;
  address: string;
  postcode: string;
  place: string;
  mobile: string;
  email: string;
  allowLogin: boolean;
  level: EUserLevel;
  name: string;
  firebaseUserId: string;
}

export enum EEmploymentType{
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
}

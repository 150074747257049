import IAttachmentAction from "../../interfaces/action/IAttachmentAction";
import IAttachmentState from "../../interfaces/state/IAttachmentState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IAttachmentState;
type ReducerSignature = (
  state: ReducerState,
  action: IAttachmentAction
) => ReducerState;

const initialState: ReducerState = {
  inputName: null,
  attachments: null,
  loading: false,
  error: null,
};

const clearAttachment: ReducerSignature = (state, action): ReducerState => {
  return { ...initialState };
};

const uploadAttachmentStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    attachments: null,
    loading: true,
    inputName: action.inputName || null,
    error: null,
  };
};

const uploadAttachmentSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    loading: false,
    inputName: action.inputName || null,
    attachments: action.attachments || null,
    error: null,
  };
};

const uploadAttachmentFail: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    attachments: null,
    error: action.error,
    loading: false,
  };
};

const reducer = (
  state: IAttachmentState = initialState,
  action: IAttachmentAction
): IAttachmentState => {
  switch (action.type) {
    case EActionTypes.ATTACHMENT_CLEAR:
      return clearAttachment(state, action);
    case EActionTypes.ATTACHMENT_UPLOAD_START:
      return uploadAttachmentStart(state, action);
    case EActionTypes.ATTACHMENT_UPLOAD_SUCCESS:
      return uploadAttachmentSuccess(state, action);
    case EActionTypes.ATTACHMENT_UPLOAD_FAIL:
      return uploadAttachmentFail(state, action);
    default:
      return state;
  }
};

export default reducer;

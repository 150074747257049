import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import Alert, { EAlertColor } from "../../../components/UI/Alert/Alert";
import Button, { EButtonColor } from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import { EInputType, IInputField } from "../../../components/UI/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs
} from "../../../components/UI/Input/input-utils";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useBlurBeforeUnload } from "../../../hooks/useBlurBeforeUnload";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { IUser } from "../../../interfaces/domain/IUser";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import { levelOptions } from "../../../data/select-data";

export enum EUser {
  firstName = "firstName",
  lastName = "lastName",
  address = "address",
  postcode = "postcode",
  place = "place",
  mobile = "mobile",
  email = "email",
  allowLogin = "allowLogin",
  level = "level",
  password = "password",
  firebaseUserId = "firebaseUserId",
}

const User: React.FC = () => {
  useBlurBeforeUnload();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { loading, user, saveOrUpdateOk, error, saveOrUpdateLoading } =
    useAppSelector((state) => state.user);
  const [isValid, setIsValid] = useState(false);
  const id = params.id as string;

  useEffect(() => {
    if (saveOrUpdateOk) {
      navigate(Routes.USERS);
    }
    return () => {
      dispatch(actions.clearUser());
    };
  }, [dispatch, saveOrUpdateOk, navigate]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getUser(id));
    }
  }, [dispatch, id]);

  const [inputs, setInputs] = useState<IInputField>({
    [EUser.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.FIRST_NAME,
      placeholderTranslation: ETranslation.FIRST_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EUser.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LAST_NAME,
      placeholderTranslation: ETranslation.LAST_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EUser.address]: {
      type: EInputType.text,
      labelTranslation: ETranslation.ADDRESS,
      placeholderTranslation: ETranslation.ADDRESS,
      value: "",
      validation: {
        required: false,
      },
    },
    [EUser.postcode]: {
      type: EInputType.text,
      labelTranslation: ETranslation.POSTCODE,
      placeholderTranslation: ETranslation.POSTCODE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EUser.place]: {
      type: EInputType.text,
      labelTranslation: ETranslation.PLACE,
      placeholderTranslation: ETranslation.PLACE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EUser.mobile]: {
      type: EInputType.text,
      labelTranslation: ETranslation.MOBILE,
      placeholderTranslation: ETranslation.MOBILE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EUser.email]: {
      type: EInputType.email,
      labelTranslation: ETranslation.EMAIL,
      placeholderTranslation: ETranslation.EMAIL,
      value: "",
      validation: {
        required: true,
      },
    },
    [EUser.allowLogin]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.ALLOW_LOGIN,
      placeholderTranslation: ETranslation.ALLOW_LOGIN,
      value: "",
      validation: {
        required: false,
      },
    },
    [EUser.level]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.LEVEL,
      placeholderTranslation: ETranslation.LEVEL,
      value: "",
      options: levelOptions,
      validation: {
        required: true,
      },
    },
    [EUser.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.PASSWORD,
      placeholderTranslation: ETranslation.PASSWORD,
      value: "",
      isNewPassword: true,
      validation: {
        required: false,
      },
    },
    [EUser.firebaseUserId]: {
      type: EInputType.text,
      labelTranslation: ETranslation.FIREBASE_USER_ID,
      placeholderTranslation: ETranslation.FIREBASE_USER_ID,
      value: "",
    },
  });

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  useEffect(() => {
    if (user) initForm(setInputs, user);
  }, [user]);

  const saveHandler = () => {
    const data = getInputData<IUser>(inputs);
    if (Routes.isNew(id)) {
      dispatch(actions.saveUser(data));
    } else {
      data.id = id;
      dispatch(actions.updateUser(data));
    }
  };

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation: !isValid,
    disabled: saveOrUpdateLoading,
  });

  const openConfirmDeleteModal = useConfirmModal();

  const deleteHandler = async () => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.CONFIRM_DELETE_USER)
    );
    if (success) {
      await dispatch(actions.deleteUser(id));
      navigate(Routes.USERS);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner center />
      ) : (
        <Container>
          {error && (
            <Alert color={EAlertColor.DANGER} noHorizontalMargin>
              {error}
            </Alert>
          )}
          <InputGroup>
            {createInput(EUser.firstName)}
            {createInput(EUser.lastName)}
          </InputGroup>
          {createInput(EUser.address)}
          <InputGroup>
            {createInput(EUser.postcode)}
            {createInput(EUser.place)}
          </InputGroup>
          {createInput(EUser.mobile)}
          {createInput(EUser.email)}
          {createInput(EUser.allowLogin)}
          {createInput(EUser.level)}
          {Routes.isNew(id) && createInput(EUser.password)}
          {Routes.isNew(id) && createInput(EUser.firebaseUserId)}
          <InputGroup>
            <Button
              disabled={loading || !isValid}
              onClick={saveHandler}
              loading={saveOrUpdateLoading}
            >
              {t(ETranslation.COMMON_SAVE)}
            </Button>
            <Button onClick={() => navigate(-1)} color={EButtonColor.NONE}>
              {t(ETranslation.COMMON_RETURN)}
            </Button>
            {Routes.isNotNew(id) && (
              <Button onClick={deleteHandler} color={EButtonColor.DANGER}>
                {t(ETranslation.COMMON_DELETE)}
              </Button>
            )}
          </InputGroup>
        </Container>
      )}
    </>
  );
};

export default User;

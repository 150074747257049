import IUserAction from "../../interfaces/action/IUserAction";
import IUserState from "../../interfaces/state/IUserState";
import { EActionTypes } from "../EActionTypes";

type ReducerState = IUserState;
type ReducerSignature = (
  state: ReducerState,
  action: IUserAction
) => ReducerState;

const initialState: ReducerState = {
  user: null,
  users: null,
  searchedUsers: null,
  loading: false,
  error: null,
  saveOrUpdateOk: false,
  saveOrUpdateLoading: false,
};

const clearUser: ReducerSignature = (state, action): ReducerState => {
  return { ...initialState };
};

const listUsersStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    users: null,
    user: null,
    error: null,
    loading: true,
  };
};

const listUsersSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    users: action.users || null,
    error: null,
    loading: false,
  };
};

const listUsersFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    users: null,
    loading: false,
    error: action.error,
  };
};

const listUsersSearchStart: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    searchedUsers: null,
    user: null,
    error: null,
    loading: true,
  };
};

const listUsersSearchSuccess: ReducerSignature = (
  state,
  action
): ReducerState => {
  return {
    ...state,
    searchedUsers: action.searchedUsers || null,
    error: null,
    loading: false,
  };
};

const listUsersSearchFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    searchedUsers: null,
    loading: false,
    error: action.error,
  };
};

const getUserStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, user: null, error: null, loading: true };
};

const getUserSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    user: action.user || null,
    error: null,
    loading: false,
  };
};

const getUserFail: ReducerSignature = (state, action): ReducerState => {
  return { ...state, user: null, loading: false, error: action.error };
};

const updateUserStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    saveOrUpdateLoading: true,
    saveOrUpdateOk: false,
    error: null,
  };
};

const updateUserSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    user: action.user || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const updateUserFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    user: null,
    loading: false,
    error: action.error,
    saveOrUpdateLoading: false,
    saveOrUpdateOk: false,
  };
};

const saveUserStart: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    saveOrUpdateLoading: true,
    saveOrUpdateOk: false,
    error: null,
  };
};

const saveUserSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    user: action.user || null,
    error: null,
    loading: false,
    saveOrUpdateOk: true,
    saveOrUpdateLoading: false,
  };
};

const saveUserFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    user: null,
    loading: false,
    error: action.error,
    saveOrUpdateOk: false,
    saveOrUpdateLoading: false,
  };
};

const deleteUserStart: ReducerSignature = (state, action): ReducerState => {
  return { ...state, error: null, loading: true };
};

const deleteUserSuccess: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    user: null,
    error: null,
    loading: false,
  };
};

const deleteUserFail: ReducerSignature = (state, action): ReducerState => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const reducer = (
  state: ReducerState = initialState,
  action: IUserAction
): ReducerState => {
  switch (action.type) {
    case EActionTypes.USER_CLEAR:
      return clearUser(state, action);
    case EActionTypes.USER_LIST_START:
      return listUsersStart(state, action);
    case EActionTypes.USER_LIST_SUCCESS:
      return listUsersSuccess(state, action);
    case EActionTypes.USER_LIST_FAIL:
      return listUsersFail(state, action);
    case EActionTypes.USER_LIST_SEARCH_START:
      return listUsersSearchStart(state, action);
    case EActionTypes.USER_LIST_SEARCH_SUCCESS:
      return listUsersSearchSuccess(state, action);
    case EActionTypes.USER_LIST_SEARCH_FAIL:
      return listUsersSearchFail(state, action);
    case EActionTypes.USER_GET_START:
      return getUserStart(state, action);
    case EActionTypes.USER_GET_SUCCESS:
      return getUserSuccess(state, action);
    case EActionTypes.USER_GET_FAIL:
      return getUserFail(state, action);
    case EActionTypes.USER_UPDATE_START:
      return updateUserStart(state, action);
    case EActionTypes.USER_UPDATE_SUCCESS:
      return updateUserSuccess(state, action);
    case EActionTypes.USER_UPDATE_FAIL:
      return updateUserFail(state, action);
    case EActionTypes.USER_SAVE_START:
      return saveUserStart(state, action);
    case EActionTypes.USER_SAVE_SUCCESS:
      return saveUserSuccess(state, action);
    case EActionTypes.USER_SAVE_FAIL:
      return saveUserFail(state, action);
    case EActionTypes.USER_DELETE_START:
      return deleteUserStart(state, action);
    case EActionTypes.USER_DELETE_SUCCESS:
      return deleteUserSuccess(state, action);
    case EActionTypes.USER_DELETE_FAIL:
      return deleteUserFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ERouteStaticParams, Routes } from "../../classes/Routes";
import PostList from "../../components/Posts/PostList/PostList";
import PostSearch, {
  IPostSearch,
} from "../../components/Posts/PostSearch/PostSearch";
import Title from "../../components/Title/Title";
import Container from "../../components/UI/Container/Container";
import Input, { EInputType } from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./Posts.module.scss";
import { useAuthUser } from "../../hooks/useAuthUser";

const Posts: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { loading, posts } = useAppSelector((state) => state.post);
  const [filter, setFilter] = useState("");
  const authUser = useAuthUser();

  const searchHandler = useCallback(
    (search: IPostSearch) => {
      dispatch(actions.listPosts(search));
    },
    [dispatch]
  );

  return (
    <>
      <Container>
        <PostSearch onSearch={searchHandler} />
      </Container>
      <Container>
        <div className={classes.Title}>
          <Title
            translation={ETranslation.POSTS}
            route={!authUser?.isViewer && Routes.POST(ERouteStaticParams.new)}
          />
          <Input
            inputName="filter"
            type={EInputType.text}
            onChange={(value) => setFilter(value as string)}
            value={filter}
            placeholderTranslation={ETranslation.POST_LIST_FILTER}
          />
        </div>
        {loading ? (
          <Spinner />
        ) : posts ? (
          <PostList posts={posts} filter={filter} />
        ) : (
          t(ETranslation.NO_POSTS)
        )}
      </Container>
    </>
  );
};

export default Posts;

import { faEnvelope, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import Attachments from "../../../components/Attachments/Attachments";
import EducationHistory from "../../../components/JobApplications/EducationHistory/EducationHistory";
import JobApplicationComments from "../../../components/JobApplications/JobApplication/JobApplicationComments/JobApplicationComments";
import WorkHistory from "../../../components/JobApplications/WorkHistory/WorkHistory";
import Title from "../../../components/Title/Title";
import Button, {
  EButtonColor,
  EButtonSize,
} from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import {
  EInputType,
  IInputField,
  TInputValue,
} from "../../../components/UI/Input/Input";
import {
  getInputData,
  initForm,
  validateInputs,
} from "../../../components/UI/Input/input-utils";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import {
  baseEducationOptions,
  commonCardOptions,
  driversLicenceTypeOptions,
  fieldOfWorkOptions,
  genderOptions,
  languageSkillOptions,
  workTimeOptions,
  workTypeOptions,
} from "../../../data/select-data";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useBlurBeforeUnload } from "../../../hooks/useBlurBeforeUnload";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useCreateInput } from "../../../hooks/useCreateInput";
import { IAttachment } from "../../../interfaces/domain/IAttachment";
import { EKind } from "../../../interfaces/domain/IBaseDomain";
import { IComment } from "../../../interfaces/domain/IComment";
import { IEducationHistory } from "../../../interfaces/domain/IEducationHistory";
import { IJobApplication } from "../../../interfaces/domain/IJobApplication";
import { IWorkHistory } from "../../../interfaces/domain/IWorkHistory";
import { isValidEmail } from "../../../shared/isValidEmail";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";
import { useAuthUser } from "../../../hooks/useAuthUser";
import Alert from "../../../components/UI/Alert/Alert";

export enum EJobApplication {
  firstName = "firstName",
  lastName = "lastName",
  mobile = "mobile",
  email = "email",
  address = "address",
  postcode = "postcode",
  place = "place",
  birthYear = "birthYear",
  gender = "gender",
  professions = "professions",
  workExperienceFields = "workExperienceFields",
  specialSkills = "specialSkills",
  itSkills = "itSkills",
  languageSkillFi = "languageSkillFi",
  languageSkillEn = "languageSkillEn",
  languageSkillSe = "languageSkillSe",
  otherLanguages = "otherLanguages",
  driversLicence = "driversLicence",
  ownCar = "ownCar",
  driversLicenceDetails = "driversLicenceDetails",
  commonCards = "commonCards",
  otherCards = "otherCards",
  workType = "workType",
  workTime = "workTime",
  preferredStartingDate = "preferredStartingDate",
  startingDateMoreInfo = "startingDateMoreInfo",
  salaryPreference = "salaryPreference",
  unwantedJobs = "unwantedJobs",
  alsoApplyToFields = "alsoApplyToFields",
  historyId = "historyId",
  gdprConcent = "gdprConcent",
  otherInfo = "otherInfo",
  applyOptions = "applyOptions",
  education = "education",
  hobbies = "hobbies",
  otherProfession = "otherProfession",

  jsonString = "jsonString",
  attachments = "attachments",
  archived = "archived",
}

const JobApplication: React.FC = () => {
  useBlurBeforeUnload();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const { loading, jobApplication, saveOrUpdateOk, saveOrUpdateLoading, professions, error } =
    useAppSelector((state) => state.jobApplication);
  const [isValid, setIsValid] = useState(false);
  const id = params.id as string;

  const [comments, setComments] = useState<IComment[]>([]);
  const [workHistories, setWorkHistories] = useState<IWorkHistory[]>([]);
  const [educationHistories, setEducationHistories] = useState<
    IEducationHistory[]
  >([]);

  const { loading: attachmentLoading, attachments } = useAppSelector(
    (state) => state.attachment
  );
  const [jobAttachments, setJobAttachments] = useState<IAttachment[]>([]);

  const { loading: postsLoading, posts } = useAppSelector((state) => state.post);

  const postsOptions = posts?.map((post) => {
    return { value: post.id, label: post.title };
  });

  const professionsOptions = professions?.map((profession) => {
    return { value: profession, label: profession };
  });

  useEffect(() => {
    if (saveOrUpdateOk) {
      navigate(Routes.JOB_APPLICATIONS);
    }
    return () => {
      dispatch(actions.clearJobApplication());
      dispatch(actions.clearAttachment());
    };
  }, [dispatch, saveOrUpdateOk, navigate]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getJobApplication(id));
    }
    dispatch(actions.listPosts({fieldsOfWork: "", archived: false}))
    dispatch(actions.getProfessionList());
  }, [dispatch, id]);

  const [inputs, setInputs] = useState<IInputField>({
    [EJobApplication.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.FIRST_NAME,
      placeholderTranslation: ETranslation.FIRST_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EJobApplication.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LAST_NAME,
      placeholderTranslation: ETranslation.LAST_NAME,
      value: "",
      validation: {
        required: true,
      },
    },
    [EJobApplication.mobile]: {
      type: EInputType.text,
      labelTranslation: ETranslation.JOB_APPLICATION_MOBILE,
      placeholderTranslation: ETranslation.JOB_APPLICATION_MOBILE,
      value: "",
      validation: {
        required: true,
      },
    },
    [EJobApplication.email]: {
      type: EInputType.text,
      labelTranslation: ETranslation.JOB_APPLICATION_EMAIL,
      placeholderTranslation: ETranslation.JOB_APPLICATION_EMAIL,
      value: "",
      validation: {
        required: true,
      },
    },
    [EJobApplication.address]: {
      type: EInputType.text,
      labelTranslation: ETranslation.ADDRESS,
      placeholderTranslation: ETranslation.ADDRESS,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.postcode]: {
      type: EInputType.text,
      labelTranslation: ETranslation.POSTCODE,
      placeholderTranslation: ETranslation.POSTCODE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.place]: {
      type: EInputType.text,
      labelTranslation: ETranslation.PLACE,
      placeholderTranslation: ETranslation.PLACE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.gender]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.GENDER,
      placeholderTranslation: ETranslation.GENDER,
      value: "",
      options: genderOptions,
      validation: {
        required: false,
      },
    },
    [EJobApplication.education]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.EDUCATION,
      placeholderTranslation: ETranslation.EDUCATION,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.professions]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.PROFESSIONS,
      placeholderTranslation: ETranslation.PROFESSIONS,
      value: "",
      validation: {
        required: false,
      },
      multiple: true,
    },
    [EJobApplication.gdprConcent]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.GDPR_CONCENT,
      placeholderTranslation: ETranslation.GDPR_CONCENT,
      value: "",
    },
    [EJobApplication.ownCar]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.OWN_CAR,
      placeholderTranslation: ETranslation.OWN_CAR,
      value: "",
    },
    [EJobApplication.driversLicence]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.DRIVERS_LICENSE,
      placeholderTranslation: ETranslation.DRIVERS_LICENSE,
      value: "",
    },
    [EJobApplication.otherInfo]: {
      type: EInputType.textarea,
      labelTranslation: ETranslation.OTHER_INFO,
      placeholderTranslation: ETranslation.OTHER_INFO,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.birthYear]: {
      type: EInputType.text,
      labelTranslation: ETranslation.BIRTH_YEAR,
      placeholderTranslation: ETranslation.BIRTH_YEAR,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.workExperienceFields]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.WORK_EXPERIENCE_FIELDS,
      placeholderTranslation: ETranslation.SELECT_FIELD_OF_WORK_PLACEHOLDER,
      value: "",
      multiple: true,
    },
    [EJobApplication.specialSkills]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SPECIAL_SKILLS,
      placeholderTranslation: ETranslation.SPECIAL_SKILLS,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.itSkills]: {
      type: EInputType.text,
      labelTranslation: ETranslation.IT_SKILLS,
      placeholderTranslation: ETranslation.IT_SKILLS,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.languageSkillFi]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.LANGUAGE_SKILL_FI,
      placeholderTranslation: ETranslation.LANGUAGE_SKILL_FI,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.languageSkillEn]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.LANGUAGE_SKILL_EN,
      placeholderTranslation: ETranslation.LANGUAGE_SKILL_EN,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.languageSkillSe]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.LANGUAGE_SKILL_SE,
      placeholderTranslation: ETranslation.LANGUAGE_SKILL_SE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.otherLanguages]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OTHER_LANGUAGES,
      placeholderTranslation: ETranslation.OTHER_LANGUAGES,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.driversLicenceDetails]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.DRIVERS_LICENSE_DETAILS,
      placeholderTranslation: ETranslation.DRIVERS_LICENSE_DETAILS,
      value: "",
      validation: {
        required: false,
      },
      multiple: true,
    },
    [EJobApplication.commonCards]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.COMMON_CARDS,
      placeholderTranslation: ETranslation.COMMON_CARDS,
      value: "",
      validation: {
        required: false,
      },
      multiple: true,
    },
    [EJobApplication.otherCards]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OTHER_CARDS,
      placeholderTranslation: ETranslation.OTHER_CARDS,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.workType]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.PREFERRED_WORK_TYPES,
      placeholderTranslation: ETranslation.PREFERRED_WORK_TYPES,
      value: "",
      validation: {
        required: false,
      },
      multiple: true,
    },
    [EJobApplication.workTime]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.WORK_TIME,
      placeholderTranslation: ETranslation.WORK_TIME,
      value: "",
      validation: {
        required: false,
      },
      multiple: true,
    },
    [EJobApplication.preferredStartingDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.PREFERRED_STARTING_DATE,
      placeholderTranslation: ETranslation.PREFERRED_STARTING_DATE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.startingDateMoreInfo]: {
      type: EInputType.text,
      labelTranslation: ETranslation.STARTING_DATE_MORE_INFO,
      placeholderTranslation: ETranslation.STARTING_DATE_MORE_INFO,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.salaryPreference]: {
      type: EInputType.text,
      labelTranslation: ETranslation.SALARY_PREFERENCE,
      placeholderTranslation: ETranslation.SALARY_PREFERENCE,
      value: "",
      validation: {
        required: false,
      },
    },
    [EJobApplication.attachments]: {
      type: EInputType.dropzone,
      labelTranslation: ETranslation.ATTACHMENTS,
      placeholderTranslation: ETranslation.ATTACHMENTS,
      value: "",
      multiple: true,
    },
    [EJobApplication.unwantedJobs]: {
      type: EInputType.text,
      labelTranslation: ETranslation.UNWANTED_JOBS,
      placeholderTranslation: ETranslation.UNWANTED_JOBS,
      value: "",
    },
    [EJobApplication.alsoApplyToFields]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.ALSO_APPLY_TO_FIELDS,
      placeholderTranslation: ETranslation.ALSO_APPLY_TO_FIELDS,
      value: "",
      multiple: true,
    },
    [EJobApplication.historyId]: {
      type: EInputType.reactSelect,
      labelTranslation: ETranslation.POSTS,
      placeholderTranslation: ETranslation.POSTS,
      value: "",
      multiple: true,
    },
    [EJobApplication.hobbies]: {
      type: EInputType.text,
      labelTranslation: ETranslation.HOBBIES,
      placeholderTranslation: ETranslation.HOBBIES,
      value: "",
    },
    [EJobApplication.otherProfession]: {
      type: EInputType.text,
      labelTranslation: ETranslation.OTHER_PROFESSION,
      placeholderTranslation: ETranslation.OTHER_PROFESSION,
      value: "",
    },
    [EJobApplication.archived]: {
      type: EInputType.checkbox,
      labelTranslation: ETranslation.ARCHIVED,
      placeholderTranslation: ETranslation.ARCHIVED,
      value: "",
    },
  });

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  useEffect(() => {
    if (jobApplication) {
      initForm(setInputs, jobApplication);
      setComments(jobApplication.comments || []);
      setWorkHistories(jobApplication.workHistory || []);
      setJobAttachments(jobApplication.attachments || []);
      setEducationHistories(jobApplication.educationHistory || []);
    }
  }, [jobApplication]);

  useEffect(() => {
    if (!attachments) return;
    setJobAttachments((jobAttachments) => [
      ...(jobAttachments || []),
      ...attachments,
    ]);
  }, [attachments]);

  const saveHandler = () => {
    const data = getInputData<IJobApplication>(inputs);
    data.comments = [...comments];
    data.workHistory = [...workHistories];
    if (jobAttachments?.length) data.attachments = [...jobAttachments];
    data.educationHistory = [...educationHistories];
    if (Routes.isNew(id)) {
      dispatch(actions.saveJobApplication(data));
    } else {
      data.id = id;
      dispatch(actions.updateJobApplication(data));
    }
  };

  const onDrop = (inputName: string, acceptedFiles: File[]) => {
    // console.log(inputName, acceptedFiles);
    dispatch(
      actions.uploadAttachment(
        inputName,
        acceptedFiles,
        EKind.JobApplication,
        jobApplication?.id
      )
    );
  };

  const deleteAttachmentHandler = (attachment: IAttachment) => {
    // delete from datastore
    dispatch(
      actions.deleteJobApplicationAttachment(
        jobApplication?.id || "",
        attachment.id,
        attachment.serverName
      )
    );
    // delete from state
    setJobAttachments((jobAttachments) =>
      jobAttachments.filter((a) => a.id !== attachment.id)
    );
  };

  const createInput = useCreateInput(inputs, setInputs, {
    showValidation: !isValid,
    disabled: saveOrUpdateLoading || authUser?.isViewer,
  });

  const commentAddHandler = (value: TInputValue) => {
    if (jobApplication && value) {
      dispatch(
        actions.addJobApplicationComment(jobApplication.id, value as string)
      );
    }
  };

  const commentDeleteHandler = (id: string) => {
    if (jobApplication) {
      dispatch(actions.deleteJobApplicationComment(jobApplication.id, id));
    }
  };

  const addHistories = () => {
    const postIdList = inputs[EJobApplication.historyId].value as string[];
    if (postIdList?.length) {
      dispatch(actions.addJobApplicationHistory(id, postIdList))
    }
  };

  const openConfirmDeleteModal = useConfirmModal();

  const deleteHandler = async () => {
    const success = await openConfirmDeleteModal(
      t(ETranslation.CONFIRM_DELETE_JOB_APPLICATION)
    );
    if (success) {
      await dispatch(actions.deleteJobApplication(id));
      navigate(Routes.JOB_APPLICATIONS);
    }
  };

  const addWorkHistoryHandler = () => {
    setWorkHistories((workHistories) => {
      return [...workHistories, {} as IWorkHistory];
    });
  };

  const addEducationHistoryHandler = () => {
    setEducationHistories((educationHistories) => {
      return [...educationHistories, {} as IEducationHistory];
    });
  };

  return (
    <>
      {error ? (
        <Alert>{t(error)}</Alert>
      ) : loading ? (
        <Spinner center />
      ) : (
        <>
          <Container>
            {jobApplication?.email && isValidEmail(jobApplication.email) && (
              <div style={{ position: "relative" }}>
                <a
                  href={`mailto:${
                    jobApplication.email
                  }?subject=Lisätietopyyntö%20työhakemukseesi&body=Hei,%20liittyen%20työhakemukseesi%20${
                    jobApplication?.created &&
                    moment(jobApplication.created).format("DD.MM.")
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ position: "absolute", right: 0 }}
                  />
                </a>
              </div>
            )}
            <Title translation={ETranslation.JOB_APPLICATION_TITLE} />
            <h3>{t(ETranslation.JOB_APPLICATION_TITLE_APPLY)}</h3>
            {postsLoading ? (
              <Spinner center />
            ) : (
              <InputGroup>
                {createInput(EJobApplication.historyId, {
                  options: postsOptions,
                })}
                {!Routes.isNew(id) && !authUser?.isViewer && (
                  <Button
                    disabled={postsLoading}
                    onClick={addHistories}
                    loading={loading}
                  >
                    {t(ETranslation.ADD_HISTORY)}
                  </Button>
                )}
              </InputGroup>
            )}

            {jobApplication &&
              jobApplication.history?.length > 0 &&
              jobApplication.history.map((history) => {
                return (
                  <p key={history.postId}>
                    {history.applyDate + ": "}
                    <b>
                      {history.title != null ? history.title : "NoTitleFound"}
                    </b>{" "}
                    id:{history.postId}
                  </p>
                );
              })}
            {createInput(EJobApplication.alsoApplyToFields, {
              options: fieldOfWorkOptions,
            })}

            <h3>{t(ETranslation.JOB_APPLICATION_TITLE_BASIC)}</h3>
            <InputGroup>
              {createInput(EJobApplication.firstName)}
              {createInput(EJobApplication.lastName)}
            </InputGroup>
            <InputGroup>
              {createInput(EJobApplication.email)}
              {createInput(EJobApplication.mobile)}
            </InputGroup>
            {createInput(EJobApplication.address)}
            <InputGroup>
              {createInput(EJobApplication.postcode)}
              {createInput(EJobApplication.place)}
            </InputGroup>
            <InputGroup>
              {createInput(EJobApplication.birthYear)}
              {createInput(EJobApplication.gender)}
            </InputGroup>
            <h3>{t(ETranslation.JOB_APPLICATION_TITLE_EXPERIENCE)}</h3>
            {createInput(EJobApplication.workExperienceFields, {
              options: fieldOfWorkOptions,
            })}
            {createInput(EJobApplication.specialSkills)}
            {createInput(EJobApplication.itSkills)}
            {createInput(EJobApplication.hobbies)}
            {createInput(EJobApplication.professions, {
              options: professionsOptions,
            })}
            {createInput(EJobApplication.otherProfession)}
            <p>{t(ETranslation.LANGUAGE_SKILL)}</p>
            <InputGroup>
              {createInput(EJobApplication.languageSkillFi, {
                options: languageSkillOptions,
              })}
              {createInput(EJobApplication.languageSkillEn, {
                options: languageSkillOptions,
              })}
              {createInput(EJobApplication.languageSkillSe, {
                options: languageSkillOptions,
              })}
            </InputGroup>
            {createInput(EJobApplication.otherLanguages)}
            <h3>{t(ETranslation.JOB_APPLICATION_TITLE_CARDS)}</h3>
            {createInput(EJobApplication.driversLicence)}
            {createInput(EJobApplication.ownCar)}
            {createInput(EJobApplication.driversLicenceDetails, {
              options: driversLicenceTypeOptions,
            })}
            {createInput(EJobApplication.commonCards, {
              options: commonCardOptions,
            })}
            {createInput(EJobApplication.otherCards)}
            <h3>{t(ETranslation.JOB_APPLICATION_TITLE_WISHES)}</h3>
            {createInput(EJobApplication.workType, {
              options: workTypeOptions,
            })}
            {createInput(EJobApplication.preferredStartingDate)}
            {createInput(EJobApplication.startingDateMoreInfo)}
            {createInput(EJobApplication.salaryPreference)}
            {createInput(EJobApplication.workTime, {
              options: workTimeOptions,
            })}
            <h3>{t(ETranslation.WORK_HISTORY)}</h3>
            {workHistories?.length > 0 &&
              workHistories.map((workHistory, i) => (
                <WorkHistory
                  key={i}
                  index={i}
                  workHistory={workHistory}
                  setWorkHistories={setWorkHistories}
                  disabled={saveOrUpdateLoading || authUser?.isViewer}
                />
              ))}
            {!authUser?.isViewer && (
              <Button
                onClick={addWorkHistoryHandler}
                color={EButtonColor.WARNING}
                size={EButtonSize.SMALL}
                icon={faPlus}
              >
                {t(ETranslation.ADD_WORK_HISTORY)}
              </Button>
            )}

            <h3>{t(ETranslation.EDUCATION_HISTORY)}</h3>
            {createInput(EJobApplication.education, {
              options: baseEducationOptions,
            })}
            {educationHistories?.length > 0 &&
              educationHistories.map((educationHistory, i) => (
                <EducationHistory
                  key={i}
                  index={i}
                  educationHistory={educationHistory}
                  setEducationHistories={setEducationHistories}
                  disabled={saveOrUpdateLoading || authUser?.isViewer}
                />
              ))}
            {!authUser?.isViewer && (
              <Button
                onClick={addEducationHistoryHandler}
                color={EButtonColor.WARNING}
                size={EButtonSize.SMALL}
                icon={faPlus}
              >
                {t(ETranslation.ADD_EDUCATION_HISTORY)}
              </Button>
            )}

            <h3>{t(ETranslation.JOB_APPLICATION_TITLE_OTHER_INFO)}</h3>
            {createInput(EJobApplication.unwantedJobs)}
            {createInput(EJobApplication.otherInfo)}
            {createInput(EJobApplication.gdprConcent)}
            {createInput(EJobApplication.archived)}
            {createInput(EJobApplication.attachments, {
              onDrop,
              loading: attachmentLoading,
            })}
            {jobAttachments?.length > 0 && (
              <Attachments
                attachments={jobAttachments}
                onDelete={deleteAttachmentHandler}
              />
            )}
            <InputGroup>
              {!authUser?.isViewer && (
                <Button
                  disabled={loading || !isValid}
                  onClick={saveHandler}
                  loading={saveOrUpdateLoading}
                >
                  {t(ETranslation.COMMON_SAVE)}
                </Button>
              )}
              <Button onClick={() => navigate(-1)} color={EButtonColor.NONE}>
                {t(ETranslation.COMMON_RETURN)}
              </Button>
              {Routes.isNotNew(id) && authUser?.isAdmin && (
                <Button onClick={deleteHandler} color={EButtonColor.DANGER}>
                  {t(ETranslation.COMMON_DELETE)}
                </Button>
              )}
            </InputGroup>
          </Container>

          {Routes.isNotNew(id) && (
            <Container>
              <JobApplicationComments
                comments={comments || []}
                onAdd={commentAddHandler}
                onDelete={commentDeleteHandler}
              />
            </Container>
          )}
          {/* <Container> 
            <p>www-sivuston hakulomakkeelta saatu data:</p>
            <p style={{ wordBreak: "break-word" }}>
              {jobApplication?.jsonString}
            </p>
          </Container> */}
        </>
      )}
    </>
  );
};

export default JobApplication;

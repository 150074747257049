import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./Dropzone.module.scss";

interface IProps {
  onDrop: (acceptedFiles: File[]) => void;
  className?: string;
  text?: string;
  accept?: string;
  canPaste?: boolean;
  multiple?: boolean;
  disabled?: boolean;
}

const Dropzone: React.FC<IProps> = ({
  onDrop,
  className,
  text,
  accept,
  canPaste,
  multiple,
  disabled,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    disabled,
  });

  useEffect(() => {
    if (canPaste) {
      const listener = (ev: ClipboardEvent) => {
        const items = ev.clipboardData ? ev.clipboardData.items : null;
        if (items) {
          const item = items[0];
          if (item.kind === "file") {
            const file = item.getAsFile();
            if (file) {
              onDrop([file]);
            }
          }
        }
      };
      document.addEventListener("paste", listener);
      return () => {
        document.removeEventListener("paste", listener);
      };
    }
  }, [canPaste, onDrop]);

  const classNames = [classes.Container];

  if (className) {
    classNames.push(className);
  }

  return disabled ? null : (
    <div className={classNames.join(" ")} {...getRootProps()}>
      <input {...getInputProps({ accept })} />
      {isDragActive ? (
        <>
          <p>{t(ETranslation.REACT_DROPZONE_DRAG_ACTIVE_TEXT)}</p>
        </>
      ) : (
        <>
          <p>{text || t(ETranslation.REACT_DROPZONE_DRAG_TEXT)}</p>
        </>
      )}
    </div>
  );
};

export default Dropzone;

import axios from "../../axios";
import { Dispatch } from "redux";
import IUserAction from "../../interfaces/action/IUserAction";
import { EUserLevel, IUser } from "../../interfaces/domain/IUser";
import { EActionTypes } from "../EActionTypes";
import { AxiosResponse } from "axios";
import { IUserSearch } from "../../components/Users/UserSearch/UserSearch";

type TAction = IUserAction;
const apiPath = "/json/user";

export const clearUser = () => {
  return {
    type: EActionTypes.USER_CLEAR,
  };
};

const listUsersStart = (): TAction => {
  return {
    type: EActionTypes.USER_LIST_START,
  };
};

const listUsersSuccess = (users: IUser[]): TAction => {
  return {
    type: EActionTypes.USER_LIST_SUCCESS,
    users,
  };
};

const listUsersFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_LIST_FAIL,
    error,
  };
};

export const listUsers = (level?: EUserLevel) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listUsersStart());
    try {
      const res = await axios.get<IUser[]>(`${apiPath}/list?level=${level ?? ""}`);
      dispatch(listUsersSuccess(res.data));
    } catch ({ response }) {
      dispatch(listUsersFail((response as AxiosResponse).data));
    }
  };
};

const listUsersSearchStart = (): TAction => {
  return {
    type: EActionTypes.USER_LIST_SEARCH_START,
  };
};

const listUsersSearchSuccess = (searchedUsers: IUser[]): TAction => {
  return {
    type: EActionTypes.USER_LIST_SEARCH_SUCCESS,
    searchedUsers,
  };
};

const listUsersSearchFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_LIST_SEARCH_FAIL,
    error,
  };
};

export const listUsersSearch = (search: IUserSearch) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(listUsersSearchStart());
    try {
      const res = await axios.get<IUser[]>(
        `${apiPath}/list?role=${search.role}`
      );
      dispatch(listUsersSearchSuccess(res.data));
    } catch ({ response }) {
      dispatch(listUsersSearchFail((response as AxiosResponse).data));
    }
  };
};

const getUserStart = (): TAction => {
  return {
    type: EActionTypes.USER_GET_START,
  };
};

const getUserSuccess = (user: IUser): TAction => {
  return {
    type: EActionTypes.USER_GET_SUCCESS,
    user,
  };
};

const getUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_GET_FAIL,
    error,
  };
};

export const getUser = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(getUserStart());
    try {
      const res = await axios.get<IUser>(`${apiPath}/get?id=${id}`);
      dispatch(getUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(getUserFail((response as AxiosResponse).data));
    }
  };
};

const updateUserStart = (): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_START,
  };
};

const updateUserSuccess = (user: IUser): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_SUCCESS,
    user,
  };
};

const updateUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_UPDATE_FAIL,
    error,
  };
};

export const updateUser = (user: IUser) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateUserStart());
    try {
      const res = await axios.put<IUser>(`${apiPath}/update`, user);
      dispatch(updateUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(updateUserFail((response as AxiosResponse).data));
    }
  };
};

const saveUserStart = (): TAction => {
  return {
    type: EActionTypes.USER_SAVE_START,
  };
};

const saveUserSuccess = (user: IUser): TAction => {
  return {
    type: EActionTypes.USER_SAVE_SUCCESS,
    user,
  };
};

const saveUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_SAVE_FAIL,
    error,
  };
};

export const saveUser = (user: IUser) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(saveUserStart());
    try {
      const res = await axios.post<IUser>(`${apiPath}/add`, user);
      dispatch(saveUserSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveUserFail((response as AxiosResponse).data));
    }
  };
};

const deleteUserStart = (): TAction => {
  return {
    type: EActionTypes.USER_DELETE_START,
  };
};

const deleteUserSuccess = (): TAction => {
  return {
    type: EActionTypes.USER_DELETE_SUCCESS,
  };
};

const deleteUserFail = (error: string): TAction => {
  return {
    type: EActionTypes.USER_DELETE_FAIL,
    error,
  };
};

export const deleteUser = (id: string) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteUserStart());
    try {
      await axios.delete(`${apiPath}/delete?id=${id}`);
      dispatch(deleteUserSuccess());
    } catch ({ response }) {
      dispatch(deleteUserFail((response as AxiosResponse).data));
    }
  };
};

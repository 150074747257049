import { useEffect, useState, useRef } from 'react';

type AsyncCall<T> = (searchTerm: string, signal: AbortSignal) => Promise<T>;

export function useAsyncCall<T>(searchTerm: string, asyncCall: AsyncCall<T>, { timeout = 300, minLength = 3 } = {}) {
  const [loading, setLoading] = useState(false);
  const abortController = useRef<AbortController | null>(null);
  const searchTimeout = useRef<number>();
  const [result, setResult] = useState<T | null>(null);

  useEffect(() => {
    if (abortController.current) abortController.current.abort();
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(async () => {
      if (searchTerm.length >= minLength) {
        setLoading(true);
        try {
          abortController.current = new AbortController();
          const result = await asyncCall(searchTerm, abortController.current.signal);
          setResult(result);
          abortController.current = null;
        } catch (error) {
          // Nothing to do
        } finally {
          setLoading(false);
        }
      } else {
        setResult(null);
      }
    }, timeout);
  }, [searchTerm, minLength, timeout, asyncCall]);

  return { loading, result };
}